import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import * as S from "components/Controls/Style"

import { VscDebugRestart } from "react-icons/vsc"
import { IoArrowForwardCircle, IoArrowForwardCircleOutline } from "react-icons/io5"
import { IoMdArrowRoundForward, IoMdHelpCircleOutline } from "react-icons/io"
import { MdHelpOutline } from "react-icons/md"
import { BsTextIndentLeft } from "react-icons/bs"

import {
    selectRawInstructions,
    updateCpu,
    updateExtra,
    updateRawInstructions,
} from "slices/CpuSlice"

import {
    parseInput,
    startSim,
    stepSim,
} from "components/Cpu/Sim"

import { setPopup } from "slices/MainSlice"

import { EXAMPLE_PROGRAM } from "components/Cpu/constants"

export const Button = ({
    icon,
    onClick,
    text,
}) => {
    let IconComponent = "div"
    if (icon == "load") { IconComponent = VscDebugRestart }
    else if (icon == "step") { IconComponent = IoMdArrowRoundForward }
    else if (icon == "example") { IconComponent = BsTextIndentLeft }
    else { IconComponent = MdHelpOutline }

    return (
        <S.Control
            sides="0b1111"
            onClick={onClick}
        >
            <IconComponent/>
            <span>{text}</span>
        </S.Control>
    )
}

export const Controls = (props) => {
    const dispatch = useDispatch()
    const rawInstructions = useSelector(selectRawInstructions)

    const sendUpdateActions = (simReturnObject) => {
        const {
            cpu,
            extra,
        } = simReturnObject
        dispatch(updateCpu(cpu))
        dispatch(updateExtra(extra))
    }

    useEffect(() => {
        sendUpdateActions(startSim(""))
    }, [])

    return (
        <S.Outer>
            <Button
                onClick={() => {
                    const parsed = parseInput(rawInstructions)
                    sendUpdateActions(startSim(parsed.instructions))
                }}
                text="Load & Go"
                icon="load"
            />
            <Button
                onClick={() => sendUpdateActions(stepSim())}
                text="Step"
                icon="step"
            />
            <Button
                onClick={() => dispatch(setPopup("help"))}
                text="Learn"
                icon="help"
            />
            <Button
                onClick={() => dispatch(updateRawInstructions(EXAMPLE_PROGRAM))}
                text="Example"
                icon="example"
            />
        </S.Outer>
    )
}

export default Controls
