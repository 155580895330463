import React, { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"

import * as S from "components/CpuContainer/Style"

import Cpu2 from "components/Cpu/Cpu2"
import Controls from "components/Controls/Controls"
import Editor from "components/Editor/Editor"

import { Register } from "components/Cpu/Popups"

const Link = (props) =>
{
    return (
        <a
            href={props.location}
            onClick={(e) => {
                e.preventDefault()
                props.history.push(props.location)
            }}
        >
            {props.children}
        </a>
    )
}


export const CpuContainer = (props) => {
    const history = useHistory()
    return (
        <S.Outer>
            <Cpu2/>
            <S.Module>
                <Controls/>
                <Editor/>
            </S.Module>
            <S.Module style={{flex: "1", maxHeight: "600px", minWidth: "150px", maxWidth_: "800px", display: "block"}}>
                <h1>CPU Simulator</h1>
                <h4>Introduction</h4>
                <p>
                    This is an interactive simulator for my novel assembly language and processor. You can experiment with the processor by writing a program in the box, then clicking "Load & Go".
                    Your program will be loaded into memory, and then you can advance the simulation by clicking "Step".
                </p>
                <div style={{float: "right", marginLeft: "10px"}}>
                    <img src="hover.gif" height="100px"/>
                </div>
                <p>
                    Click "Example" to load an example program, or click "Learn" to learn more about the language and this project. You can also click on any box or register in the simulator to learn more about that specific location.
                </p>
                <p>
                    You can also visit the the "<Link location="/docs" history={history}>docs</Link>" page for a full rundown, and the "<Link location="/about" history={history}>about</Link>" page to see more projects.
                </p>
                <h4>Primer</h4>
                <p>All instructions specify two locations, a <S.ColorSpan color="#EF233C">source</S.ColorSpan> and a <S.ColorSpan color="#00A7E1">destination</S.ColorSpan>. An example: [<span>{Register("PC")} {Register("GPA")}</span>]</p>
                <p>The location on the left is the source from which data will be pulled. The location on the right is the destination where that data will be stored.</p>
                <p>Legal locations are any of the capitalized labels you see in the boxes in the simulator, like "{Register("PC")}", "{Register("GPG")}", or "{Register("ALUM")}".</p>
                <p>Instructions are run one at a time; when the instruction is executed, the contents of the source location are copied to the destination.</p>
                <p>Some locations have special behavior, which you can learn about by clicking the location in the simulator.</p>
                <p>
                    You can also specify a numerical constant as a source location in order to load a number into a register. This is useful for setting the ALU mode or operands, for example.
                    Constants may be in either base-10 (ex. "12") or base-16 (ex. "0x0C"), and must be less than 128 (0x80).
                </p>
            </S.Module>
        </S.Outer>
    )
}

export default CpuContainer
