import React, { useRef, useState } from "react"
import { useDispatch } from "react-redux"

import * as S from "components/DocsPage/Style"

import Popups from "components/Cpu/Popups"

import { updateRawInstructions } from "slices/CpuSlice"
import { useEffect } from "react"

const useWidth = () => {
    const [width, setWidth] = useState(undefined)

    const ref = useRef(null)

    // run once to get initial width
    useEffect(() => {
        if (ref && ref.current) {
            setWidth(ref.current.offsetWidth)
        }
    }, [])

    useEffect(() => {
        const handleResize = () => {
            if (ref && ref.current) {
                setWidth(ref.current.offsetWidth)
            }
        }

        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    })

    return [ref, width]
}

export const DocsPage = (props) => {
    const dispatch = useDispatch()

    const [widthRef, width] = useWidth()

    const sections = [
        Popups.Help,
        Popups.PC,
        Popups.GP("GPA"),
        Popups.ALU("ALUA"),
        Popups.ALUM,
        Popups.ALUR,
        Popups.FPU("FPUA"),
        Popups.FPUM,
        Popups.FPUR,
        Popups.BASE("RBASE"),
        Popups.MEM("WMEM"),
        Popups.SKIP,
        Popups.RTRN,
        Popups.LINK,
        Popups.COMP("COMPA"),
        Popups.COMPR,
        Popups.IADN,
        Popups.IADF,
        Popups.FLAG,
    ]

    const numColumns = width ? Math.floor(width / 600) : 1

    const columns = []
    for (let i = 0; i < numColumns; i++)
    {
        columns.push([])
    }

    // for (let c = 0; c < numColumns; c++)
    // {
    //     const sectionsPerColumn = Math.floor(sections.length / numColumns)
    //     const lower = sectionsPerColumn * c
    //     const newColumn = sections.map((section, i) => i >= lower && i < lower + sectionsPerColumn && section).filter(section => section)
    //     columns.push(newColumn)
    // }

    sections.forEach((section, i) => columns[i % numColumns].push(section))

    return (
        <S.Outer ref={widthRef}>
            <S.Row>
                {
                    columns.map(column =>
                        <S.Column>
                            {
                                column.map(PopupComponent =>
                                    <PopupComponent updateEditor={(code) => dispatch(updateRawInstructions(code))}/>
                                )
                            }
                        </S.Column>
                    )
                }
            </S.Row>
        </S.Outer>
    )
}

export default DocsPage
