import styled, { css } from "styled-components"

import { FGColor, Floating } from "utility/StyleUtility"

export const Outer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
`

export const Module = styled.div`
    display: flex;
    flex-direction: row;
    padding: 24px;
    box-shadow: 8px 8px 30px rgba(0, 0, 0, 0.2);
    background-color: #EEEEEE;

    align-self: flex-start;

    gap: 24px;

    overflow-y: auto;
`

export const ColorSpan = styled.span`
    ${FGColor}
    font-weight: 600;
`
