import React, { useEffect, useState } from "react"
import Editor from "react-simple-code-editor"


import * as S from "components/Editor/Style"

import { getColorsForInstruction } from "components/Cpu/constants"

import { parseInput } from "components/Cpu/Sim"

import { useSelector, useDispatch } from "react-redux"
import { selectRawInstructions, updateRawInstructions } from "slices/CpuSlice"

const highlight = (code) => {
    if (!code) {
        return ""
    }

    const words = code.match(/(\S+)|(\s+)/g)
    return words.map(w => {
        const colors = getColorsForInstruction(w.toUpperCase())
        return (
            <S.Word
                color={colors && colors.primary}
                backgroundColor={colors && colors.secondary}
            >
                {w}
            </S.Word>
        )
    }).concat(" ")
}

export const NewEditor = (props) => {
    const [content, setContent] = useState("")
    const dispatch = useDispatch()
    const rawInstructions = useSelector(selectRawInstructions)

    useEffect(() => {
        //check if the instructions in the store have changed by
        //any other way (like from the "insert example" button)
        //if so, replace our content with those
        setContent(rawInstructions)
    }, [rawInstructions])

    const maxHeight = "400px"

    return (
        <S.Outer>
            <S.Container sides="0b1111">
                <Editor
                    value={content}
                    placeholder={`0x05 ALUA`}
                    onValueChange={(value) => {
                        setContent(value)
                        dispatch(updateRawInstructions(value))
                    }}
                    highlight={(code) => highlight(code)}
                    textareaClassName="code-area"
                    preClassName="pre-area"
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 18,
                        minHeight: maxHeight,
                    }}
                />
            </S.Container>
        </S.Outer>
    )
}

export default NewEditor
