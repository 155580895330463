import styled, { css } from "styled-components"

import {
    Transitions
} from "utility/StyleUtility"

export const Outer = styled.div`
    background-color: black;

    margin-top: -40px;
    margin-left: -75px;
    margin-right: -85px;
    margin-bottom: -85px;

    display: flex;
    flex-direction: row;

    min-height: calc(100% + 125px);

    color: white;

    a {
        color: #00A7E1;
        transition: 0.2s;

        :hover {
            transition: 0.2s;
            color: #EF233C;
        };
    }
`

export const CanvasContainer = styled.div`
    width: ${({size}) => `${size}px`};
    height: ${({size}) => `${size}px`};
    margin: 35px;
    margin-left: 250px;
`

export const Tip = styled.span`
    color: #BBCCDD;
    font-size: 12px;
`

export const Slider = styled.div`
    flex: 1;

    display: flex;
    flex-direction: column;
    align-self: center;
    text-align: center;
    margin: 8px;
    padding: 6px;
    border-radius: 8px;
    align-items: center;
    user-select: none;

    border: medium solid #474747;
    ${Transitions(0.25)}
    &:hover {
        background-color: #373737;
        filter: saturate(180%);
    }

    &:active {
        box-shadow: 2px 2px 2px black;
        z-index: 9;
    }

    font-weight: 600;

    box-shadow: 4px 4px 4px black;
`

export const Button = styled(Slider)`
    flex-basis: 100%;

    ${({deselected}) => css`
        background-color: ${deselected ? "#111111" : "#272727"};
        color: ${deselected ? "#AAAAAA" : "white"};
    `}

    &:hover {
        transform: translate(0px, -2px);
    }

    &:active {
        transform: translate(0px, 0px);
    }
`

export const SwitchButtonBank = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-basis: 100%;
`

export const Header = styled.div`
    font-weight: 600px;
    font-size: 30px;
    align-self: center;
    justify-text: center;
`
