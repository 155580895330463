export const colorMapping = {
    FLAG: { primary: "#0BD55C", secondary: "#ECFEF3" },
    PC: { primary: "#00A7E1", secondary: "#EBFAFF" },

    SKIP: { primary: "#7A7A7A", secondary: "#E0E0E0" },
    RTRN: { primary: "#474747", secondary: "#E0E0E0" },
    LINK: { primary: "#00A7E1", secondary: "#EBFAFF" },

    COMPA: { primary: "#007AA3", secondary: "#ADEBFF" },
    COMPB: { primary: "#09AE4B", secondary: "#B1FBCF" },
    COMPR: { primary: "#A366E1", secondary: "#EBDDF8" },

    IADN: { primary: "#D11026", secondary: "#FEECEE" },
    IADF: { primary: "#089B43", secondary: "#ECFEF3" },

    GPA: { primary: "#EF233C", secondary: "#FBF7DA" },
    GPB: { primary: "#FF9B42", secondary: "#FBF7DA" },
    GPC: { primary: "#0BD55C", secondary: "#FBF7DA" },
    GPD: { primary: "#00A7E1", secondary: "#FBF7DA" },
    GPE: { primary: "#7A28CB", secondary: "#FBF7DA" },
    GPF: { primary: "#3D68D6", secondary: "#FBF7DA" },
    GPG: { primary: "#F36878", secondary: "#FBF7DA" },
    GPH: { primary: "#272727", secondary: "#FBF7DA" },

    RBASE: { primary: "#007AA3", secondary: "#ADEBFF" },
    ROFST: { primary: "#007AA3", secondary: "#ADEBFF" },
    RMEM: { primary: "gold", secondary: "#FBF7DA" },

    WBASE: { primary: "#09AE4B", secondary: "#B1FBCF" },
    WOFST: { primary: "#09AE4B", secondary: "#B1FBCF" },
    WMEM: { primary: "gold", secondary: "#FBF7DA" },

    ALUA: { primary: "#00A7E1", secondary: "#EBFAFF" },
    ALUB: { primary: "#00A7E1", secondary: "#EBFAFF" },
    ALUM: { primary: "#7A28CB", secondary: "#EBDDF8" },
    ALUR: { primary: "#3D68D6", secondary: "#DDE5F8" },

    FPUA: { primary: "#00A7E1", secondary: "#EBFAFF" },
    FPUB: { primary: "#00A7E1", secondary: "#EBFAFF" },
    FPUM: { primary: "#7A28CB", secondary: "#EBDDF8" },
    FPUR: { primary: "#B52684", secondary: "#F8DDEF" },

    IMM: { primary: "grey", secondary: "#EBFAFF" },
    DMEM: { primary: "gold", secondary: "#8B878A" },
}

export const dmemRegex = /(DMEM)[0-3][0-9A-F]/

export const getColorsForInstruction = (inst) => {
    if (!inst || inst === "DMEM") {
        return undefined
    }

    const color = colorMapping[inst.toUpperCase()]
    if (!color) {
        if (inst.search(dmemRegex) !== -1) {
            return colorMapping["DMEM"]
        } else {
            return undefined
        }
    }
    else {
        return color
    }
}

export const EXAMPLE_PROGRAM = (
`0x01 ALUM
0x02 ALUA
0x04 ALUB
ALUR RBASE
0x02 ROFST
IADF WBASE
SKIP PC
0x00 0x00
0x00 0x14
RMEM WMEM
`
)
