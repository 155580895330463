import styled, { css } from "styled-components"

export const Outer = styled.div`
    background-color: #272727;
    margin-top: -40px;
    margin-left: -75px;
    margin-right: -85px;
    margin-bottom: -85px;

    padding-left: max(325px, 25%);
    padding-right: max(200px, 25%);
    padding-top: 80px;

    height: 100%;

    overflow-y: scroll;
    min-height: calc(100% + 45px);


    color: white;
    font-size: 20px;
    font-weight: 350;
    line-height: 32px;

    a {
        color: #00A7E1;
        transition: 0.2s;

        :hover {
            transition: 0.2s;
            color: #EF233C;
        };
    }

    h1 {
        line-height: 40px;
    }

    h2 {
        margin-top: 60px;
    }

    h3 {
        margin-top: 40px;
    }

    p + p {
        margin-top: 60px;
    }
`

export const ImageR = styled.img`
    width: 50%;
    float: right;
    padding: 22px;
`

export const CaptionImage = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 28px;
`

export const CenteredCaptionImage = styled(CaptionImage)`
    align-items: center;
    width: 100%;
    margin: 28px 0 28px 0;
`

export const FloatingCaptionImage = styled(CaptionImage)`
    float: right;
`

export const ImageForCaption = styled.img`
    padding: 8px;
    width: min(100%, 700px);
    flex: 1;
`

export const ImagePair = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: center;
    gap: 20px;

    img {
        width: 50%;
    }
`

export const Caption = styled.span`
    color: white;
    font-style: italic;
    align-self: center;
    font-size: 16px;
    padding: 12px;
    background-color: #111111;
    border-radius: 6px;
`

export const Quote = styled.blockquote`
    background-color: #111111;
    border-radius: 0 8px 8px 0;
    padding: 22px;
    font-style: italic;
    border-left: 12px solid #555555;
`
