export const createNode = ({
    children=[],
    opReducer,
    parent,
    terminal=true,
    text,
}) => ({
    children,
    opReducer,
    name: text,
    parent,
    terminal,
    text,
})

export const createRoot = (name, opReducer) => {
    return createNode({
        opReducer,
        text: name,
        terminal: true,
    })
}

export const insertNode = (parent, name, opReducer) => connectNode(parent, createNode({text: name, opReducer}))

export const copyTree = (root) => {
    // if there are no children, return a new object with thes same KVPs
    if (!root.children.length) {
        return copyNode(root)
    }

    //else, we do have children
    //we need to deep copy the children's trees and connect them to this one

    //copy all the children
    const childrenCopies = root.children.map(child => copyTree(child))
    //copy the root
    const rootCopy = copyNode(root)

    //attach all copies of children to the copy of the root
    childrenCopies.forEach(child => child.parent = rootCopy)
    rootCopy.children = childrenCopies

    return rootCopy
}

//creates a copy of a node with on parent or children connections
//opReducer points to the same function
export const copyNode = (node) => {
    return createNode({
        children: [],
        opReducer: node.opReducer,
        name: node.name,
        parent: undefined,
        terminal: node.terminal,
        text: node.text
    })
}

//adds the child to the parent's children
export const connectNode = (parent, child) => {
    parent.children.push(child)
    child.parent = parent
    parent.terminal = false
    return child
}

//inserts node between child and child's parent
export const insertNodeBetween = (node, child) => {
    node.parent = child.parent

    connectNode(node, child)

    if (node.parent) {
        const index = node.parent.children.findIndex(offspring => offspring === child)
        node.parent.children[index] = node
    }
}

export const replaceSubtree = (oldRoot, newRoot) => {
    //make the parent of the old subtree the parent of the new subtree
    newRoot.parent = oldRoot.parent

    if (newRoot.parent) {
        //find where the old subtree was in the children of the parent
        const index = newRoot.parent.children.findIndex(child => child === oldRoot)
        //replace the old subtree with the new subtree in the parent's children list
        newRoot.parent.children[index] = newRoot
    }

    return newRoot
}

export const stringPreorderTraversal = (root) => {
    const subtrees = root.children.map(child => stringPreorderTraversal(child))
    return `${root.text}${subtrees.length ? `(${subtrees.join(", ")})` : ""}`
}

export const inorderTraversal = (root) => {
    if (!root.children.length)
        return root.text

    const left = inorderTraversal(root.children[0])
    const right = inorderTraversal(root.children[1])

    return `(${left} ${root.text} ${right})`
}

export const preorderTraversal = (root) => {
    const subtrees = root.children.map(child => preorderTraversal(child))
    return [root, ...subtrees].flat()
}

export const evaluate = (root) => {
    const subtrees = root.children.map(child => evaluate(child))
    return root.opReducer(subtrees)
}
