import styled, { css } from "styled-components"

import {
    BGColor,
    FGColor,
    Floating,
    RoundSides,
    Transitions
} from "utility/StyleUtility"

export const Outer = styled.div`
    background-color: #272727;
    margin-top: -40px;
    margin-left: -75px;
    margin-right: -85px;
    margin-bottom: -85px;

    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;

    padding: 40px 120px;
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;

    width: 600px;
    min-width: 600px;
`

export const TopGrid = styled.div`
    display: grid;
    grid-template-rows: 50% 50%;
    height: 100%;
`

export const FlexWrapper = styled.div`
    display: flex;
`

export const BankSet = styled.div`
    display: grid;
    // flex-direction: column;
    // flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    place-content: center;

    // grid-template-columns: 33% 40% 33%;
    grid-template-columns: 4fr 4fr 3fr;

`

export const FloatingButtons = styled.div`
    display: flex;
    place-items: center;
    place-content: center;
    flex-direction: row;
`

export const ButtonBank = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    padding: 8px;
    place-content: center;
`

export const GridRow = styled.div`
    display: flex;
    flex-direction: column;
    place-content: center;
`

export const BaseButton = styled.div`
    display: flex;
    border-radius: 6px;
    padding: 8px;

    flex-basis: 10%;
    text-align: center;
    place-content: center;

    border: medium solid #474747;

    display: flex;
    flex-direction: column;
    gap: 2px;

    user-select: none;

    ${({deselected}) => css`
        background-color: ${deselected ? "#111111" : "#272727"};
        color: ${deselected ? "#AAAAAA" : "white"};
    `}

    ${Transitions(0.25)}
`

export const Button = styled(BaseButton)`
    &:hover {
        transform: translate(0px, -2px);
        filter: saturate(180%);
    }

    &:active {
        box-shadow: 2px 2px 2px black;
        z-index: 9;
        transform: translate(0px, 0px);
    }

    font-weight: 600;

    box-shadow: 4px 4px 4px black;
`

export const DisabledButton = styled(BaseButton)`
    background-color: #111111;
    color: #AAAAAA;
`

export const DeselectedButton = styled(Button)`
    background-color: #111111;
    color: #AAAAAA;
`

export const WideButton = styled(Button)`
    min-width: 200px;
`
