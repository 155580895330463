import styled, { css } from "styled-components"

import { Floating, RoundSides, Transitions } from "utility/StyleUtility"

export const Outer = styled.div`
    display: flex;
    flex-direction: column;

    gap: 12px;
`

export const Control = styled.div`
    background-color: white;
    user-select: none;

    padding: 12px;

    color: #272727;
    line-height: 0px;
    font-size: 72px;

    display: flex;
    flex-direction: column;
    text-align: center;

    span {
        font-size: 12px;
        opacity: 0;
        ${Transitions(0.65)}

    }

    :hover {
        span {
            opacity: 1;
        }
    }

    ${Floating}
    ${Transitions(0.15)}
    ${RoundSides}
`

