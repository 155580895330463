import React from "react"
import { useSelector, useDispatch } from "react-redux"

import styled from "styled-components"

import { setColor, selectColor } from "slices/MainSlice"

const transitionTime = "0.24s"

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    user-select: none;
`

const LeftSide = styled.div`
    display: flex;

    justify-content: center;
    align-items: center;

    flex-basis: 50%;

    border-right: thick solid red;

    margin-right: 10px;

    transition: ${transitionTime};
    background-color: white;

    &:hover {
        transition: ${transitionTime};
        float: right;

        background-color: khaki;
    }
`

const RightSide = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 100px;
    min-height: 100px;

    height: 100vh;

    flex-basis: 50%;
`

const Item1 = styled.div`
    display: flex;
    flex: 1;
    background-color: white;

    justify-content: center;
    align-items: center;

    border-left: thick solid blue;
    border-bottom: thick solid blue;
    margin-bottom: 5px;

    border-radius 0px 0px 0px 30px;

    transition: ${transitionTime};

    &:hover {
        transition: ${transitionTime};
        float: right;

        background-color: salmon;
    }
`

const Item2 = styled.div`
    display: flex;
    flex: 1;
    background-color: white;

    justify-content: center;
    align-items: center;

    border-left: thick solid gold;
    border-top: thick solid gold;
    margin-top: 5px;

    border-radius 30px 0px 0px 0px;

    transition: ${transitionTime};

    &:hover {
        transition: ${transitionTime};
        float: right;

        background-color: cornflowerblue;
    }
`

export const Home = (props) => {
    const color = useSelector(selectColor)
    const dispatch = useDispatch()
    
    return (
        <Container>
            <LeftSide>
                Welcome to the {color} side.
            </LeftSide>
            <RightSide>
                <Item1
                    onClick={() => {
                        dispatch(setColor("red"))
                        props.history.push("/article/1")
                    }}
                >
                    Enter here
                </Item1>
                <Item2
                    onClick={() => {
                        dispatch(setColor("blue"))
                        props.history.push("/article/2")
                    }}
                >
                    Enter here
                </Item2>
            </RightSide>
        </Container>
    )
}

export default Home