import React from "react"

import * as S from "components/Cpu/Style"
import { FRValueContainer } from "./Style"

import { DISPLAYS } from "components/Cpu/contants"

const RC = () => {
    const colors = [
        // "red", "green", "blue", "yellow", "pink", "orange", "gray", "cyan", "salmon", "cornflowerblue", "white", "lime", "gold"
        "#5A5A66", "#A4C2A8", "#ACEB98", "#87FF65", "#F87666", "#FB4B4E",
    ]
    return colors[Math.floor(Math.random()*colors.length)]
}

const VAL = () => (Math.random() * 1000).toFixed(2)

const Register = ({label, value}) => {
    return (
        <S.FancyRegister>
            <S.FRLabel>
                {label}
            </S.FRLabel>
            <S.FRValueContainer>
                {value}
            </S.FRValueContainer>
        </S.FancyRegister>
    )
}

const regs = Object.values(DISPLAYS).map(r => {
    // console.log(r)
    // return Register({label: r.name, value: VAL()})
    return <Register label={r.name} value={VAL()}/>
})

const get_regs = (area) => (
    Object.values(DISPLAYS).filter(r => {
        return r.area === area
    }).map(r => {
        return <Register label={r.name} value={VAL()}/>
    })
)

const get_preview = (area) => {
    return `
    00 AA 11 BB 22 CC 33 DD
    00 AA 11 BB 22 CC 33 DD
    00 AA 11 BB 22 CC 33 DD
    00 AA 11 BB 22 CC 33 DD
    00 AA 11 BB 22 CC 33 DD
    00 AA 11 BB 22 CC 33 DD
    00 AA 11 BB 22 CC 33 DD
    00 AA 11 BB 22 CC 33 DD
    `
}

const NewGrid = () => (
    <S.OuterContainer>
        <S.GridContainer2>
            <S.status>{get_regs("status")}</S.status>
            <S.registers>
                <S.prog>{get_regs("prog")}</S.prog>
                <S.gp>{get_regs("gp")}</S.gp>
                <S.math>{get_regs("math")}</S.math>
            </S.registers>
            <S.memory>
                <S.rregs2>{get_regs("rregs")}</S.rregs2>
                <S.rmem2><S.MemPreview>{get_preview("rmem")}</S.MemPreview></S.rmem2>
                <S.wregs2>{get_regs("wregs")}</S.wregs2>
                <S.wmem2><S.MemPreview>{get_preview("wmem")}</S.MemPreview></S.wmem2>
            </S.memory>
        </S.GridContainer2>
    </S.OuterContainer>
)

const TestArea = () => (
    <S.Area>
        <S.FancyRegister>
            <S.FRLabel>
                GPA
            </S.FRLabel>
            <FRValueContainer>
                value
            </FRValueContainer>
        </S.FancyRegister>
    </S.Area>
)

const SimpleGrid = () => (
    <S.FlexHud>
        {regs}
    </S.FlexHud>
)

const FirstGrid = () => (
    <S.OuterContainer>
        <S.GridContainer>
            <S.regs>
                <S.GridReg gridArea="exe" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="pc" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="skip" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="rtrn" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="link" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="compa" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="compb" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="compr" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="iadn" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="iadf" color={RC()}>{VAL()}</S.GridReg>

                <S.GridReg gridArea="gpa" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="gpb" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="gpc" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="gpd" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="gpe" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="gpf" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="gpg" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="gph" color={RC()}>{VAL()}</S.GridReg>

                <S.GridReg gridArea="alum" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="alua" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="alub" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="alur" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="fpum" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="fpua" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="fpub" color={RC()}>{VAL()}</S.GridReg>
                <S.GridReg gridArea="fpur" color={RC()}>{VAL()}</S.GridReg>
            </S.regs>
            <S.r>
                <S.rram><S.GridReg color={RC()}>{VAL()}</S.GridReg></S.rram>
                <S.rregs>
                    <S.GridReg gridArea="rbase" color={RC()}>{VAL()}</S.GridReg>
                    <S.GridReg gridArea="rofst" color={RC()}>{VAL()}</S.GridReg>
                    <S.GridReg gridArea="rmem" color={RC()}>{VAL()}</S.GridReg>
                </S.rregs>
            </S.r>
            <S.w>
                <S.wram><S.GridReg color={RC()}>{VAL()}</S.GridReg></S.wram>
                <S.wregs>
                    <S.GridReg gridArea="wbase" color={RC()}>{VAL()}</S.GridReg>
                    <S.GridReg gridArea="wofst" color={RC()}>{VAL()}</S.GridReg>
                    <S.GridReg gridArea="wmem" color={RC()}>{VAL()}</S.GridReg>
                </S.wregs>
            </S.w>
            <S.GridReg gridArea="inst" color={RC()}>{VAL()}</S.GridReg>
            <S.GridReg gridArea="flag" color={RC()}>{VAL()}</S.GridReg>
        </S.GridContainer>
    </S.OuterContainer>
)

export const Cpu = (props) => {
    return (
        <React.Fragment>
            <NewGrid/>
            <SimpleGrid/>
            <TestArea/>
            <FirstGrid/>
        </React.Fragment>
    )
}

export default Cpu
