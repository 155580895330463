import { current } from "@reduxjs/toolkit"
import * as Tree from "gp/tree"

//nullary operators
export const negativeOne = () => () => -1
export const zero = () => () => 0
export const one = () => () => 1
export const two = () => () => 2
export const e = () => () => Math.E
export const random = () => () => Math.random()
export const accumulator = (state) => () => state.accumulator
export const currentIndex = (state) => () => state.index
export const currentValue = (state) => () => state.array[state.index]
export const length = (state) => () => state.array.length

//unary operators
export const index = (state) => (values) => state.array[values[0]]
export const sine = () =>  (values) => Math.sin(values[0])

//binary operators
export const exponent = () => (values) => Math.pow(values[0], values[1])
// export const divide = (values) => values[0] / values[1]

//n-ary operators
export const add = () => (values) => values.reduce((acc, val) => acc + val, 0)
// export const multiply = () => (values) => values.reduce((acc, val) => acc * val, 0)
export const multiply = () => (values) => values.slice(1).reduce((acc, val) => acc * val, values[0])
export const subtract = () => (values) => values.slice(1).reduce((acc, val) => acc - val, values[0])
export const divide = () => (values) => values.reduce((acc, val) => acc / val, 0)

export const allOperators = [
    negativeOne,
    zero,
    one,
    two,
    e,
    random,
    accumulator,
    currentIndex,
    currentValue,
    index,
    length,
    sine,
    exponent,
    add,
    multiply,
    subtract,
    divide,
]

const randomInt = (range) => Math.floor(Math.random() * Math.floor(range))
const randomElement = (array) => array[randomInt(array.length)]

//randomly mutate nodes in the genome's tree
//mutation occurs with a change of mutationRate
//until mutation fails to occur once
export const mutateGenome = (genome, mutationRate, legalOperators) => {
    const allNodes = Tree.preorderTraversal(genome)

    while (Math.random() < mutationRate) {
        mutateNode(randomElement(allNodes), legalOperators)
    }
}

//change the operator of a node to a random operator
export const mutateNode = (node, legalOperators) => {
    const newOperator = randomElement(legalOperators)
    node.opReducer = newOperator
}

//return a new tree that is a crossover of the two parents
export const crossover = (parent1, parent2) => {
    //copy parent 1 to be the new genome
    const newGenome = Tree.copyTree(parent1)
    
    //pick a random subtree in the new genome to replace
    const allGenomeNodes = Tree.preorderTraversal(newGenome)
    const site = randomElement(allGenomeNodes)

    //pick a random subtree to insert from parent 2
    const allDonorNodes = Tree.preorderTraversal(parent2)
    const donorSite = randomElement(allDonorNodes)

    //insert the new subtree into the new genome at the chosen site
    const newRoot = Tree.copyTree(donorSite)
    Tree.replaceSubtree(site, newRoot)

    //return the new genome
    return newGenome
}

export const randomGenome = (numGenes, legalOperators) => {
    const rootOperator = randomElement(legalOperators)
    const root = Tree.createRoot(rootOperator.name, rootOperator)
    const addedNodes = [root]

    for (let i = 0; i < numGenes - 1; i++) {
        const nodeOperator = randomElement(legalOperators)
        const node = Tree.insertNode(randomElement(addedNodes), nodeOperator.name, nodeOperator)
        addedNodes.push(node)
    }

    return root
}

//returns a genome, the winner of a tournament held in the population
//evaluatedPopulation is an array of objects of shape {
    //genome: GP tree
    //fitness: fitness score
//}
export const tournament = (evaluatedPopulation, tournamentSize) => {
    console.assert(tournamentSize < evaluatedPopulation.length)

    const copiedList = evaluatedPopulation.map(genome => genome)
    const participants = []

    for (let i = 0; i < tournamentSize; i++) {
        //splice returns a list, but in this case with always only 1 element
        const participant = copiedList.splice(randomInt(copiedList.length), 1)[0]
        participants.push(participant)
    }

    const winner = participants.reduce((acc, participant) => {
        if (!acc || participant.fitness > acc.fitness)
            return participant
        return acc
    })

    return winner.genome

}

export const procreate = (evaluatedPopulation, tournamentSize, mutationRate, legalOperators) => {
    const newPopulation = []

    for (let i = 0; i < evaluatedPopulation.length; i++) {
        const parent1 = tournament(evaluatedPopulation, tournamentSize)
        const parent2 = tournament(evaluatedPopulation, tournamentSize)
        const newGenome = crossover(parent1, parent2)
        mutateGenome(newGenome, mutationRate, legalOperators)
        newPopulation.push(newGenome)
    }

    return newPopulation
}

//evaluates an array of GP trees on a fitness function ((genome) => fitness)
//returns an array of objects of shape {
    //genome: GP tree
    //fitness: fitness score
//}
export const evaluatePopulation = (population, fitnessFunction) => {
    return population.map(genome => ({
        genome,
        fitness: fitnessFunction(genome),
    }))
}

//genome is a GP tree
//inputs is an array of objects with shape: {
    //array: input array
    //answer: correct answer for the input array
//}
export const inverseErrorSquaredFitnessFunc = (inputs) => (genome) => {
    const errorSquared = inputs.reduce((acc, input) => {
        const error = Math.abs(input.answer - evaluateTree(genome, input.array))
        return acc += (error * error)
    }, 0)

    if (1000 / errorSquared > 3) debugger
    return 1000 / errorSquared
}

export const evaluateTree = (root, inputArray) => {
    const state = {
        array: inputArray,
        index: 0,
        accumulator: 0,
    }

    const originalOpReducers = []

    //Each node in the tree needs its opReducer to transformed
    //by being invoked with the accumulation state
    //We need to restore the original opReducer functions
    //when we're done because we shouldn't mutate the tree
    Tree.preorderTraversal(root).forEach(n => {
        //save pairs of nodes and their original functions
        originalOpReducers.push({
            node: n,
            originalOpReducer: n.opReducer,
        })
        
        //unwrap the operators by passing them the accumulation state
        n.opReducer = n.opReducer(state)
    })

    state.array.forEach((val, idx) => {
        state.index = idx
        state.accumulator = Tree.evaluate(root)
    })

    //restore all the original opReducer functions
    originalOpReducers.forEach(n => {
        n.node.opReducer = n.originalOpReducer
    })

    return state.accumulator
}

export const nextGeneration = (population, fitnessFunction, tournamentSize, mutationRate, legalOperators) => {
    const evaluatedPopulation = evaluatePopulation(population, fitnessFunction)
    const nextPopulation = procreate(evaluatedPopulation, tournamentSize, mutationRate, legalOperators)
    return nextPopulation
}

export const newPopulation = (populationSize, genomeSize, legalOperators) => {
    const newGenomes = []

    for (let i = 0; i < populationSize; i++) {
        newGenomes.push(randomGenome(genomeSize, legalOperators))
    }

    return newGenomes
}

export const evolve = ({
    numGenerations,
    fitnessFunction,
    populationSize,
    tournamentSize,
    mutationRate
}) => {
    const initialPopulation = newPopulation(populationSize, 3, allOperators)

    let currentPopulation = initialPopulation;

    for (let i = 0; i < numGenerations; i++) {
        const evaluatedPopulation = evaluatePopulation(currentPopulation, fitnessFunction)
        const nextGeneration = procreate(evaluatedPopulation, tournamentSize, mutationRate, allOperators)
        currentPopulation = nextGeneration


        /////////////////////////////////////////
        //instrumentation
        const winner = evaluatedPopulation.reduce((acc, participant) => {
            if (!acc || participant.fitness > acc.fitness)
                return participant
            return acc
        })
        console.log(`Generation ${i}:`, evaluatedPopulation)
        console.log("High score:", winner.fitness)
        if (winner.fitness > 3) debugger
        /////////////////////////////////////////
    }

    return currentPopulation
}

const ff1_input_generator = () => {
    const wordList = [
        "a",
        "bacon",
        "cab",
        "delicious",
        "eggs",
        "free",
        "ghostly",
        "hello",
        "inescapable",
        "justice",
        "kangaroo",
        "lemon",
        "money",
        "natural",
        "overt",
        "pal",
        "query",
        "rescue",
        "substantial",
        "tango",
        "uranium",
        "violin",
        "whiskers",
        "xylum",
        "yummy",
        "zebra"
    ]

    return wordList.map(word => {

        const letterMap = {}

        word.split("").forEach((char) => {
            if (letterMap[char]) {
                letterMap[char] = letterMap[char] + 1
            } else {
                letterMap[char] = 1
            }
        })

        const numDuplicates = Object.values(letterMap).reduce((acc, value) => {
            return value > 1 ? acc + (value - 1) : acc
        }, 0)

        const numDistinct = Object.keys(letterMap).length

        const score = (numDistinct * 2) - (numDuplicates * 3)

        return {
            array: word.split(""),
            answer: score,
        }
    })
}

export const ff1 = inverseErrorSquaredFitnessFunc(ff1_input_generator())
