import React, { useEffect, useRef, useState } from "react"

import GameCanvas from "components/GameCanvas/GameCanvas"

import {
    vec,
    drawVector,
    randomVector,
    randomInt,
} from "components/GameCanvas/GameUtility"

var frame = 0

var nodeCounter = 0

const safeLog = (...x) => {
    if (frame % 500 == 0) {
        console.log(...x)
    }
}

const drawGraph = (ctx, graph) => {
    // draw each node
    Object.values(graph.nodes).forEach(node => {
        const color = node.color || vec(255, 255, 255)
        ctx.strokeStyle = `rgb(${color.x}, ${color.y}, ${color.z}, 1.0)`
        // ctx.fillStyle = `rgb(${color.x}, ${color.y}, ${color.z}, 1.0)`
        ctx.beginPath()
        ctx.arc(node.position.x, node.position.y, 10, 0, 2 * Math.PI)
        ctx.stroke()
        // ctx.fill()
    })

    // draw each edge
    Object.values(graph.nodes).forEach(node => {
        const color = node.color || vec(255, 255, 255)
        ctx.strokeStyle = `rgb(${color.x}, ${color.y}, ${color.z}, 1.0)`
        ctx.beginPath()

        Object.keys(node.outs).forEach(destName => {
            const dest = graph.nodes[destName]
            ctx.moveTo(node.position.x, node.position.y)
            ctx.lineTo(dest.position.x, dest.position.y)
        })

        ctx.stroke()
    })
}

const makeNode = (maxX = 500, maxY) => {
    const name = nodeCounter
    nodeCounter += 1
    const randomPosition = randomVector().scale(maxX / 2).add(vec(maxX / 2, maxX / 2))
    return {
        position: vec(randomPosition.x, randomPosition.y, 0),
        color: randomVector().scale(128).add(vec(128, 128, 128)),
        ins: {},
        outs: {},
        name,
    }
}

const draw = (gameState) => {
    let {
        ctx,
        deltaTime,
        time,
        centerX,
        centerY,
        maxX,
        maxY,

        graph,
    } = gameState.current

    if (isNaN(deltaTime)) {
        deltaTime = 1
    }

    if (!graph) {
        const nodes = {}
        for (let i = 0; i < 128; i++)
        {
            const newNode = makeNode(maxX)
            nodes[newNode.name] = newNode
        }

        Object.values(nodes).forEach(node => {
            while (Math.random() < 0.31) {
                const otherNode = Object.values(nodes)[randomInt(Object.values(nodes).length)]
                if (node !== otherNode)
                {
                    console.log("[graph build] linking")
                    const weight = Math.random()
                    node.outs[otherNode.name] = weight
                    otherNode.ins[node.name] = weight
                }
            }
        })

        graph = {
            nodes,
        }
        gameState.current.graph = graph
    }

    safeLog("nodes", graph)

    drawGraph(ctx, graph)

    const repelConstant = 100000.0
    const springConstant = 2.5
    const gravityConstant = 100
    const originPosition = vec(centerX, centerY)

    // repel nodes from each other and add tension from edges
    const nodeForces = {}

    Object.values(graph.nodes).forEach(node => {
        // the force on a node in a frame is the sum of:
        //  magnetism: nodes repel each other
        //  springs: edges pull nodes together
        //  gravity: nodes are attracted to the origin by a constant force
        let force = vec()

        // all nodes are repelled magnetically by all other nodes
        Object.values(graph.nodes).forEach(otherNode => {
            if (node !== otherNode) {
                // const totalEdges = Math.max(1, Math.sqrt((
                //     Object.keys(node.ins).length
                //     + Object.keys(node.outs).length
                //     + Object.keys(otherNode.ins).length
                //     + Object.keys(otherNode.outs).length
                // )))

                const totalEdges = Math.max(1, (
                    Object.keys(node.ins).length
                    + Object.keys(node.outs).length
                    + Object.keys(otherNode.ins).length
                    + Object.keys(otherNode.outs).length
                ))

                const difference = node.position.sub(otherNode.position)
                force = force.add(
                    difference.normalize().scale((totalEdges * repelConstant * deltaTime * 0.001) / (difference.magnitude * difference.magnitude))
                )
            }
        })

        // all edges work like springs compressing between the terminal nodes
        const edgeSets = [node.ins, node.outs]
        edgeSets.forEach(edges => {
            Object.keys(edges).forEach(sourceName => {
                const source = graph.nodes[sourceName]
                const difference = node.position.sub(source.position)
                const attraction = -1 * springConstant * difference.magnitude * deltaTime * 0.001
                force = force.add(
                    difference.normalize().scale(attraction)
                )
            })
        })

        // all nodes are attracted to the center by a constant force
        const differenceFromOrigin = node.position.sub(originPosition)
        force = force.add(
            differenceFromOrigin.normalize().scale(-1 * gravityConstant * deltaTime * 0.001)
        )

        nodeForces[node.name] = force

    })

    // apply the forces as motion only after all forces for all nodes has been calculated
    Object.values(graph.nodes).forEach(node => {
        node.position = node.position.add(nodeForces[node.name])
    })

    // randomly connect and disconnect nodes
    if (Math.random() < 0.00) {
        const allNodes = Object.values(graph.nodes)
        const targetA = allNodes[randomInt(allNodes.length)]
        const targetB = allNodes[randomInt(allNodes.length)]

        if (targetA !== targetB)
        {
            if (targetA.ins[targetB.name])
            {
                delete targetA.ins[targetB.name]
                delete targetB.outs[targetA.name]
            }
            else if (targetA.outs[targetB.name])
            {
                delete targetA.outs[targetB.name]
                delete targetB.ins[targetA.name]
            }

            else
            {
                const weight = Math.random()
                targetA.ins[targetB.name] = weight
                targetB.outs[targetA.name] = weight
            }
        }
    }

    ctx.fillStyle = "rgb(90, 250, 40, 1.0)"
    ctx.font = "12px sans-serif"
    ctx.fillText(`dT: ${deltaTime.toFixed(3)}`, 16, 16)

    frame++
}

const GraphGame = (props) => {
    const [_, _set] = useState()

    return (
        <div key={_} style={{width: "min-content", height: "min-content", marginLeft: "100px"}}>
            <button onClick={() => _set(Math.random())}>Reset</button>
            <div style={{border: "thin solid black"}}>
                <GameCanvas
                    showBounds
                    drawFunc={draw}
                    width={1000}
                    height={1000}
                    initialGameState={{
                    }}
                />
            </div>
        </div>
    )
}

export default GraphGame
