import React, { useState } from "react"
import styled from "styled-components"

import TreeVisualizer from "react-tree-graph"
import "react-tree-graph/dist/style.css"

import * as Tree from "gp/tree"
import * as GP from "gp/gp"
import * as GP_tests from "gp/tests/gp_tests"

window.Tree = Tree

const node = (text, opReducer) => Tree.createNode({text, opReducer})

const insert = (parent, text, opReducer) => Tree.connectNode(parent, node(text, opReducer))

const plus = (values) => values.reduce((acc, val) => acc + val)
const multiply = (values) => values.reduce((acc, val) => acc * val)

const randomInt = (range) => Math.floor(Math.random() * Math.floor(range))
const randomElement = (array) => array[randomInt(array.length)]

const buildTree = (x, y) => {
    const root = node("max", (values) => Math.max(...values))
    
    const leftPlus = insert(root, "+1", plus)
    const rightPlus = insert(root, "+2", plus)

    const mult = insert(leftPlus, "*", multiply)
    insert(mult, "y1", () => y)
    insert(mult, "3", () => 3)

    insert(leftPlus, "x1", () => x)
    insert(rightPlus, "x2", () => x)
    insert(rightPlus, "x3", () => x)

    console.log(Tree.preorderTraversal(root))

    console.log("EVAL:", Tree.evaluate(root))

    return root
}

export const Gp = (props) => {
    const [data, setData] = useState({})
    const [population, setPopulation] = useState([])
    const [ePopulation, setEPopulation] = useState([])

    const graphs = population.map(genome => (
        <TreeVisualizer
            data={genome}
            width={250}
            height={250}
        />
    ))

    const eGraphs = ePopulation.map(genome => (
        <TreeVisualizer
            data={genome}
            width={800}
            height={800}
        />
    ))

    return (
        <div>
            <button onClick={() => {
                setData(buildTree(4, 2))
            }}>
                Example tree
            </button>

            <button onClick={() => {
                GP_tests.testAccumulator()
                GP_tests.testCurrentValue()
                GP_tests.testCurrentIndex()
                GP_tests.testMultiply()
            }}>
                run tests
            </button>
            <div>
                { Object.keys(data).length &&
                    <TreeVisualizer
                        data={data}
                        height={800}
                        width={800}
                    />
                }
            </div>

            {/* GP ZONE */}
            <div>
                <button
                    onClick={() => setPopulation(GP.newPopulation(10, 3, GP.allOperators))}
                >
                    Make population
                </button>
                <button
                    onClick={() => setPopulation(
                        population.map(genome => GP.crossover(genome, randomElement(population)))
                    )}
                >
                    Shuffle population
                </button>
                <button
                    onClick={() => setPopulation(
                        population.map(genome => ({}))
                    )}
                >
                    Clear genomes
                </button>
                <div>
                    { graphs }
                </div>
            </div>
            {/* E V O L U T I O N */}
            <div>
                <button
                    onClick={() => setEPopulation(GP.evolve({
                        numGenerations: 100,
                        fitnessFunction: GP.ff1,
                        populationSize: 18,
                        tournamentSize: 5,
                        mutationRate: 0.5
                    }))}
                >
                    Evolve
                </button>
                <div>
                    { eGraphs }
                </div>
            </div>
        </div>
    )
}

export default Gp
