import styled from "styled-components"

export const Outer = styled.div`
    background-color: #272727;
    margin-top: -40px;
    margin-left: -75px;
    margin-right: -85px;
    margin-bottom: -85px;

    display: flex;
    flex-direction: column;

    min-height: calc(100% + 125px);

    color: white;
    font-size: 20px;

    a {
        color: #00A7E1;
        transition: 0.2s;

        :hover {
            transition: 0.2s;
            color: #EF233C;
        };
    }
`

export const Box = styled.div`
    // :not(:first-child) {
    //     margin-top: 80px;
    // }

    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: 50px;
    margin-right: 50px;

    max-width: 1200px;
    align-self: center;

    // display: flex;

    // flex-direction: row;
`

export const ImageR = styled.img`
    width: 50%;
    float: right;
    // padding-left: 10px;
    padding: 22px;
`
