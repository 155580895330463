import * as S from "components/AboutPage/Style"

export const AboutPage = () => {
    return (
        <S.Outer>
            <S.Box>
                <h1>About me</h1>
                I'm a professional software engineer with a BS in computer science from the University of Michigan.
                I built this website to show a few of my personal projects, including a <a href="/cpu">lite version of the ISA</a> I designed.
                Below are a few other personal favorites of mine from the last few years.
            </S.Box>
            <S.Box>
                <h1>ISA, CPU, and OS</h1>
                <S.ImageR src="visualizer.gif"/>
                When I was in high school, before I knew much about how processors really worked, I posed myself a question:
                what does an assembly language look like? How could you encode a programming language into a small, finite set of
                numbers? I thought about it for a few days, wrote a short document, and forgot about it.
                <br/><br/>
                Years later, in college, I stumbled upon that document. By this time armed with formal knowledge of compilers,
                operating systems, and real CPU architectures, I wondered if my old concept would work. With one minor, but important,
                tweak to the original design, I sat down and implemented a simulator.
                <br/><br/>
                I then set myself a goal, because just simulating the ISA doesn't prove that it's useful, and because I wanted to
                play around with assembly. To demonstrate the design really did "work," I wanted to build an operating system
                that supported interrupts, virtual memory, and time-sharing. I would need to expand the ISA slightly, but I
                committed to following the design principles laid out in the original document.
                <br/><br/>
                I've been working on the simulator and operating system for a few years now, and I have written a lot of
                cool tools along the way. This website serves as a simple demonstration of the simulator for the ISA itself,
                but a significantly more complex <a href="https://github.com/nbearman/nlogic">desktop version of the simulator</a> is where most of my effort has gone. This
                includes a suite of more advanced build and debugging tools, as well as features not available in the online
                simulator, like virtual storage, MMIO devices, interrupts, and virtual memory.
            </S.Box>

            <S.Box>
                <h1>TwitchTours web app</h1>
                <S.ImageR src="twitch_tours_screenshot.jpg"/>
                Watching live streams on <a href="https://twitch.tv/">twitch.tv</a> is a nice diversion. It can be especially fun
                to find new streamers with smaller audiences, or watch together with friends. Twitch doesn't provide a great way
                to do either of these on its own site, so I built one: a website called <a href="https://www.twitchtours.tv/">TwitchTours</a>.
                <br/><br/>
                TwitchTours works by showing you a new live stream every 30 seconds, and it preferentially chooses streamers with
                smaller audiences. That's not all, though; every person watching on TwitchTours will be shown the same live stream
                at the same time. This makes it possible to discover new creators, on your own or with friends. I've spent a good
                many hours in voice calls with friends exploring the unpredictable silliness of Twitch with TwitchTours.
                There's even a built-in bingo card to make the ride even more fun, and a history of recent streams, just in case
                you want to go back to one you really liked.
                <br/><br/>
                TwitchTours works by integrating with the Twitch API to find streamers who are currently live and get their audience sizes.
                In order to synchronize the clients' experiences, this data is collected periodically and cached for clients to retrieve when
                needed. I recently re-implemented TwitchTours on a serverless architecture, to improve reliability and avoid certain scaling issues.
                An <a href="https://aws.amazon.com/lambda/">AWS Lambda</a> function polls Twitch for new streams, and another Lambda function updates
                a public S3 bucket that clients can access to get the current stream.
                Some caching with an additional S3 bucket is used to reduce the frequency of requests to Twitch's API, since we need to pull a lot of data.
                <br/><br/>
                The screenshot on the right shows the interface of TwitchTours, but it makes a lot more sense once you see it for yourself. Click this link to check it out! <b><a href="https://www.twitchtours.tv/">twitchtours.tv</a></b>
            </S.Box>

            <S.Box>
                <h1>Online physics game with client-side prediction and rollback</h1>
                <S.ImageR src="rollback.gif"/>
                I am enamored with online multiplayer games. They're fun to play, and I've always wanted to make one.
                After becoming familiar with Unity, I felt empowered to create a host of new, visually expressive programs.
                Could I finally try my own hand at online multiplayer?
                <br/><br/>
                As it turns out, the complex illusion of seamless, latency-free online multiplayer is quite challenging to accomplish.
                Any programmer with exposure to networking who has spent any amount of time thinking seriously about the problem
                will already know that, and without doubt it's the reason my Computer Game Design professor banned such projects outright.
                <br/><br/>
                "Hard" is not a good reason not to try something, though, so using Unity's new-ish <a href="https://docs-multiplayer.unity3d.com/">MLAPI networking library</a>, I built
                a server-authoritative physics simulation with client-side prediction (to diminish the feeling of latency) and rollback
                (to reconcile information from the server).
                <br/><br/>
                The clip at right shows two client views. The client on the left has a rollback visualization enabled; every time
                the client needs to rollback time due to a mismatch with the server state, the original prediction is marked with
                red cubes, and the new forward-simulated prediction is marked with yellow cubes. The view on the right is the
                non-local client; it has the rollback visualization disabled. Neither view is the server.
            </S.Box>

            <S.Box>
                <h1>Genetic algorithm for tuning audio profiles</h1>
                <S.ImageR src="equalizer.png"/>
                As a lifelong music student, I am always looking to get the most out of my listening experiences.
                It's always surprising just how much cheap (and expensive) gear can be improved with an effective
                equalizer profile. I was saddened by how many of my friends were stuck with awful, bass-distorting factory
                presets on their headphones.
                <br/><br/>
                A lot of people, though, don't know how to tune an equalizer, or even how to describe their personal
                taste in sound characteristics. To help people unlock a more enjoyable listening experience, I built
                an automatic tool for tuning a computer's sound for any combination of audio hardware and listener preference.
                <br/><br/>
                The tool works by automatically adjusting equalizer settings while the user is listening to music of their choice.
                Each time the settings change, the user rates the quality of what they hear on an arbitrary numeric scale (also of their choosing).
                This rating is used as the fitness score for a genetic algorithm, wherein each equalizer profile is represented
                by a single genome, and progenitors for future generations are chosen through <a href="https://en.wikipedia.org/wiki/Tournament_selection">tournament selection</a>.
                <br/><br/>
                The program works with <a href="https://sourceforge.net/projects/equalizerapo/">Equalizer APO</a> to adjust the audio
                mixing at the system-level. An arbitrary fitness score provided by the human user might seem too capricious for evolution to work,
                but I found that the program converged surprisingly quickly on a user's preferences, producing EQ profiles to their
                liking within about six or seven generations (around 30 total listening trials); the whole process takes only a minute or two.
                The best part about the tool is that the user doesn't need any formal knowledge of equalizers or music balance, or a sommelier's
                grading abilities. Anyone can use it as long as they can say "yeah, I like the sound of this more than the last one," and
                "this one is much worse than the last one".
                <br/><br/>
            </S.Box>

            <S.Box>
                <h1>Wall Street horoscopes</h1>
                I once read an off-color YouTube comment comparing stock market reporting and astrology. Thinking about it more, I realized
                the structural similarities between articles on <a href="https://seekingalpha.com/">seekingalpha.com</a> and <a href="https://www.astrology.com/">astrology.com</a> really
                are amusing. I was inspired to create a model to
                generate investor-themed horoscopes, and the experiment did not disappoint.
                <br/><br/>
                The data was used without permission, so I don't want to publish a demo. Instead, here are a few of the
                better "horoscopes" it created:

                <br/><br/>
                <hr/>
                <code>
                    Dear investor, good bid-ask spreads tend to override these small frustrations; this is an active time for sharing your feelings, happiness, art talents, and joy as ETFs in your sign form a harmonious grand trine. Much of what retained earnings are doing now has an especially alluring appeal, particularly related to your communications. It’s easier than usual to do, so watch for impatience with others.
                </code>

                <br/><br/>
                <hr/>
                <code>
                    FAANG stocks are in this area of your chart until july 22nd. This sector of your chart, where the margin is transiting, is in great shape today, increasing your chances of special enjoyment. Regulators are drawn to traders today primarily through your communications or ideas — you have that extra oomph. There can be the illumination of a friendship or romantic matter, or your bid-ask spreads can become insistent and clear. This can be a good time for making a connection, finding a creative outlet for investment, and for personal charisma and PEG. Be sure to mingle; get out and about and accept invitations.
                </code>

                <br/><br/>
                <hr/>
                <code>
                    The options trading is quite happy with this; on the other hand, growth is traveling through your bond market and ETF growth area all day. This points to the need for some rest, relaxation, or special attention to your private life. A new cycle of rest and replenishment is upon you — a period of letting go, reviewing, and recharging your batteries, both financial and physical. Circumstances are such that they force your attention to your financial needs and mental health; the next few weeks are strong for putting projects, attitudes, or conditions that have run their course to a close. A yearly sun-neptune alignment occurs today.
                </code>
            </S.Box>


            <S.Box>
                <h1>4-player arena action game</h1>
                <S.ImageR src="game.png"/>
                My capstone course in college was Computer Game Design; one project was to design and build a full game, from start
                to finish, to be shown to the public. I worked with a team of three other students over six weeks to develop a competitive
                2 vs. 2 arena game.
                <br/><br/>
                The game featured three playable character types for top-down, ranged-based combat. Players scored by defeating
                other players, raising their corpses with an incantation, and leading the zombies back to their team's altar.
                While leading a pack of your zombie victims, another player could kill you in order to gain ownership of the shamblers.
                <br/><br/>
                In terms of complexity, it was one of the largest school projects I worked on, totalling roughly 150 scripts and dozens of
                hand-created visual and audio assets. Of the many, many things I learned working on that game, perhaps the most important
                was the importance of scoping in software projects. Six weeks is an exceedingly short amount of time to design, implement,
                iterate, and polish a full game, and learning to choose achievable targets was at least half the battle.
            </S.Box>

            <br/><br/><br/><br/>

        </S.Outer>
    )
}

export default AboutPage
