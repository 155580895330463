import React, { useEffect, useRef, useState } from "react"

import GameCanvas from "components/GameCanvas/GameCanvas"

import {
    vec,
    drawVector,
    randomVector
} from "components/GameCanvas/GameUtility"

import * as S from "components/Games/VectorFieldStyle"

var frame = 0
var canvas2ref = null
var canvas2ctx = null
var frameTimes = []

const safeLog = (...x) => {
    if (frame % 500 == 0) {
        console.log(...x)
    }
}

const oldCalcField = (x, y) => {
    // const a = -2.2
    // const b = 0.1
    // const c = 2
    // const d = 1.01

    const a = -1.2
    const b = 0.2
    const c = 0.5 - Math.sin(frame / 500)
    const d = 0.5 - Math.cos(frame / 500)

    // const sx = x * Math.PI * 10 * (frame * 0.001)
    // const sy = y * Math.PI * 10 * (frame * 0.001)
    const sx = x * Math.PI * 10
    const sy = y * Math.PI * 10
    return vec(
        Math.sin(a * sy) + c * Math.cos(a * sx),
        Math.sin(b * sx) + d * Math.cos(b * sy),
    )

    // return vec(
    //     -y,
    //     x,
    // )

    // return vec(
    //     -2 * x - 3 * y,
    //     3 * x - 2* y
    // )

    return vec(
        y - x,
        -x - y,
    )

    return vec(
        x,
        y * Math.tan(Math.sqrt((x * x) + (y * y))),
    )
    // return vec(
    //     Math.cos(x * 2 * Math.PI),
    //     Math.sinh(y * 2 * Math.PI),
    // )
    // return vec(-2 * x * x, -2 * y)
    // return vec(x * y, (x * x / 2) - y)
    // return vec(
    //     Math.cosh(x * 2 * Math.PI),
    //     Math.sinh(y * 2 * Math.PI)
    // )
    const fx = y / (x * x + y * y)
    const fy = x / (x * x + y * y)
    return vec(fx, fy)
}


const drawCell = (ctx, position, color=vec(0, 0, 0)) => {
    ctx.fillStyle = `rgb(${color.x}, ${color.y}, ${color.z}, 1.0)`
    ctx.beginPath()
    ctx.arc(position.x, position.y, 5, 0, 2 * Math.PI)
    ctx.fill()
    // ctx.stroke()
}

const drawTrail = (ctx, cell, color) => {
    ctx.fillStyle = color || `rgb(${cell.color.x}, ${cell.color.y}, ${cell.color.z}, 1.0)`
    // ctx.fillRect(cell.position.x, cell.position.y, Math.floor(Math.random() * 7), 3)
    ctx.fillRect(cell.position.x, cell.position.y, 4, 4)
}

const draw = (gameState) => {
    const {
        ctx,
        deltaTime,
        time,
        maxX,
        maxY,

        mouseX,
        mouseY,

        cells,
        particleSpawnRateRef,

        trailsOnRef,
        shouldShowVectorsRef,
        shouldShowMouseRef,

        fieldFuncRef,

        shouldAnimateARef,
        shouldAnimateBRef,
        shouldAnimateCRef,
        shouldAnimateDRef,
        shouldAnimateERef,

        attractorARef,
        attractorBRef,
        attractorCRef,
        attractorDRef,
        attractorERef,
        attractorDegreeRef,

        vectorCountRef,
        animationRateRef,
        zoomLevelRef,

        ...otherState
    } = gameState.current

    const fieldFunc = fieldFuncRef ? fieldFuncRef.current : 0
    const showTrails = trailsOnRef && trailsOnRef.current

    const A = attractorARef ? attractorARef.current : 0
    const B = attractorBRef ? attractorBRef.current : 0
    const C = attractorCRef ? attractorCRef.current : 0
    const D = attractorDRef ? attractorDRef.current : 0
    const E = attractorERef ? attractorERef.current : 0
    const degree = attractorDegreeRef ? attractorDegreeRef.current : 1

    const shouldAnimateA = shouldAnimateARef && shouldAnimateARef.current
    const shouldAnimateB = shouldAnimateBRef && shouldAnimateBRef.current
    const shouldAnimateC = shouldAnimateCRef && shouldAnimateCRef.current
    const shouldAnimateD = shouldAnimateDRef && shouldAnimateDRef.current
    const shouldAnimateE = shouldAnimateERef && shouldAnimateERef.current

    const animationRate = animationRateRef ? animationRateRef.current : 1
    const zoomLevel = zoomLevelRef ? zoomLevelRef.current : 1

    let a = A
    let b = B
    let c = C
    let d = D
    let e = E

    if (shouldAnimateA) {
        a = A * Math.sin(frame * 0.001 * animationRate)
    }
    if (shouldAnimateB) {
        b = B * Math.sin(frame * 0.001 * animationRate)
    }
    if (shouldAnimateC) {
        c = C * Math.sin(frame * 0.001 * animationRate)
    }
    if (shouldAnimateD) {
        d = D * Math.sin(frame * 0.001 * animationRate)
    }
    if (shouldAnimateE) {
        e = E * Math.sin(frame * 0.001 * animationRate)
    }

    const calcField = (x, y) => {
        const sx = x * Math.PI * 10 * zoomLevel
        const sy = y * Math.PI * 10 * zoomLevel

        const lambda = e
        // const degree = 19

        /////////////////////
        // symmetric icon
        if (fieldFunc == 0) {
            const zzbar = (sx * sx) + (sy * sy)
            let p = a * zzbar + lambda
            let zreal = sx
            let zimag = sy
            for (let i = 1; i < degree - 2; i++)
            {
                const za = zreal * sx - zimag * sy
                const zb = zimag * sx - zreal * sy
                zreal = za
                zimag = zb
            }

            const zn = sx * zreal - sy * zimag
            p = p + b * zn
            return vec(
                p * sx + c * zreal - d * sy,
                p * sy - c * zimag + d * sx
            )
        }
        // symmetric icon
        /////////////////////

        /////////////////////
        // johnny svensson
        if (fieldFunc == 1) {
            return vec(
                d * Math.sin(sx * a) - Math.sin(sy * b),
                c * Math.cos(sx * a) + Math.cos(sy * b)
            )
        }
        // johnny svensson
        /////////////////////

        /////////////////////
        // clifford attractor
        if (fieldFunc == 2) {
            return vec(
                Math.sin(a * sy) + c * Math.cos(a * sx),
                Math.sin(b * sx) + d * Math.cos(b * sy),
            )
        }
        // clifford attractor
        /////////////////////

        /////////////////////
        // debug
        return sx < sy
            ? vec(
                sy * Math.sin(sy),
                sx >> 2,
            ) : vec(
                sy >> 2,
                sx * Math.cos(sx)
            )
        // debug
        /////////////////////

        return vec(-y, x)

    }

    const pxToField = (x, y) => {
        const fieldX = ((x / maxX) - 0.5) * 2
        const fieldY = ((y / maxY) - 0.5) * 2
        return vec(fieldX, fieldY)
    }

    const fieldToPx = (x, y) => {
        return vec(((x / 2) + 0.5) * maxX, ((y / 2) + 0.5) * maxY)
    }

    ctx.fillStyle = "rgb(0, 0, 0, 1.0)"
    ctx.fillRect(0, 0, maxX, maxY)


    if (!cells) {
        gameState.current.cells = []
    }


    // sample average move speed
    let sumSpeed = 0
    const numSamples = 500
    for (let i = 0; i < numSamples; i++) {
        const samplePoint = randomVector()
        const motion = calcField(samplePoint.x, samplePoint.y)
        sumSpeed += motion.magnitude
    }
    const sampleAvg = sumSpeed / numSamples
    safeLog("[field speed sample]", sampleAvg)

    const showVectors = shouldShowVectorsRef && shouldShowVectorsRef.current
    const numX = (vectorCountRef && vectorCountRef.current) || 35
    const numY = (vectorCountRef && vectorCountRef.current) || 35
    const vectorDensity = numX
    if (showVectors)
    {
        for (let y = 0; y < numY; y++)
        {
            const fieldY = (((0.5 + y) / numY) - 0.5) * 2
            for (let x = 0; x < numX; x++)
            {
                // const fieldX = ((((0.5 + x) * maxX / numX) / maxX) - 0.5) / 2
                const fieldX = (((0.5 + x) / numX) - 0.5) * 2
                const fieldVector = calcField(fieldX, fieldY)

                const speedPercentage = fieldVector.magnitude / sampleAvg
                drawVector({
                    ctx,
                    origin: vec((0.5 + x) * maxX / numX, (0.5 + y) * maxY / numY),
                    vector: fieldVector.normalize().scale(12),
                    // color: fieldVector.magnitude < 2
                    //     ? `rgb(${fieldVector.magnitude * 128}, 255, 0, ${0.1 + fieldVector.magnitude * 0.5})`
                    //     : `rgb(${(fieldVector.magnitude / 2) * 128}, 0, 255)`,
                    color: `rgb(${speedPercentage * 128}, 128, ${speedPercentage * speedPercentage * 10}, ${0.35 + speedPercentage * 0.6})`,
                    capped: true,
                })
            }
        }
    }


    // draw cells and move them
    if (cells) {
        const newCells = []
        cells.forEach(cell => {
            const newPosition = cell.add(calcField(cell.x, cell.y).scale(deltaTime * 0.001).scale(1 / sampleAvg)).add(vec(0, 0, 1))

            // if (newPosition.magnitude < 2) {
            //     newCells.push(newPosition)
            // }
            newCells.push(newPosition)
            // drawCell(ctx, fieldToPx(cell.x, cell.y), vec(255, 90, 252))

            const cellColor = vec(255, 90 + cell.z / 3, cell.z * 2)
            drawCell(ctx, fieldToPx(cell.x, cell.y), cellColor)

            if (showTrails)
            {
                drawTrail(canvas2ctx, {position: fieldToPx(cell.x, cell.y), color: cellColor})
            }
        })

        gameState.current.cells = newCells

        // spawn a new cell every frame
        if (particleSpawnRateRef)
        {
            for (let i = 0; i < particleSpawnRateRef.current; i++)
            {
                gameState.current.cells.push(randomVector())
            }
        }

        // kill the oldest cells when we are over the limit
        while (gameState.current.cells.length > 600)
        {
            gameState.current.cells.shift()
        }
        safeLog("Number of live cells:", cells.length)
    }

    // draw mouse
    const showMouse = shouldShowMouseRef && shouldShowMouseRef.current
    if (showMouse)
    {
        const fieldMouse = pxToField(mouseX, mouseY)
        drawVector({
            ctx,
            vector: calcField(fieldMouse.x, fieldMouse.y).normalize().scale(50),
            origin: vec(mouseX, mouseY),
            color: "rgb(255, 0, 0, 1.0)",
            capped: true,
        })
    }

    if (showTrails) {
        // draw trails over image
        if (canvas2ref && canvas2ref.current) ctx.drawImage(canvas2ref.current, 0, 0)

        const fadeRate = 2 // making this a parameter set by the UI tanks frame rate for some reason ("compiler" opt?)
        // fade the trail canvas data over time by moving pixels toward 0,0,0 every frame
        const imageData = canvas2ctx.getImageData(0, 0, maxX, maxY);
        const data = imageData.data;
        for (var i = 0; i < data.length; i += 4) {
            data[i]     = data[i] - fadeRate     // red
            data[i + 1] = data[i + 1] - fadeRate // green
            data[i + 2] = data[i + 2] - fadeRate // blue
            data[i + 3] = data[i + 3] - fadeRate
        }
        canvas2ctx.putImageData(imageData, 0, 0);
    }

    frameTimes.push(deltaTime)
    while (frameTimes.length > 30) {
        frameTimes.shift()
    }
    const FPS = frameTimes.reduce((sum, frameTime) => sum + frameTime, 0) / frameTimes.length

    // add text overlay
    const textLeftOffset = 16
    ctx.fillStyle = "rgb(0, 0, 0, 1.0)"
    ctx.fillRect(textLeftOffset - 4, 0, 125, 212)
    ctx.fillStyle = "rgb(90, 250, 40, 1.0)"
    ctx.font = "12px sans-serif"
    ctx.fillText(`dT: ${FPS.toFixed(3)}`, textLeftOffset, 16)
    ctx.fillText(`Vectors: ${showVectors ? "ON" : "off"}`, textLeftOffset, 32)
    ctx.fillText(`Trails: ${showTrails ? "ON" : "off"}`, textLeftOffset, 48)
    ctx.fillText(`Mouse: ${showMouse ? "ON" : "off"}`, textLeftOffset, 64)
    ctx.fillText(`A: ${A.toFixed(3)}    |   ${a.toFixed(3)}`, textLeftOffset, 80)
    ctx.fillText(`B: ${B.toFixed(3)}    |   ${b.toFixed(3)}`, textLeftOffset, 96)
    ctx.fillText(`C: ${C.toFixed(3)}    |   ${c.toFixed(3)}`, textLeftOffset, 112)
    ctx.fillText(`D: ${D.toFixed(3)}    |   ${d.toFixed(3)}`, textLeftOffset, 128)
    ctx.fillText(`E: ${E.toFixed(3)}    |   ${e.toFixed(3)}`, textLeftOffset, 144)
    ctx.fillText(`Degree: ${degree}`, textLeftOffset, 160)

    ctx.fillText(`Vector density: ${vectorDensity}`, textLeftOffset, 176)
    ctx.fillText(`Animation rate: ${animationRate}`, textLeftOffset, 192)
    ctx.fillText(`Zoom level: ${zoomLevel}`, textLeftOffset, 208)

    frame++
}

const makeSliderControl = (label, valueRef, min, max, valueScale, tip) => {
    if (!valueScale) {
        valueScale = 1
    }

    return (
        <S.Slider>
            <input type="range" min={min} max={max} defaultValue={valueRef.current * valueScale}
                onChange={(e) => valueRef.current = e.target.value / valueScale}
            />{label}
            {tip && <S.Tip>{tip}</S.Tip>}
        </S.Slider>
    )
}

const VectorField = (props) => {
    const [_, _set] = useState()
    const trailCanvasRef = useRef(null)

    const [size, setSize] = useState(800)

    const fieldFuncRef = useRef(0)

    const particleSpawnRateRef = useRef(0)
    const shouldShowVectorsRef = useRef(true)
    const trailsOnRef = useRef(false)
    const shouldShowMouseRef = useRef(true)

    const shouldAnimateARef = useRef(false)
    const shouldAnimateBRef = useRef(false)
    const shouldAnimateCRef = useRef(false)
    const shouldAnimateDRef = useRef(false)
    const shouldAnimateERef = useRef(false)

    const attractorARef = useRef(0.01)
    const attractorBRef = useRef(0)
    const attractorCRef = useRef(0)
    const attractorDRef = useRef(0)
    const attractorERef = useRef(0)

    const attractorDegreeRef = useRef(1)

    const vectorCountRef = useRef(35)
    const animationRateRef = useRef(1)
    const zoomLevelRef = useRef(1)

    const setPresetParameters = (index, presetIndex) => {
        attractorARef.current = fieldFuncParameterSets[index].presets[presetIndex].A || 0
        attractorBRef.current = fieldFuncParameterSets[index].presets[presetIndex].B || 0
        attractorCRef.current = fieldFuncParameterSets[index].presets[presetIndex].C || 0
        attractorDRef.current = fieldFuncParameterSets[index].presets[presetIndex].D || 0
        attractorERef.current = fieldFuncParameterSets[index].presets[presetIndex].E || 0

        shouldAnimateARef.current = fieldFuncParameterSets[index].presets[presetIndex].animateA || false
        shouldAnimateBRef.current = fieldFuncParameterSets[index].presets[presetIndex].animateB || false
        shouldAnimateCRef.current = fieldFuncParameterSets[index].presets[presetIndex].animateC || false
        shouldAnimateDRef.current = fieldFuncParameterSets[index].presets[presetIndex].animateD || false
        shouldAnimateERef.current = fieldFuncParameterSets[index].presets[presetIndex].animateE || false

        animationRateRef.current = fieldFuncParameterSets[index].presets[presetIndex].animationRate || 1.0

        attractorDegreeRef.current = fieldFuncParameterSets[index].presets[presetIndex].Degree || 0
        zoomLevelRef.current = fieldFuncParameterSets[index].presets[presetIndex].Zoom || 0
        _set(Math.random())
    }

    useEffect(() => { canvas2ref = trailCanvasRef }, [])

    useEffect(() => {
        console.log("re-render")
        canvas2ctx = canvas2ref.current && canvas2ref.current.getContext("2d")
    })

    useEffect(() => {
        setPresetParameters(fieldFuncRef.current, 0)
        particleSpawnRateRef.current = 1
    }, [])

    const makeFieldFuncButton = (label, index) => {
        return (
            <S.Button
                onClick={() => {
                    fieldFuncRef.current = index
                    setPresetParameters(index, 0)
                    _set(Math.random())
                }}
                deselected={fieldFuncRef.current != index}
            >
                {label}
            </S.Button>
        )
    }

    const fieldFuncParameterSets = [
        {
            name: "Symmetric Icon",
            A: true,
            B: true,
            C: true,
            D: true,
            E: true,
            Degree: true,
            presets: [
                { A: -1.6, B: -0.4, C: 0.18, D: -0.07, E: 0.52, Degree: 1, Zoom: 0.04, animationRate: 5.0, animateE: true},
                { A: 0.11, B: 0.20, C: 1.31, D: -7.0, E: 1.05, Degree: 6, Zoom: -0.08, },
                { A: -0.74, B: -0.17, C: 2.63, D: -0.53, E: 0.02, Degree: 5, Zoom: 0.11, },
                { A: -1.32, B: -0.1, C: 2.21, D: 1.97, E: 0.8, Degree: 7, Zoom: 0.05, animateC: true},
                { A: 0.01, B: 0.00, C: 0.00, D: -3.87, E: -4.18, Degree: 11, Zoom: 1 },
            ]
        },
        {
            name: "Johnny Svensson",
            A: true,
            B: true,
            C: true,
            D: true,
            presets: [
                { A: -2.4, B: -1.39, C: -1.51, D: 0.09, Zoom: -0.03, animateA: true, animateB: true, animateD: true},
            ]
        },

        {
            name: "Clifford Attractor",
            A: true,
            B: true,
            C: true,
            D: true,
            presets: [
                { A: -1.6, B: -0.4, C: 0.18, D: -0.07, Zoom: 0.11, },
                { A: 0.8, B: -0.05, C: -3.00, D: -0.37, Zoom: -1.68, animateB: true, animateC: true, animateD: true},
            ]
        },

        {
            name: "Debug",
            A: true,
            B: true,
            C: true,
            D: true,
            presets: [
                { A: 0.01, B: 0.0, C: 0.0, D: 0.0, Zoom: 1.0, },
                { A: 1.00, B: 1.0, C: -1.0, D: 0.5, Zoom: 0.01, },
                { A: Math.random() * 6 - 3, B: Math.random() * 6 - 3, C: Math.random() * 6 - 3, D: Math.random() * 6 - 3, Zoom: 0.05, },
            ]
        },
    ]

    return (
        <S.Outer key={_} style={{position: "relative"}}>
            <div style={{position: "fixed", right: "10%", top: "10%"}}>
                <S.Slider>
                    <input type="range" min={250} max={1000} defaultValue={size}
                        onChange={(e) => setSize(e.target.value)}
                    />Window size
                </S.Slider>
            </div>
            <S.ControlColumn>
                <S.SwitchButtonBank>
                    {
                        fieldFuncParameterSets.map((fieldFunc, idx) =>
                            makeFieldFuncButton(fieldFunc.name, idx)
                        )
                    }
                </S.SwitchButtonBank>

                <S.SwitchButtonBank>
                    {
                        fieldFuncParameterSets[fieldFuncRef.current].presets.map((preset, idx) => (
                            <S.Button
                                onClick={() => {
                                    setPresetParameters(fieldFuncRef.current, idx)
                                    _set(Math.random())
                                }}
                                deselected
                            >
                                {`Preset ${String.fromCharCode(65 + idx)}`}
                            </S.Button>
                        ))
                    }
                </S.SwitchButtonBank>

                <S.SwitchButtonBank>
                    <S.Button onClick={() => {
                        frame = 0
                        particleSpawnRateRef.current = 1
                        _set(Math.random())}
                    }>Reset</S.Button>
                    <S.Button onClick={() => {
                        particleSpawnRateRef.current = 1
                    }}>Start</S.Button>
                    <S.Button onClick={() => {
                        particleSpawnRateRef.current = 0
                    }}>Stop</S.Button>
                </S.SwitchButtonBank>

                <S.SwitchButtonBank>
                    <S.Slider>
                        <input type="checkbox" id="vectorsCheck" defaultChecked={shouldShowVectorsRef.current}
                            onClick={(e) => shouldShowVectorsRef.current = e.target.checked}
                        /><label htmlFor="vectorsCheck">Show vectors</label>
                    </S.Slider>

                    <S.Slider>
                        <input type="checkbox" id="trailsCheck"
                            onClick={(e) => trailsOnRef.current = e.target.checked}
                        /><label htmlFor="trailsCheck">Show trails</label>
                        <S.Tip>Disable for performance</S.Tip>
                    </S.Slider>

                    <S.Slider>
                        <input type="checkbox" id="mouseCheck" defaultChecked={shouldShowMouseRef.current}
                            onClick={(e) => shouldShowMouseRef.current = e.target.checked}
                        /><label htmlFor="mouseCheck">Evaluate field at mouse</label>
                    </S.Slider>
                </S.SwitchButtonBank>

                {fieldFuncParameterSets[fieldFuncRef.current].A
                    && makeSliderControl("A", attractorARef, -300, 300, 100)}

                {fieldFuncParameterSets[fieldFuncRef.current].B
                    && makeSliderControl("B", attractorBRef, -300, 300, 100)}

                {fieldFuncParameterSets[fieldFuncRef.current].C
                    && makeSliderControl("C", attractorCRef, -300, 300, 100)}

                {fieldFuncParameterSets[fieldFuncRef.current].D
                    && makeSliderControl("D", attractorDRef, -700, 700, 100)}

                {fieldFuncParameterSets[fieldFuncRef.current].E
                    && makeSliderControl("E", attractorERef, -700, 700, 100)}

                {fieldFuncParameterSets[fieldFuncRef.current].Degree
                    && makeSliderControl("Degree", attractorDegreeRef, 1, 15)}

                {makeSliderControl("Vector density", vectorCountRef, 1, 100)}
                {makeSliderControl("Animation rate", animationRateRef, -100, 100, 10)}
                {makeSliderControl("Zoom level", zoomLevelRef, -1000, 1000, 100, "Try values near 0")}

                {fieldFuncParameterSets[fieldFuncRef.current].A &&
                    <S.Slider>
                        <input type="checkbox" id="animateACheck" defaultChecked={shouldAnimateARef.current}
                            onClick={(e) => shouldAnimateARef.current = e.target.checked}
                        /><label htmlFor="animateACheck">Animate A</label>
                    </S.Slider>
                }

                {fieldFuncParameterSets[fieldFuncRef.current].B &&
                <S.Slider>
                    <input type="checkbox" id="animateBCheck" defaultChecked={shouldAnimateBRef.current}
                        onClick={(e) => shouldAnimateBRef.current = e.target.checked}
                    /><label htmlFor="animateBCheck">Animate B</label>
                </S.Slider>
                }

                {fieldFuncParameterSets[fieldFuncRef.current].C &&
                    <S.Slider>
                        <input type="checkbox" id="animateCCheck" defaultChecked={shouldAnimateCRef.current}
                            onClick={(e) => shouldAnimateCRef.current = e.target.checked}
                        /><label htmlFor="animateCCheck">Animate C</label>
                    </S.Slider>
                }

                {fieldFuncParameterSets[fieldFuncRef.current].D &&
                    <S.Slider>
                        <input type="checkbox" id="animateDCheck" defaultChecked={shouldAnimateDRef.current}
                            onClick={(e) => shouldAnimateDRef.current = e.target.checked}
                        /><label htmlFor="animateDCheck">Animate D</label>
                    </S.Slider>
                }

                {fieldFuncParameterSets[fieldFuncRef.current].E &&
                    <S.Slider>
                        <input type="checkbox" id="animateECheck" defaultChecked={shouldAnimateERef.current}
                            onClick={(e) => shouldAnimateERef.current = e.target.checked}
                        /><label htmlFor="animateECheck">Animate E</label>
                    </S.Slider>
                }

                <S.Tip>https://softologyblog.wordpress.com/2017/03/04/2d-strange-attractors/</S.Tip>
            </S.ControlColumn>
            <S.CanvasContainer size={size}>
                <GameCanvas
                    showBounds={false}
                    drawFunc={draw}
                    width={size}
                    height={size}
                    initialGameState={{
                        particleSpawnRateRef,
                        shouldShowVectorsRef,
                        shouldShowMouseRef,
                        trailsOnRef,

                        fieldFuncRef,

                        shouldAnimateARef,
                        shouldAnimateBRef,
                        shouldAnimateCRef,
                        shouldAnimateDRef,
                        shouldAnimateERef,

                        attractorARef,
                        attractorBRef,
                        attractorCRef,
                        attractorDRef,
                        attractorERef,
                        attractorDegreeRef,

                        vectorCountRef,
                        animationRateRef,
                        zoomLevelRef,
                    }}
                />
            </S.CanvasContainer>
            <canvas
                width="1000"
                height="1000"
                ref={trailCanvasRef}
                style={{display: "none"}}
            ></canvas>
        </S.Outer>
    )
}

export default VectorField

/*
https://softologyblog.wordpress.com/2017/03/04/2d-strange-attractors/

Fun presets

Johnny Svensson Attractor
A = -2.4
B = -1.39
C = - 1.51
D = 0.09
zoom = -0.03
animate A
animate B


symmetry icon attractor
A = -1.32
B = -0.1
C = 2.21
D = 1.940
lambda = 0.80
degree = 7
zoom = 0.05
animate C

symmetry icon attractor
A = -0.74
B = -0.17
C = 2.63
D = -0.53
lambda = 0.02
degree = 5
zoom = 0.11

symmetry icon attractor
A = 0.11
B = 0.20
C = 1.31
D = -7.0
lambda = 1.05
degree = 6
zoom = -0.08

symmetry icon attractor
A = -1.6
B = -0.4
C = 0.18
D = -0.07
lambda = 0.52
degree = 1
zoom = 0.04
animate E

symmetry icon attractor
A = 0.01
B = 0.00
C = 0.00
D = -3.87
lambda = -4.18
degree = 11
zoom = 1

*/
