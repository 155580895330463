import { createSlice } from "@reduxjs/toolkit"

export const cpuSlice = createSlice({
    name: "cpu",
    initialState: {
        cpu: {},
        rawInstructions: "",
        extra: {
            rmemPreview: "",
            wmemPreview: "",
            rmemAddress: 0,
            wmemAddress: 0,
            currentInstruction: {
                fromLoc: 0,
                fromLocHex: "0x00",
                fromLocName: "IMM",

                toLoc: 0,
                toLocHex: "0x00",
                toLocName: "IMM",
            }
        }
    },

    reducers: {
        updateCpu: (state, action) => {
            state.cpu = action.payload
        },

        updateExtra: (state, action) => {
            state.extra = action.payload
        },

        updateRmemPreview: (state, action) => {
            state.rmemPreview = action.payload
        },

        updateWmemPreview: (state, action) => {
            state.wmemPreview = action.payload
        },

        updateRawInstructions: (state, action) => {
            state.rawInstructions = action.payload
        },
    }
})

export const {
    updateCpu,
    updateExtra,
    updateRawInstructions,
} = cpuSlice.actions

export const selectCpu = state => state.cpu.cpu
export const selectRmemPreview = state => state.cpu.extra.rmemPreview
export const selectWmemPreview = state => state.cpu.extra.wmemPreview
export const selectRmemAddress = state => state.cpu.extra.rmemAddress
export const selectWmemAddress = state => state.cpu.extra.wmemAddress
export const selectCurrentInstruction = state => state.cpu.extra.currentInstruction
export const selectRawInstructions = state => state.cpu.rawInstructions

export default cpuSlice.reducer
