import { createSlice } from '@reduxjs/toolkit';

export const mainSlice = createSlice({
  name: 'main',
  initialState: {
    value: 0,
    color: "red",
    popup: "",
  },
  reducers: {
    increment: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: state => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    setColor: (state, action) => {
      state.color = action.payload
    },
    setBackgroundColor: (state, action) => {
      state.backgroundColor = action.payload
    },
    setPopup: (state, action) => {
      state.popup = action.payload
    },
  },
});

export const {
    increment,
    decrement,
    incrementByAmount,
    setColor,
    setBackgroundColor,
    setPopup,
} = mainSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCount = state => state.counter.value;

export const selectColor = state => state.main.color

export const selectBackgroundColor = state => state.main.backgroundColor

export const selectPopup = state => state.main.popup

export const selectExtraNavItems = (props) => (state) => {
    console.log("[selectExtraNavItems] props", props)
    const pathname = props.location.pathname

    if (pathname.startsWith("/articles")) {
        return [
            {
                path: "/articles/lens-perspective",
                text: "Lens-based Perspective",
            },
        ]
    }
    return []
}

export default mainSlice.reducer;
