import React, { useEffect, useRef, useState } from "react"

import GameCanvas from "components/GameCanvas/GameCanvas"

import {
    vec,
    drawVector,
    randomVector,
    lerp,
    lerp_scalar,
    rotatePoint,
} from "components/GameCanvas/GameUtility"

import * as S from "components/Games/ForceGameStyle"

var frame = 0
var frameTimes = []

const safeLog = (...x) => {
    if (frame % 500 == 0) {
        console.log(...x)
    }
}

const drawCell = (ctx, position, color=vec(0, 0, 0)) => {
    ctx.fillStyle = `rgb(${color.x}, ${color.y}, ${color.z}, 1.0)`
    ctx.beginPath()
    ctx.arc(position.x, position.y, 5, 0, 2 * Math.PI)
    ctx.fill()
    // ctx.stroke()
}

const drawTrail = (ctx, cell, color) => {
    ctx.fillStyle = color || `rgb(${cell.color.x}, ${cell.color.y}, ${cell.color.z}, 1.0)`
    // ctx.fillRect(cell.position.x, cell.position.y, Math.floor(Math.random() * 7), 3)
    ctx.fillRect(cell.position.x, cell.position.y, 4, 4)
}

const gameObjects = []

const init = () => {
    gameObjects.length = 0

    gameObjects.push({
        name: "ball1",
        p_state: {
            position: vec(200, 200),
            rotation: 0,
            rot_velocity: 0,
            velocity: vec(0, 0),
        },
        draw: function(ctx) {
            drawCell(ctx, this.v_state.position, vec(255, 128, 64))
        },
    })

    gameObjects.push({
        name: "box1",
        p_state: {
            position: vec(500, 500),
            rotation: Math.PI * 1.25,
            rot_velocity: Math.PI * -0.05,
            velocity: vec(0, 0),
        },
        state: {
            points: [
                vec(-50, -50),
                vec(50, -50),
                vec(50, 50),
                vec(-50, 50),
            ],
        },
        draw: function(ctx) {
            const rotatedPoints = this.state.points.map(pt => (
                rotatePoint(pt, this.v_state.rotation, vec(0, 0))
            ))

            const lastPtPos = rotatedPoints[rotatedPoints.length - 1].add(this.v_state.position)
            ctx.beginPath()
            ctx.moveTo(lastPtPos.x, lastPtPos.y)
            rotatedPoints.forEach((pt) => {
                const pos = this.v_state.position.add(pt)
                ctx.lineTo(pos.x, pos.y)
            })
            ctx.strokeStyle = "lime"
            ctx.stroke()
            rotatedPoints.forEach(pt => {
                drawCell(ctx, this.v_state.position.add(pt), vec(4 * pt.x + 50, 4 * pt.y + 50, 64))
            })
        },
    })

    // initialize all game objects' visual state and last
    // physics state to match the initial physics state
    gameObjects.forEach(obj => {
        obj.last_p_state = {...obj.p_state}
        obj.v_state = {
            position: obj.p_state.position,
            rotation: obj.p_state.rotation,
        }
    })
}

const draw = (gameState) => {
    const {
        ctx,
        deltaTime,
        time,
        maxX,
        maxY,

        mouseX,
        mouseY,

        pTime,
        pStep,
        pFrame,


        ...otherState
    } = gameState.current
    const dpTime = pStep * 0.001

    const pxToCanvas = (x, y) => {
        const fieldX = ((x / maxX) - 0.5) * 2
        const fieldY = ((y / maxY) - 0.5) * 2
        return vec(fieldX, fieldY)
    }

    const fieldToPx = (x, y) => {
        return vec(((x / 2) + 0.5) * maxX, ((y / 2) + 0.5) * maxY)
    }

    safeLog("[mouse]", mouseX, pxToCanvas(mouseX, mouseY))

    // clear the frame
    ctx.fillStyle = "rgb(0, 0, 0, 1.0)"
    ctx.fillRect(0, 0, maxX, maxY)

    //////////////////////////
    // Game loop
    //////////////////////////


    ///////////
    // Physics
    ///////////

    let doPhysics = false
    gameState.current.pTime += deltaTime || 0
    if (gameState.current.pTime > pStep) {
        gameState.current.pTime -= pStep
        gameState.current.pFrame += 1
        doPhysics = true
    }

    if (doPhysics)
    {
        const gravity = vec(0, 9.8)
        // const gravity = vec(0, 0)

        gameObjects.forEach(obj => {
            obj.last_p_state = {...obj.p_state}

            const velocity = obj.p_state.velocity.add(gravity.scale(dpTime))
            const position = obj.p_state.position.add(velocity.scale(dpTime))
            const rotation = obj.p_state.rotation + (obj.p_state.rot_velocity * dpTime)
            // TODO add air resistance / rot_vel damping
            obj.p_state = {
                velocity,
                position,
                rotation,
                rot_velocity: obj.p_state.rot_velocity,
            }
        })
    }


    ///////////
    // Animation
    ///////////

    // update the interpolated visual state of all objects
    const interpolationFactor = gameState.current.pTime / pStep
    if (interpolationFactor > 1 || interpolationFactor < 0) {
        console.error("WARNING")
    }

    gameObjects.forEach(obj => {
        const position = lerp(obj.last_p_state.position, obj.p_state.position, interpolationFactor)
        const rotation = lerp_scalar(obj.last_p_state.rotation, obj.p_state.rotation, interpolationFactor)
        obj.v_state = {
            position,
            rotation
        }
    })

    gameObjects.forEach(obj => {
        obj.draw(ctx)
    })


    const points = [
        vec(300, 300),
        vec(300, 400),
        vec(400, 400),
        vec(400, 300),
    ]

    points.forEach(p => {
        drawCell(ctx, p, vec(p.x / 2, p.y / 2, 100))
    })

    const angle = time * 0.001

    ctx.beginPath()

    points.forEach((p0, idx) => {
        const pivot = vec(350, 350)
        const rotatedPoint = rotatePoint(p0, angle, pivot)

        if (!idx) {
            ctx.moveTo(rotatedPoint.x, rotatedPoint.y)
        } else {
            ctx.lineTo(rotatedPoint.x, rotatedPoint.y)
        }
        // drawCell(ctx, rotatedPoint.add(pivot), vec(100, p.x, p.y))
    })
    ctx.fill()



    //////////////////////////
    //////////////////////////


    // draw mouse
    drawCell(ctx,vec(mouseX, mouseY), vec(1, 200, 30))


    // calc performance stats
    frameTimes.push(deltaTime)
    while (frameTimes.length > 30) {
        frameTimes.shift()
    }
    const FPS = frameTimes.reduce((sum, frameTime) => sum + frameTime, 0) / frameTimes.length
    // add text overlay
    const textLeftOffset = 16
    ctx.fillStyle = "rgb(20, 64, 20, 0.8)"
    ctx.fillRect(textLeftOffset - 4, 0, 125, 96)
    ctx.fillStyle = "rgb(90, 250, 40, 1.0)"
    ctx.font = "12px sans-serif"
    ctx.fillText(`dT: ${FPS.toFixed(3)}`, textLeftOffset, 16)
    ctx.fillText(`t: ${time.toFixed(3)}`, textLeftOffset, 32)
    ctx.fillText(`sin θ: ${Math.sin(angle).toFixed(3)}`, textLeftOffset, 48)
    ctx.fillText(`pTime: ${pTime.toFixed(3)}`, textLeftOffset, 72)
    ctx.fillText(`pFrame: ${pFrame}`, textLeftOffset, 88)
    frame++
}

const VectorField = (props) => {
    const [_, _set] = useState()

    const [size, setSize] = useState(800)

    init()

    return (
        <S.Outer key={_} style={{position: "relative"}}>
            <div style={{position: "fixed", right: "10%", top: "10%"}}>
                <S.Slider>
                    <input type="range" min={250} max={1000} defaultValue={size}
                        onChange={(e) => setSize(e.target.value)}
                    />Window size
                </S.Slider>
            </div>
            <S.CanvasContainer size={size}>
                <GameCanvas
                    showBounds={false}
                    drawFunc={draw}
                    width={size}
                    height={size}
                    initialGameState={{
                        pTime: 0.0,
                        pStep: 200,
                        pFrame: 0,
                    }}
                />
            </S.CanvasContainer>
        </S.Outer>
    )
}

export default VectorField
