import styled, { css } from "styled-components"

export const Transitions = (time) => {
    return css`
        transition: ${time}s;
        &:hover {
            transition: ${time}s;
        }
        &:active {
            transition: ${time}s;
        }
    `
}

export const Floating = () => {
    return css`
        box-shadow: 4px 4px 4px #CCCCCC;
        &:hover {
            transform: translate(0px, -2px);
        }

        &:active {
            box-shadow: 2px 2px 2px #CCCCCC;
            z-index: 9;
            transform: translate(0px, 0px);
        }
    `
}

export const RoundSides = ({sides=0b0000, r=15}) => {
    const tl = (sides >>> 3 & 0b1) && r
    const tr = (sides >>> 2 & 0b1) && r
    const br = (sides >>> 1 & 0b1) && r
    const bl = (sides >>> 0 & 0b1) && r

    return `border-radius: ${tl}px ${tr}px ${br}px ${bl}px;`
}

export const BGColor = (({color}) => color ? `background-color: ${color};` : "")
export const FGColor = (({color}) => color ? `color: ${color};` : "")
