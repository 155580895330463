import React, { useEffect } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CgChevronDoubleLeftO, CgChevronDoubleRightO } from "react-icons/cg"
import { HiOutlineChevronDoubleRight } from "react-icons/hi"
import { FaAngleDoubleLeft } from "react-icons/fa"
import { FiDivideCircle, FiPlusCircle, FiMinusCircle, FiXCircle } from "react-icons/fi"

import { faAdjust } from "@fortawesome/free-solid-svg-icons"

import { ReactComponent as SvgOr } from "OrNew.svg"

import {
    MultiplyCircle,
    Or
} from "Icons/Custom"

import * as S from "components/Cpu/Style2"

import { useSelector, useDispatch } from "react-redux"
import { setPopup } from "slices/MainSlice"
import {
    updateCpu,
    updateExtra,
    selectCpu,
    selectCurrentInstruction,
    selectRmemPreview,
    selectWmemPreview,
    selectRmemAddress,
    selectWmemAddress,
} from "slices/CpuSlice"
import {
    runSim, //outdated; runs the whole sim without updating the store
    startSim,
    stepSim,

    bytesToInt,
    bytesToFloat,
    intToHex,

    aluMode,
    fpuMode,

    getHighlightIndex,
} from "components/Cpu/Sim"

import { getNestedKey } from "functions"

import { colorMapping } from "components/Cpu/constants"


export const Cpu2 = (props) => {
    const cpuState = useSelector(selectCpu)
    const rmemPreview = useSelector(selectRmemPreview)
    const wmemPreview = useSelector(selectWmemPreview)
    const rmemAddress = useSelector(selectRmemAddress)
    const wmemAddress = useSelector(selectWmemAddress)
    const currentInstruction = useSelector(selectCurrentInstruction)
    const dispatch = useDispatch()

    const displayValues = Object.keys(cpuState).reduce((acc, key) => {
        const bytes = cpuState[key]
        const int = bytesToInt(bytes)
        acc[key] = {
            int,
            float: bytesToFloat(bytes),
            hex: intToHex(int),
        }
        return acc
    }, {})

    const NormalBigRegister = ({
        name,
        sides,
        long,
        flip,
        float,
    }) => {
        const num = getNestedKey(displayValues, [name, float ? "float" : "int"]) || 0
        const dataBack = (float && num.toString().length > 10)
            ? num.toString().slice(0, 9).concat("...")
            : num

        const dataFront = getNestedKey(displayValues, [name, "hex"]) || "0x00000000"

        const secondary = getNestedKey(colorMapping, [name, "secondary"]) || "#EBFAFF"

        return (
            <S.Card
                onClick={() => openPopup(name)}
                sides={sides}
                r="8"
                primary={getNestedKey(colorMapping, [name, "primary"]) || "#00A7E1"}
                secondary={num === 0 ? `${secondary}70` : secondary}
                long={long}
            >
                <S.Name>
                    {name}
                </S.Name>
                <S.Counter
                    sides={sides}
                    r="6"
                    data-back={flip ? dataFront : dataBack}
                    data-front={flip ? dataBack : dataFront}
                >
                    <span>WWWWWWW</span>
                </S.Counter>
            </S.Card>
        )
    }

    const getMemSpans = (bytes, address) => bytes.split(" ").map((b, i) => {
        const index = getHighlightIndex(address)
        return (
            <S.MemByte
                bold={i >= index && i < index + 4}
                strike={b == "00"}
                color={b == "00" ? "grey" : "white"}
            >
                {b == "00" ? "0" : b}
            </S.MemByte>
        )
    }
)

    const getInstructionReadout = () => (
        <S.Card
            sides="0b0110"
            r="8"
            primary="black"
            secondary="#EBFAFF"
        >
            <S.Spread>
                <S.Name color="black">From</S.Name>
                <S.Counter
                    color={getNestedKey(colorMapping, [currentInstruction.fromLocName, "primary"]) || "grey"}
                    colorAlt={getNestedKey(colorMapping, [currentInstruction.fromLocName, "secondary"]) || "#EBFAFF"}
                    sides="0b0100"
                    r="6"
                    data-back={currentInstruction.fromLocHex}
                    data-front={currentInstruction.fromLocName}
                ><span>WWWW</span></S.Counter>
            </S.Spread>
            <S.Spread>
                <S.Counter
                    color={getNestedKey(colorMapping, [currentInstruction.toLocName, "primary"]) || "grey"}
                    colorAlt={getNestedKey(colorMapping, [currentInstruction.toLocName, "secondary"]) || "#EBFAFF"}
                    sides="0b0010"
                    r="6"
                    data-back={currentInstruction.toLocHex}
                    data-front={currentInstruction.toLocName}
                ><span>WWWW</span></S.Counter>
                <S.Name>To</S.Name>
            </S.Spread>
        </S.Card>
    )

    const getAlumComponent = () => {
        const alum = getNestedKey(displayValues, ["ALUM", "int"])
        switch (alum) {
            case aluMode.NoOp:
                return <S.Op sides="0b1111">NOOP</S.Op>
            case aluMode.Add:
                return <FiPlusCircle/>
            case aluMode.Multiply:
                return <FiXCircle/>
            case aluMode.Subtract:
                return <FiMinusCircle/>
            case aluMode.Divide:
                return <FiDivideCircle/>
            case aluMode.ShiftLeft:
                return <CgChevronDoubleLeftO/>
            case aluMode.ShiftRight:
                return <CgChevronDoubleRightO/>
            case aluMode.OR:
                return <S.Op sides="0b1111">OR</S.Op>
            case aluMode.AND:
                return <S.Op sides="0b1111">AND</S.Op>
            case aluMode.XOR:
                return <S.Op sides="0b1111">XOR</S.Op>
            case aluMode.NAND:
                return <S.Op sides="0b1111">NAND</S.Op>
            case aluMode.NOR:
                return <S.Op sides="0b1111">NOR</S.Op>
            default:
                return <S.Op sides="0b1111">ERR</S.Op>
        }
    }

    const getFpumComponent = () => {
        const fpum = getNestedKey(displayValues, ["FPUM", "int"])
        switch (fpum) {
            case fpuMode.NoOp:
                return <S.Op sides="0b1111">NOOP</S.Op>
            case fpuMode.Add:
                return <FiPlusCircle/>
            case fpuMode.Multiply:
                return <FiXCircle/>
            case fpuMode.Subtract:
                return <FiMinusCircle/>
            case fpuMode.Divide:
                return <FiDivideCircle/>
            default:
                return <S.Op sides="0b1111">ERR</S.Op>
        }
    }

    const sendUpdateActions = (simReturnObject) => {
        const {
            cpu,
            extra,
        } = simReturnObject
        dispatch(updateCpu(cpu))
        dispatch(updateExtra(extra))
    }

    const openPopup = (popup) => {
        dispatch(setPopup(popup))
    }

    // return (
    //     <React.Fragment>
    //         <div style={{padding: "200px", backgroundColor: "#EEEEEE"}}>
    //             {NormalBigRegister({ name: "GPA", sides: "0b1111"})}
    //         </div>
    //     </React.Fragment>
    // )

    return (
        <S.TestArea>
            <S.BigRow>
                <S.Col>
                    <S.BigRegCol>
                        <S.Col>
                    <S.Spread>
                        {NormalBigRegister({ name: "FLAG", sides: "0b1001", })}
                        {NormalBigRegister({ name: "PC", })}
                        {getInstructionReadout()}
                    </S.Spread>


                    <S.Spread>
                        {NormalBigRegister({ name: "SKIP", sides: "0b1001",})}
                        {NormalBigRegister({ name: "RTRN", })}
                        {NormalBigRegister({ name: "LINK", sides: "0b0110",})}
                    </S.Spread>

                    <S.Spread>
                        {NormalBigRegister({ name: "COMPA", sides: "0b1001",})}
                        {NormalBigRegister({ name: "COMPB", })}
                        {NormalBigRegister({ name: "COMPR", })}

                        <S.Card
                            sides="0b0110"
                            r="8"
                            primary="salmon"
                            secondary="#EBFAFF"
                        >
                            <S.Spread onClick={() => openPopup("IADN")}>
                                <S.Name color={colorMapping["IADN"].primary}>IADN</S.Name>
                                <S.Counter
                                    color={colorMapping["IADN"].primary}
                                    colorAlt={colorMapping["IADN"].secondary}
                                    sides="0b0100"
                                    r="6"
                                    data-back={getNestedKey(displayValues, ["IADN", "int"]) || 0}
                                    data-front={getNestedKey(displayValues, ["IADN", "hex"]) || "0x00000000"}
                                ><span>WWWWWWW</span></S.Counter>
                            </S.Spread>
                            <S.Spread onClick={() => openPopup("IADF")}>
                                <S.Name color={colorMapping["IADF"].primary}>IADF</S.Name>
                                <S.Counter
                                    color={colorMapping["IADF"].primary}
                                    colorAlt={colorMapping["IADF"].secondary}
                                    sides="0b0010"
                                    r="6"
                                    data-back={getNestedKey(displayValues, ["IADF", "int"]) || 0}
                                    data-front={getNestedKey(displayValues, ["IADF", "hex"]) || "0x00000000"}
                                ><span>WWWWWWW</span></S.Counter>
                            </S.Spread>
                        </S.Card>
                    </S.Spread>

                    <S.Spread>
                        <S.Stack>
                            {NormalBigRegister({ name: "GPA", sides:"0b1000", })}
                            {NormalBigRegister({ name: "GPE", sides:"0b0001", })}
                        </S.Stack>

                        <S.Stack>
                            {NormalBigRegister({ name: "GPB", })}
                            {NormalBigRegister({ name: "GPF", })}
                        </S.Stack>

                        <S.Stack>
                            {NormalBigRegister({ name: "GPC", })}
                            {NormalBigRegister({ name: "GPG", })}
                        </S.Stack>

                        <S.Stack>
                            {NormalBigRegister({ name: "GPD", sides: "0b0100", })}
                            {NormalBigRegister({ name: "GPH", sides: "0b0010", })}
                        </S.Stack>

                    </S.Spread>
                    </S.Col>
                    </S.BigRegCol>

                    <S.SmallRegCol>
                        <S.Spread>
                            {NormalBigRegister({ name: "FLAG", sides: "0b1001", })}
                            {NormalBigRegister({ name: "PC", })}
                            {getInstructionReadout()}
                        </S.Spread>

                        <S.Row>
                        <S.RegStack>
                            {NormalBigRegister({ name: "SKIP", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "RTRN", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "LINK", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "COMPA", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "COMPB", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "COMPR", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "IADN", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "IADF", sides: "0b1111", long: true})}
                        </S.RegStack>

                        <S.RegStack>
                            {NormalBigRegister({ name: "GPA", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "GPB", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "GPC", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "GPD", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "GPE", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "GPF", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "GPG", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "GPH", sides: "0b1111", long: true})}
                        </S.RegStack>
                        </S.Row>
                    </S.SmallRegCol>

                </S.Col>

                <S.Col>
                    <S.Spread>
                        {NormalBigRegister({ name: "RBASE", sides: "0b1001", })}
                        {NormalBigRegister({ name: "ROFST", })}
                        {NormalBigRegister({ name: "RMEM", sides: "0b0110", })}
                    </S.Spread>

                    <S.Mem sides="0b1111">{getMemSpans(rmemPreview, rmemAddress)}</S.Mem>

                    <S.Spread>
                        {NormalBigRegister({ name: "WBASE", sides: "0b1001", })}
                        {NormalBigRegister({ name: "WOFST", })}
                        {NormalBigRegister({ name: "WMEM", sides: "0b0110", })}
                    </S.Spread>

                    <S.Mem sides="0b1111">{getMemSpans(wmemPreview, wmemAddress)}</S.Mem>
                </S.Col>

                <S.Math>
                    <S.Stack>
                        {NormalBigRegister({ name: "ALUA", sides: "0b1111", long: true, flip: true, })}
                        {NormalBigRegister({ name: "ALUB", sides: "0b1111", long: true, flip: true, })}

                        <S.Stack>
                            <S.Card
                                sides="0b1111"
                                primary={colorMapping["ALUM"].primary}
                                secondary={colorMapping["ALUM"].secondary}
                                onClick={() => openPopup("ALUM")}
                            >
                                <S.Spread>
                                    <S.Stack>
                                        <S.Name>ALUM</S.Name>
                                        <S.Counter
                                            sides="0b1111"
                                            r="6"
                                            data-back={getNestedKey(displayValues, ["ALUM", "int"]) || 0}
                                            data-front={getNestedKey(displayValues, ["ALUM", "hex"]) || "0x00000000"}
                                        />
                                    </S.Stack>
                                    <S.Name>
                                        {/* <div style={{minWidth: "75px"}}> */}
                                        {getAlumComponent()}
                                        <S.Op sides="0b1111"></S.Op>
                                        {/* </div> */}
                                    </S.Name>
                                </S.Spread>
                            </S.Card>
                        </S.Stack>

                        {NormalBigRegister({ name: "ALUR", sides: "0b1111", long: true, flip: true, })}
                    {/* </S.Stack>

                    <S.Stack> */}
                        {NormalBigRegister({ name: "FPUA", sides: "0b1111", long: true, flip: true, float: true, })}
                        {NormalBigRegister({ name: "FPUB", sides: "0b1111", long: true, flip: true, float: true, })}

                        <S.Stack>
                            <S.Card
                                // long
                                sides="0b1111"
                                primary={colorMapping["FPUM"].primary}
                                secondary={colorMapping["FPUM"].secondary}
                                onClick={() => openPopup("FPUM")}
                            >
                                <S.Spread>
                                    <S.Stack>
                                        <S.Name>FPUM</S.Name>
                                        <S.Counter
                                            sides="0b1111"
                                            r="6"
                                            data-back={getNestedKey(displayValues, ["FPUM", "int"]) || 0}
                                            data-front={getNestedKey(displayValues, ["FPUM", "hex"]) || "0x00000000"}
                                        />
                                    </S.Stack>
                                    <S.Name>
                                        <div style={{minWidth: "75px"}}>

                                        {getFpumComponent()}
                                        <S.Op sides="0b1111"></S.Op>
                                        </div>
                                    </S.Name>
                                </S.Spread>
                            </S.Card>
                        </S.Stack>

                        {NormalBigRegister({ name: "FPUR", sides: "0b1111", long: true, flip: true, float: true, })}
                    </S.Stack>
                </S.Math>


            </S.BigRow>





































            <S.SmallRow>
                <S.Col>

                    <S.SmallRegCol>
                        <S.Spread>
                            {NormalBigRegister({ name: "FLAG", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "PC", sides: "0b1111", long: true})}
                        </S.Spread>

                        <S.Row>
                        <S.RegStack>
                            {NormalBigRegister({ name: "SKIP", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "RTRN", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "LINK", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "COMPA", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "COMPB", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "COMPR", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "IADN", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "IADF", sides: "0b1111", long: true})}
                        </S.RegStack>

                        <S.RegStack>
                            {NormalBigRegister({ name: "GPA", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "GPB", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "GPC", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "GPD", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "GPE", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "GPF", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "GPG", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "GPH", sides: "0b1111", long: true})}
                        </S.RegStack>

                        {/* <S.RegStack>
                            {NormalBigRegister({ name: "RBASE", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "ROFST", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "RMEM", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "WBASE", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "WOFST", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "WMEM", sides: "0b1111", long: true})}
                        </S.RegStack> */}

                        <S.RegStack>
                            {NormalBigRegister({ name: "ALUA", sides: "0b1111", long: true, flip: true})}
                            {NormalBigRegister({ name: "ALUB", sides: "0b1111", long: true, flip: true})}
                            {NormalBigRegister({ name: "ALUM", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "ALUR", sides: "0b1111", long: true, flip: true})}
                            {NormalBigRegister({ name: "FPUA", sides: "0b1111", long: true, flip: true, float: true})}
                            {NormalBigRegister({ name: "FPUB", sides: "0b1111", long: true, flip: true, float: true})}
                            {NormalBigRegister({ name: "FPUM", sides: "0b1111", long: true})}
                            {NormalBigRegister({ name: "FPUR", sides: "0b1111", long: true, flip: true, float: true})}
                        </S.RegStack>
                        </S.Row>
                    </S.SmallRegCol>
                    <S.Spread style={{width: "100%"}}>
                        <S.Row style={{width: "100%", flexFlow: "nowrap"}}>
                            <S.RegStack style={{marginTop: "unset"}}>
                                {NormalBigRegister({ name: "RBASE", sides: "0b1111", long: true, })}
                                {NormalBigRegister({ name: "ROFST", sides: "0b1111", long: true, })}
                                {NormalBigRegister({ name: "RMEM", sides: "0b1111", long: true, })}
                            </S.RegStack>
                            <S.SmallMem sides="0b1111">{getMemSpans(rmemPreview, rmemAddress)}</S.SmallMem>
                        </S.Row>
                        <S.Row style={{width: "100%", flexFlow: "nowrap"}}>
                            <S.RegStack style={{marginTop: "unset"}}>
                                {NormalBigRegister({ name: "WBASE", sides: "0b1111", long: true})}
                                {NormalBigRegister({ name: "WOFST", sides: "0b1111", long: true})}
                                {NormalBigRegister({ name: "WMEM", sides: "0b1111", long: true})}
                            </S.RegStack>
                            <S.SmallMem sides="0b1111">{getMemSpans(wmemPreview, wmemAddress)}</S.SmallMem>
                        </S.Row>
                    </S.Spread>

                </S.Col>

            </S.SmallRow>
        </S.TestArea>
    )
}

export default Cpu2
