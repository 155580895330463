import styled, { css } from "styled-components"

import { FGColor } from "utility/StyleUtility"

export const Outer = styled.div`
    padding: 20px;
`

export const P1 = styled.p`
    text-indent: 10px;
`

export const Header = styled.h1`
    margin: 0px;
    text-align: center;
    font-size: 3em;
`

export const Subtitle = styled.h1`
    margin: 0px;
    text-align: center;
`

export const Image = styled.img`
    // width: 100%;
    width: 8vh;
    float: right;
    padding-left: 10px;
`

export const Image2 = styled.img`
    // width: 100%;
    width: 8vh;
    float: left;
    padding-right: 10px;
`

export const Image3 = styled.img`
    width: 14vh;
    float: right;
    padding-right: 10px;
`

export const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;

`

export const InstructionElement = styled.div`
    padding: 10px;
    color: white;
    border: thick dashed ${({color}) => color || "#D11026"};
    font-size: 24px;
    align-self: center;
    text-align: center;

`

export const Gap = styled.div`
    width: 100%;
    height: 24px;
`

export const InstructionLabel = styled.span`
    padding: 10px;
    align-self: center;
    font-size: 24px;

    ${FGColor}
`

export const CSpan = styled.span`
    // padding: 2px;
    ${FGColor}
    ${({bgcolor}) => bgcolor ? `background-color: ${bgcolor};` : ""}
    ${({pad}) => pad ? `padding: 2px;` : ""}
    font-weight: 600;
`

export const InstructionWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
`

export const LocationColumns = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 12px;
`

export const ListItemHolder = styled.div`
    display: flex;
    place-content: center;
    justify-self: flex-start;

    ${CSpan} {
        background-color: unset;
    }
`

export const InfoSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
`

export const InstGrid = styled.div`
    margin-left: 60px;
    width: 25%;
    display: grid;
    grid-template-columns: 1fr 1fr;
`

export const T = styled.h2`
    ${CSpan} {
        text-transform: capitalize;
    }
`

export const Table = styled.table`
    width: 100%;
    border: thin solid black;
    padding: 12px;
`

export const TableHeader = styled.th`
    text-align: left;
`

export const ButtonContainer = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
`

export const Button = styled.div`
    font-weight: 600;
    background-color: white;
    color: #272727;
    border-radius: 6px;
    padding: 8px;
    text-align: center;
    font-size: 12px;

    align-self: flex-end;
    user-select: none;

    border: medium solid transparent;

    transition: 0.25s;
    &:hover {
        background-color: #272727;
        color: white;
        border: medium solid white;
        transition: 0.25s;
        transform: translate(0px, -2px);
    }

    &:active {
        transform: translate(0px, 0px);
        background-color: grey;
        transition: 0.25s;
    }
`
