import React, { useEffect, useRef } from "react"

import * as S from "components/CanvasPage/Style"

import GameCanvas from "components/GameCanvas/GameCanvas"

var lastNumSquares = 0
var frameCount = 0
var lastMouse = null
var lastTime = 0
var lerpedMouse = null

const vec = (x=0, y=0, z=0) => ({
    x, y, z,
    magnitude: Math.sqrt((x * x) + (y * y) + (z * z)),
    sub: (other) => vec(x - other.x, y - other.y, z - other.z),
    add: (other) => vec(x + other.x, y + other.y, z + other.z),
    scale: (length) => vec(x * length, y * length, z * length),
    normalize: () => {
        const len = Math.sqrt((x * x) + (y * y) + (z * z))
        return vec(x / len, y / len, z / len)
    },
})

const lerp = (vec1, vec2, t) => {
    const difference = vec2.sub(vec1)
    const addition = difference.scale(t)
    return vec1.add(addition)
}

const drawVector = ({ctx, color, origin, vector, capped}) => {
    ctx.strokeStyle = color
    ctx.beginPath()
    ctx.moveTo(origin.x, origin.y)
    ctx.lineTo(origin.x + vector.x, origin.y + vector.y)

    // draw arrow head
    if (capped) {
        const head = vec(origin.x + vector.x, origin.y + vector.y)
        const perp = vec(vector.y, -1 * vector.x).scale(0.25)
        const tip = head.add(vector.scale(0.5))

        ctx.lineTo(origin.x + vector.x + perp.x, origin.y + vector.y + perp.y)
        ctx.lineTo(tip.x, tip.y)
        ctx.lineTo(origin.x + vector.x - perp.x, origin.y + vector.y - perp.y)
        ctx.lineTo(head.x, head.y)
    }
    ctx.stroke()
}

const draw = (gameState) => (time) => {
    const {
        canvasRef,
        numSquares,
        mouseX,
        mouseY,
    } = gameState.current

    if (!canvasRef.current)
    {
        return
    }

    if (numSquares != lastNumSquares)
    {
        lastNumSquares = numSquares
        console.log("[draw] num squares", numSquares)
    }

    const deltaTime = time - lastTime
    const ctx = canvasRef.current.getContext("2d")
    const maxX = ctx.canvas.width
    const maxY = ctx.canvas.height
    const centerX = maxX / 2
    const centerY = maxY / 2

    // clear the existing frame
    ctx.clearRect(0, 0, maxX, maxY)


    lerpedMouse = lerpedMouse ? lerp(lerpedMouse, vec(mouseX, mouseY), 0.35 / deltaTime) : vec(mouseX, mouseY)
    if (!lastMouse) lastMouse = vec(mouseX, mouseY)

    if (frameCount % 100 == 0) {
        if (deltaTime > 10) {
            console.log("[DELTATIME] dT > 10!", deltaTime)
        }
    }


    ctx.fillStyle = "rgb(200, 0, 0, 0.5)"
    ctx.fillRect(0, 0, 50, 50)

    ctx.fillStyle = "rgb(200, 0, 0, 0.5)"
    ctx.fillRect(maxX - 50, 0, 50, 50)

    ctx.fillStyle = "rgb(200, 0, 0, 0.5)"
    ctx.fillRect(0, maxY - 50, 50, 50)

    ctx.fillStyle = "rgb(200, 0, 0, 0.5)"
    ctx.fillRect(maxX - 50, maxY - 50, 50, 50)

    // draw vector field
    const [numX, numY] = [numSquares, numSquares]
    const vectorColor = "rgb(50, 220, 100, 1.0)"
    for (var y = 0; y < numY; y++)
    {
        for (var x = 0; x < numX; x++)
        {
            // calculate center of the grid square for this vector
            const origin = vec((x + 0.5) * maxX / numX, (y + 0.5) * maxY / numY)

            // point the vector at the mouse
            const vector = vec(lerpedMouse.x, lerpedMouse.y).sub(origin).normalize().scale(maxX / numSquares / 3)

            // move the vector backwards along itself halfway, so the center of rotation
            //  is at the origin (otherwise center of rotation is at origin + vector / 2)
            const adjustedOrigin = origin.sub(vector.scale(0.5))
            drawVector({ ctx, color: vectorColor, origin: adjustedOrigin, vector, capped: true })
        }
    }

    // request this draw function to be called again at the next frame opportunity
    const animationRequestId = requestAnimationFrame(draw(gameState))

    // store the latest animation request to game state so the component's
    // useEffect cleanup can cancel it if the component gets unmounted
    gameState.current.animationRequestId = animationRequestId

    frameCount += 1
    lastTime = time
    lastMouse = vec(mouseX, mouseY)
}

const CanvasPage = (props) => {
    const canvasRef = useRef(null)
    const gameState = useRef({
        canvasRef,
        numSquares: 1,
        animationRequestId: null,
    })

    // add mousemove listener
    useEffect(() => {
        if (canvasRef.current)
        {
            // store the new mouse position in the game state
            const moveHandler = (e) => {
                gameState.current.mouseX = e.offsetX
                gameState.current.mouseY = e.offsetY
            }

            const moveListener = canvasRef.current.addEventListener("mousemove", moveHandler)
            return () => canvasRef.current && canvasRef.current.removeEventListener("mousemove", moveHandler)
        }
    }, [])

    // add animation loop
    useEffect(() => {
        // request draw() to be called at the next opportunity to draw a frame
        const animationRequestId = requestAnimationFrame(draw(gameState))

        // store the request ID just in case we need to cancel the request
        gameState.current.animationRequestId = animationRequestId

        console.log("[useEffect] initial game state", gameState)

        // return a clean up function for when this component gets re-rendered
        return () => {
            console.log("cancelling animation frame", gameState.current.animationRequestId)

            // when this component is re-mounted, we want to cancel any outstanding
            // animation requests, otherwise the existing chain of draw() calls will
            // continue to request further animation frames, and we will start another
            // chain here by requesting a new animation frame in this useEffect on re-mount
            // therefore, each frame we will be requesting another animation frame twice,
            // and draw() will run twice (or more) per frame
            cancelAnimationFrame(gameState.current.animationRequestId)
        }
    }, [])

    return (
        <S.GameContainer>
            <canvas
                width="600"
                height="600"
                ref={canvasRef}
            >
                Fallback content
            </canvas>
            <S.HUD
                x="250"
                y="350"
                onClick={() => gameState.current.numSquares = gameState.current.numSquares - 1}
            >-</S.HUD>
            <S.HUD
                x="300"
                y="350"
                onClick={() => gameState.current.numSquares = gameState.current.numSquares + 1}
            >+</S.HUD>
        </S.GameContainer>
    )
}

export default CanvasPage
