import React, { useEffect, useRef } from "react"
import {
    vec, lerp, drawVector
} from "components/GameCanvas/GameUtility"

const draw = (gameState, drawFunc) => (time) => {
    const {
        canvasRef,
        deltaTime,
        lastTime,
        mouseX,
        mouseY,
    } = gameState.current

    if (!canvasRef.current)
    {
        return
    }

    gameState.current.deltaTime = time - gameState.current.lastTime
    gameState.current.lastTime = time
    gameState.current.time = time

    const ctx = canvasRef.current.getContext("2d")

    gameState.current.ctx = ctx

    const maxX = ctx.canvas.width
    const maxY = ctx.canvas.height
    const centerX = maxX / 2
    const centerY = maxY / 2

    gameState.current.maxX = maxX
    gameState.current.maxY = maxY
    gameState.current.centerX = centerX
    gameState.current.centerY = centerY

    // clear the existing frame
    ctx.clearRect(0, 0, maxX, maxY)

    if (gameState.current.showBounds) {
        ctx.fillStyle = "rgb(200, 0, 0, 0.5)"
        ctx.fillRect(0, 0, 50, 50)

        ctx.fillStyle = "rgb(200, 0, 0, 0.5)"
        ctx.fillRect(maxX - 50, 0, 50, 50)

        ctx.fillStyle = "rgb(200, 0, 0, 0.5)"
        ctx.fillRect(0, maxY - 50, 50, 50)

        ctx.fillStyle = "rgb(200, 0, 0, 0.5)"
        ctx.fillRect(maxX - 50, maxY - 50, 50, 50)
    }

    drawFunc(gameState)

    // request this draw function to be called again at the next frame opportunity
    const animationRequestId = requestAnimationFrame(draw(gameState, drawFunc))

    // store the latest animation request to game state so the component's
    // useEffect cleanup can cancel it if the component gets unmounted
    gameState.current.animationRequestId = animationRequestId
}

const GameCanvas = ({
    drawFunc,
    initialGameState,
    showBounds,
    width=600,
    height=600,
}) => {
    const canvasRef = useRef(null)
    const gameState = useRef({
        ...initialGameState,
        canvasRef,
        animationRequestId: null,
        showBounds,
    })

    // add mousemove listener
    useEffect(() => {
        if (canvasRef.current)
        {
            // store the new mouse position in the game state
            const moveHandler = (e) => {
                gameState.current.mouseX = e.offsetX
                gameState.current.mouseY = e.offsetY
            }

            const moveListener = canvasRef.current.addEventListener("mousemove", moveHandler)
            return () => canvasRef.current && canvasRef.current.removeEventListener("mousemove", moveHandler)
        }
    }, [])

    // add animation loop
    useEffect(() => {
        // request draw() to be called at the next opportunity to draw a frame
        const animationRequestId = requestAnimationFrame(draw(gameState, drawFunc))

        // store the request ID just in case we need to cancel the request
        gameState.current.animationRequestId = animationRequestId

        console.log("[useEffect] initial game state", gameState)

        // return a clean up function for when this component gets re-rendered
        return () => {
            console.log("cancelling animation frame", gameState.current.animationRequestId)

            // when this component is re-mounted, we want to cancel any outstanding
            // animation requests, otherwise the existing chain of draw() calls will
            // continue to request further animation frames, and we will start another
            // chain here by requesting a new animation frame in this useEffect on re-mount
            // therefore, each frame we will be requesting another animation frame twice,
            // and draw() will run twice (or more) per frame
            cancelAnimationFrame(gameState.current.animationRequestId)
        }
    })

    if (!drawFunc) {
        console.error("drawFunc is required for GameCanvas")
        return <em>**[Error] drawFunc is required for GameCanvas**</em>
    }

    return (
        <canvas
            // width="600"
            width={width}
            // height="600"
            height={height}
            ref={canvasRef}
        >
            Fallback content
        </canvas>
    )
}

export default GameCanvas
