import { useEffect } from "react"

export const useClickOutside = (ref, callback) => {
    useEffect(() => {
        const wrappedCallback = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback(event)
            }
        }

        document.addEventListener("mousedown", wrappedCallback)

        return () => {
            document.removeEventListener("mousedown", wrappedCallback)
        }

    }, [ref])
}

export const useEscape = (ref, callback) => {
    useEffect(() => {
        const wrappedCallback = (event) => {
            if (ref.current && event.key === "Escape") {
                callback(event)
            }
        }

        document.addEventListener("keydown", wrappedCallback)

        return () => {
            document.removeEventListener("keydown", wrappedCallback)
        }

    }, [ref])
}
