import React from 'react';

import {
    BrowserRouter as Router,
    Route,
    Switch,
} from "react-router-dom"

import 'App.css';

//pages
import Home from "pages/home"
import Gp from "pages/gp"

import ArticleContainer from "components/Article/Article"
import MainContainer from "components/MainContainer2/MainContainer"

import Cpu from "components/Cpu/Cpu"
import Cpu2 from "components/Cpu/Cpu2"
import CpuContainer from "components/CpuContainer/CpuContainer"
import DocsPage from "components/DocsPage/DocsPage"
import AboutPage from "components/AboutPage/AboutPage"
import TwitchTour from "components/TwitchTour/twitchTour"
import ChordsPage from "components/ChordsPage/ChordsPage"
import CanvasPage from "components/CanvasPage/CanvasPage"
import RandomWalk from "components/Games/RandomwWalk"
import VectorField from "components/Games/VectorField"
import ForceGame from "components/Games/ForceGame"
import ValuePage from "components/ValuePage/ValuePage"
import GraphGame from "components/Games/Graph"
import ArticlePage from "components/ArticlePage/ArticlePage"

export function RoutedApp(props) {
    return (
        <Router>
            {/* <Route exact path="/" component={(props) => <Home {...props}/>}/>
            <Route exact path="/gp" component={(props) => <Gp {...props}/>}/>
            <Route exact path="/cpu" component={(props) =>
                <MainContainer>
                    <CpuContainer {...props}/>
                </MainContainer>}
            />
            <Route path="/article">
                <MainContainer>
                    <Route exact path="/article/:id">{(props) => <ArticleContainer {...props}/>}</Route>
                </MainContainer>
            </Route> */}

            <Switch>
                <Route exact path="/twitch" component={(props) =>
                    <TwitchTour {...props}/>}
                />
                <Route path="/" component={(props) =>
                    <MainContainer {...props}>
                        <Route exact path={["/", "/cpu"]} component={(props) =>
                            <CpuContainer {...props}/>}
                        />
                        {/* <Route path="/articles" component={(props) =>
                            <ArticlePage {...props}/>}
                        /> */}
                        <Route exact path="/docs" component={(props) =>
                            <DocsPage {...props}/>}
                        />
                        <Route exact path="/about" component={(props) =>
                            <AboutPage {...props}/>}
                        />
                        <Route exact path="/chords" component={(props) =>
                            <ChordsPage {...props}/>}
                        />
                        <Route exact path="/canvas" component={(props) =>
                            <CanvasPage {...props}/>}
                        />
                        <Route exact path="/vectors" component={(props) =>
                            <VectorField {...props}/>}
                        />
                        <Route exact path="/forces" component={(props) =>
                            <ForceGame {...props}/>}
                        />
                        {/* <Route exact path="/value" component={(props) =>
                            <ValuePage {...props}/>}
                        /> */}
                        <Route exact path="/graph" component={(props) =>
                            <GraphGame {...props}/>}
                        />
                    </MainContainer>
                }/>
            </Switch>
        </Router>
    )
}

export default RoutedApp
