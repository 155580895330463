import * as S from "components/Cpu/PopupStyle"

import { Button } from "components/Controls/Controls"

import { colorMapping as colorMap } from "components/Cpu/constants"

const colorMapping = {
    ...colorMap,
    GPH: {
        primary: "white",
        secondary: colorMap.GPH.secondary,
    }
}

const SOURCE_COLOR = "#EF233C"
const DEST_COLOR = "#00A7E1"
const SOURCE = <S.CSpan color={SOURCE_COLOR}>source</S.CSpan>
const DEST = <S.CSpan color={DEST_COLOR}>destination</S.CSpan>

const UpdateEditor = (props, code) => (
    <S.Button onClick={
        () => props.updateEditor(code)}
    >
        Copy to editor
    </S.Button>
)

export const Register = (name) => {
    const key = name.toUpperCase()
    return (
        <S.CSpan pad color={colorMapping[key].primary} bgxcolor={colorMapping[key].secondary}>{key}</S.CSpan>
    )
}

const allLocations = Object.keys(colorMapping).map((k) => (
    k !== "IMM" && <S.ListItemHolder>{Register(k)}</S.ListItemHolder>
))

export const Help = () => (
    <S.Outer>
        <S.Header>Controls</S.Header>
        <p>
            <S.Image src="load_and_go.png"/>
            Use the "Load & Go" button to restart the simulation.
            It will reset the state of the CPU and memory, and then
            load the program from the editor into memory.
            Changes in your program will not be reflected in the simulation
            until you press "Load & Go".
            <br/><br/>
            After that, just press "Step" to execute the first instruction!
        </p>
        <hr/>
        <p>
            <S.Image2 src="step.png"/>
            Press the "Step" button to advance the simulation by one step.
            This will execute the next instruction - the one at the address held in the "PC" register.
            <br/><br/>
            Continue pressing the button to advance the program!
        </p>
        <hr/>
        <p>
            <S.Image src="example.png"/>
            Pressing the "Example" button will load an example program into the editor.
            This will not change the state of the CPU or memory, only replace the contents of the editor.
            <br/><br/>
            To run the example program, first click the "Example" button, then click the "Load & Go" button.
        </p>
        <hr/>

        <h3>Quick Info</h3>
        <p>
            All registers hold 32 bits. Main memory in this version of the simulator is 65536 bytes (64K) long.
            Memory is byte-addressable, and both memory and all registers are big-endian.
        </p>
        <hr/>

        <S.Gap/>
        <S.Header>Instructions</S.Header>
        <p>
            Every instruction consists of two bytes. The first byte specifies a {SOURCE} location,
            and the second byte specifies a {DEST} location.
        </p>
        <S.Gap/>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>0x00</S.InstructionElement>
                <S.InstructionLabel color={SOURCE_COLOR}>Source</S.InstructionLabel>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>0x00</S.InstructionElement>
                <S.InstructionLabel color={DEST_COLOR}>Destination</S.InstructionLabel>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            When an instruction executes, it moves the contents of the {SOURCE} to the {DEST}.

            <br/><br/>
            For a simple instruction, this might mean copying the data from one register into another.
            Here is an example:
            <br/><br/>
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("PC")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("GPC")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            <br/>
            Above, {Register("PC")} is the {SOURCE} and {Register("GPC")} is the {DEST}.
            In byte code, each of these locations are represented by their particular corresponding eight-bit number.
            In the documentation and code editor, however, the byte will be shown as the location's short name,
            rather than displaying the literal byte.

            <br/><br/>
            This instruction copies the 32-bit number in the program counter register ({Register("PC")})
            to a general purpose register ({Register("GPC")}). All instructions operate on 32 bits, or four bytes, of data.

            <br/><br/>
            Not all locations that appear in instructions are registers on the processor.
            Some locations have the effect of reading from or writing to memory, others always hold special values,
            and others are the result of mathematical operations.

            <br/><br/>
            In the simulator, click on a location's box to learn exactly what it does and how to use it. The all-capital
            short name that appears in each box is how it may be referred to in code.
            <br/><br/>
        </p>

        <hr/>

        <h3>Numbers</h3>
        <p>
            Besides locations, instructions may also contain numbers. Any number less than 128 in an instruction will be interpreted
            as that number literally.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    <S.CSpan>3</S.CSpan>
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("ALUM")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            In the above example, the number 3 is loaded into the ALU mode register ({Register("ALUM")}).
            Numbers in instructions may be written in decimal form, as in the example, or in hexadecimal form (0x03).

            <br/><br/>
            Numerical constants may only be used as the {SOURCE} in an instruction; an instruction with a
            number less than 128 as the {DEST} will have no effect when executed (no-op).

            <br/><br/>
            Numbers between 128 (0x80) and 191 (0xBF) represent the named locations, and not numeric literals.
            For example, using 147 (0x93) as the {SOURCE} or {DEST} is equivalent to using the name {Register("GPC")},
            since that is {Register("GPC")}'s designated number.
            <br/><br/>
        </p>

        <hr/>

        <h3>Direct Memory Locations</h3>
        <p>
            Instructions can also use any one of the first 64 bytes of memory as either a {SOURCE} or {DEST} by
            using special "DMEM" locations. Using a {Register("dmem")} location as a {SOURCE} will read the target byte from
            memory, and using a {Register("dmem")} location as a {DEST} will write to that byte in memory.
        </p>

        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("GPC")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    <S.CSpan pad color={colorMapping.DMEM.primary}>DMEM0C</S.CSpan>
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>

        <p>
            The above example stores the value of a general purpose register ({Register("GPC")}) in memory,
            starting at byte 12. All instructions operate on four bytes of data, so bytes 12-15 in memory will be overwritten
            with the data from {Register("GPC")}.

            <br/><br/>
            As the example shows, {Register("dmem")} locations are formed by appending a hexadecimal number to "DMEM".
            The number specifies the starting address of the operation in memory.

            <br/><br/>
            (In machine code, the {Register("dmem")} locations correspond to the numbers 0xC0 - 0xFF).

            <br/><br/>
            {Register("dmem")} instructions were introduced specifically with interrupt handling in mind. Interrupts
            are not enabled in this online demo of the simulator, but the {Register("dmem")} instructions still work!

            <br/><br/>
            Read more about interrupts on the {Register("FLAG")} help popup.
        </p>

        <hr/>

        <h3>All Legal Locations</h3>
        <p>
            Any location in this list can be used in an instruction. Some locations have no effect when
            used as a {SOURCE}, some have no effect when used as a {DEST}, and many can be used as either.

            <br/><br/>
            Click the locations in the simulator (underneath this popup) to learn more about each location.
        </p>

        <S.LocationColumns>
            {allLocations}
        </S.LocationColumns>
    </S.Outer>
)

export const PC = (props) => (
    <S.Outer>
        <S.Header>{Register("pc")}</S.Header>
        <S.Subtitle>Program Counter</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={DEST_COLOR}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. 32-bit register.
                <br/>When used as a {SOURCE}, copies the register's data to the {DEST}.
            </p>
            <p>
                Valid {DEST}. 32-bit register.
                <br/>When used as a {DEST}, sets the program counter equal to the value from the {SOURCE}.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            The program counter ({Register("PC")}) is a 32-bit register on the processor, but it serves a special purpose.
            It is a control register that dictates the address of the next instruction that will be executed.
            <br/><br/>
            {Register("PC")} automatically increments itself by two each time an instruction is run.
            It increments by two because each instruction is two bytes long, which allows for executing consecutive instructions sequentially.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            When used as a {SOURCE}, {Register("PC")} is like any normal register. It's 32-bit value is used as the data which is written to the {DEST}.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("PC")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("GPB")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            In the example instruction above, the value held in {Register("PC")} will be copied to {Register("GPB")}.
            This stores the address of the next instruction in {Register("GPB")}. If we wanted to return to this part of the program again,
            we can run the reverse, [{Register("GPB")} {Register("PC")}], to set the {Register("PC")} back to this point.
            <br/><br/>
            Remember that {Register("PC")} is also automatically incremented after each instruction, even if it is used as the {SOURCE} or {DEST}.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            When used as a {DEST}, {Register("PC")} can be written to like most normal registers.
            Writing to {Register("PC")} will use the data from the {SOURCE} as its new value.
            This will also change the next instruction to be executed. The processor always executes the instruction at the address stored in {Register("PC")},
            so changing {Register("PC")} will change what happens next!

            <br/><br/>
            One important detail: the auto-incrementation of {Register("PC")} happens immediately <em><b>after</b></em> loading the current instruction, but <em><b>before</b></em> executing it.
            This means that, when writing a new value to {Register("PC")}, the new value is the address of the next instruction that will be executed;
            no need to account for the +2.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("ALUR")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("PC")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            This example instruction demonstrates loading the result of the ALU ({Register("ALUR")}) into {Register("PC")}.
            Since we can use the ALU to do arithmetic, this means we can do something like add 100 to the program counter, jumping 50
            instructions further into the program. A snippet that does just that:
        </p>
        <S.ButtonContainer>
            <S.InstGrid>
                <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUA")}</S.ListItemHolder>

                <S.ListItemHolder>100</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUB")}</S.ListItemHolder>

                <S.ListItemHolder>0x01</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUM")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>
            </S.InstGrid>
            {UpdateEditor(props, "PC ALUA\n100 ALUB\n0x01 ALUM\nALUR PC")}
        </S.ButtonContainer>
    </S.Outer>
)

export const GP = (name) => () => (
    <S.Outer>
        <S.Header>{Register(name)}</S.Header>
        <S.Subtitle>General Purpose Register "{name.at(name.length - 1)}"</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={DEST_COLOR}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. 32-bit register.
                <br/>When used as a {SOURCE}, copies the register's data to the {DEST}.
            </p>
            <p>
                Valid {DEST}. 32-bit register.
                <br/>When used as a {DEST}, sets the register's value to the data from {SOURCE}.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            {Register(name)} is a one of eight general purpose registers. The general purpose registers are 32-bit registers have no special behavior.
            The registers are free to be used by a program in any way.

            <br/><br/>
            <S.Image3 src="gpa.gif"/>
            All registers, including the general purpose registers, hold 32 bits (or four bytes) of data.
            The contents of a register are shown in the simulator, normally in hexadecimal form.
            Hover over the contents to view them in decimal form.

        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            When used as a {SOURCE}, the value in {Register(name)} will be stored in the {DEST}.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register(name)}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("COMPA")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            In this example, {Register(name)}'s contents are being moved to {Register("COMPA")} for comparison with the value in {Register("COMPB")}.
            The general purpose registers are generally good for storing data for use in the near future, much like a temporary variable.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            Any value can be written to {Register(name)} when it is used as a {DEST}. This is useful for storing the results of a math operation, memory read,
            or comparison, for example. Constants can also be loaded into a general purpose register by using a number less than 128 as the {SOURCE}.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    <S.CSpan>0x1A</S.CSpan>
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register(name)}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            This example demonstrates using a constant as a {SOURCE}, storing the value 26 (0x1A) in {Register(name)}.
            In the code editor, constants may be written in hexadecimal form or decimal form. The following are therefore equivalent:
        </p>
        <S.InstGrid>
            <S.ListItemHolder>0x1A</S.ListItemHolder>
            <S.ListItemHolder>{Register(name)}</S.ListItemHolder>

            <S.ListItemHolder>26</S.ListItemHolder>
            <S.ListItemHolder>{Register(name)}</S.ListItemHolder>
        </S.InstGrid>
    </S.Outer>
)

export const ALU = (name) => (props) => (
    <S.Outer>
        <S.Header>{Register(name)}</S.Header>
        <S.Subtitle>Arithmetic Logic Unit Input "{name.at(name.length - 1)}"</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={DEST_COLOR}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. 32-bit register.
                <br/>When used as a {SOURCE}, copies the register's data to the {DEST}. As a source, {Register(name)} works like a normal register.
            </p>
            <p>
                Valid {DEST}. 32-bit register.
                <br/>When used as a {DEST}, sets the register's value to the data from {SOURCE}.
                In the same cycle, {Register("ALUR")} will be updated with the result of combining {Register("ALUA")} and {Register("ALUB")} in the
                mode specified by {Register("ALUM")}.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            {Register(name)} is one of two input registers for the arithmetic logic unit (ALU). {Register("ALUA")} and {Register("ALUB")} work exactly the same way.

            <br/><br/>
            The arithmetic logic unit is used to perform mathematical operations on integers. It accepts two operands, the values in {Register("ALUA")} and {Register("ALUB")}, and an
            operator, specified by the value in {Register("ALUM")}.
            The result is available in {Register("ALUR")} starting in the next cycle.

            <br/><br/>
            In operations that are ordered, like subtraction, {Register("ALUA")} is always the first operand and {Register("ALUB")} is always the second.

            <br/><br/>
            The ALU is the best place to perform arbitrary operations with integers, but it is not the only way to do arithmetic on the processor.
            Some locations are specially designed to avoid needing to use the ALU for the most common operations.

            <br/><br/>
            <li>{Register("RBASE")} and {Register("ROFST")} used in combination can specify an address + offset</li>
            <li>{Register("SKIP")} and {Register("RTRN")} always hold special PC-relative addresses to make branching and jumping easier</li>
            <li>{Register("COMPA")} and {Register("COMPB")} can be used together to perform comparisons.</li>
            <li>Finally, the FPU can be used for operations with floating-point numbers.</li>
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            When used as a {SOURCE}, the value in {Register(name)} will be stored in the {DEST}. So, as a {SOURCE}, {Register(name)} is much like a normal register.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register(name)}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("GPB")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            This example demonstrates retrieving a value from {Register(name)} and storing it into a general purpose register.
            Since the value in {Register(name)} remains the same, {Register("ALUR")} also remains unchanged.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            Using {Register(name)} as a {DEST} will copy the {SOURCE} data to {Register(name)} and cause an update to {Register("ALUR")} in the same cycle. This is normally done to set up the ALU for a math operation.
            Make sure you have saved the value of {Register("ALUR")} to another register first if you need it, though, since {Register("ALUR")} will be updated and its previous value will be lost.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    <S.CSpan>3</S.CSpan>
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register(name)}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            Here, the number three is being loaded into one of the ALU's input registers, which in turn causes an update to {Register("ALUR")}.
            <br/><br/>

            To better explain how {Register("ALUR")} updates when {Register("ALUA")} or {Register("ALUB")} is the {DEST}, we will look at some code snippets.
            We begin by setting the ALU mode to addition and loading numbers into the ALU input registers.
        </p>
        <h5>
            (The different ALU modes are explained in the {Register("ALUM")} popup.)
        </h5>
        <S.ButtonContainer>
            <S.InstGrid>
                <S.ListItemHolder>1</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUM")}</S.ListItemHolder>

                <S.ListItemHolder>15</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUA")}</S.ListItemHolder>

                <S.ListItemHolder>11</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUB")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPA")}</S.ListItemHolder>
            </S.InstGrid>
            {UpdateEditor(props, `1 ALUM\n15 ALUA\n11 ALUB\nALUR GPA\n39 ALUA\nALUR GPB`)}
        </S.ButtonContainer>
        <p>
            <br/>
            In the first instruction, the mode is set to addition. In the second instruction, {Register("ALUA")} is set to 15, and then {Register("ALUB")} is set to 11 in the third instruction.
            <br/><br/>
            After the third instruction, [11 {Register("ALUB")}], {Register("ALUR")} will be updated to hold the result of the ALU's operation.
            The ALU is in add mode, and the inputs are 15 and 11, so the ALU performs 11 + 15 and stores the result (26) in {Register("ALUR")} automatically.

            <br/><br/>
            {Register("ALUR")} now holds 26, and in the last instruction, {Register("ALUR")}'s value is saved into {Register("GPA")}. Therefore, {Register("GPA")} now also holds the value 26.

            <br/><br/>
            Now we add a fifth instruction, changing one of the input registers, and a sixth again copying the result out of {Register("ALUR")} into another register.
        </p>
        <S.InstGrid>
            <S.ListItemHolder>39</S.ListItemHolder>
            <S.ListItemHolder>{Register("ALUA")}</S.ListItemHolder>

            <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
            <S.ListItemHolder>{Register("GPB")}</S.ListItemHolder>
        </S.InstGrid>
        <p>
            {Register("ALUA")} has been changed from 15 to 39; consequently, {Register("ALUR")} is updated to reflect the result of the new operation: 39 + 11 = 50.
            Neither {Register("ALUB")} nor {Register("ALUM")} changed, so the mode and second input value are the same as before.
            <br/><br/>
            In the final instruction, [{Register("ALUR")} {Register("GPB")}], we copy the result to {Register("GPB")}, so {Register("GPB")} now also holds 50.
            <br/>({Register("GPA")} still holds 26, since we have not changed its value!)

            <br/><br/>
        </p>
        <hr/>
        <p>
            <b>Note</b>: {Register("ALUR")} will not be updated if the ALU is in "no-op" mode, even if {Register("ALUA")} or {Register("ALUB")} is the {DEST}.
        </p>
    </S.Outer>
)

export const ALUM = (props) => (
    <S.Outer>
        <S.Header>{Register("ALUM")}</S.Header>
        <S.Subtitle>Arithmetic Logic Unit Mode</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={DEST_COLOR}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. 32-bit register.
                <br/>When used as a {SOURCE}, copies the register's data to the {DEST}. As a source, {Register("ALUM")} works like a normal register.
            </p>
            <p>
                Valid {DEST}. 32-bit register.
                <br/>When used as a {DEST}, sets the register's value to the data from {SOURCE}, which also changes the ALU's mode.
                In the same cycle, {Register("ALUR")} will be updated with the result of combining {Register("ALUA")} and {Register("ALUB")} in the new mode.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            {Register("ALUM")} is the operator input register for the arithmetic logic unit (ALU).

            <br/><br/>
            The arithmetic logic unit is used to perform mathematical operations. It accepts two operands, the values in {Register("ALUA")} and {Register("ALUB")}, and an
            operator, specified by the value in {Register("ALUM")}.
            The result is available in {Register("ALUR")} starting in the next cycle.

            <br/><br/>
            <S.Image3 src="alu_modes.gif"/>
            The ALU is used to perform integer arithmetic or bitwise logic operations. The operation performed by the ALU is determined by its mode,
            which is the value held in {Register("ALUM")}. To change the mode of the ALU, store a new value in {Register("ALUM")}.

            <br/><br/>
            The ALU has 12 modes:

        </p>
        <S.Table>
            <tbody>
                <tr>

                    <S.TableHeader>{Register("ALUM")}</S.TableHeader>
                    <S.TableHeader>Mode</S.TableHeader>
                    <S.TableHeader>Description</S.TableHeader>
                </tr>
                <tr>
                    <td>0x00</td>
                    <td>No Op</td>
                    <td>No operation; {Register("ALUR")} will not be updated</td>
                </tr>
                <tr>
                    <td>0x01</td>
                    <td>Add</td>
                    <td>{Register("ALUA")} + {Register("ALUB")}</td>
                </tr>
                <tr>
                    <td>0x02</td>
                    <td>Multiply</td>
                    <td>{Register("ALUA")} * {Register("ALUB")}</td>
                </tr>
                <tr>
                    <td>0x03</td>
                    <td>Subtract</td>
                    <td>{Register("ALUA")} - {Register("ALUB")}</td>
                </tr>
                <tr>
                    <td>0x04</td>
                    <td>Divide</td>
                    <td>{Register("ALUA")} ÷ {Register("ALUB")}; result truncated to an integer</td>
                </tr>
                <tr>
                    <td>0x05</td>
                    <td>Shift Left</td>
                    <td>{Register("ALUA")} {"<<"} {Register("ALUB")}; zero-fill bitwise left shift</td>
                </tr>
                <tr>
                    <td>0x06</td>
                    <td>Shift Right</td>
                    <td>{Register("ALUA")} {">>"} {Register("ALUB")}; zero-fill bitwise right shift</td>
                </tr>
                <tr>
                    <td>0x07</td>
                    <td>Or</td>
                    <td>{Register("ALUA")} | {Register("ALUB")}; bitwise OR</td>
                </tr>
                <tr>
                    <td>0x08</td>
                    <td>And</td>
                    <td>{Register("ALUA")} & {Register("ALUB")}; bitwise AND</td>
                </tr>
                <tr>
                    <td>0x09</td>
                    <td>Exclusive Or</td>
                    <td>{Register("ALUA")} ^ {Register("ALUB")}; bitwise XOR</td>
                </tr>
                <tr>
                    <td>0x0A</td>
                    <td>Not And</td>
                    <td>{Register("ALUA")} NAND {Register("ALUB")}; bitwise NAND</td>
                </tr>
                <tr>
                    <td>0x0B</td>
                    <td>Nor</td>
                    <td>{Register("ALUA")} NOR {Register("ALUB")}; bitwise NOR</td>
                </tr>
            </tbody>
        </S.Table>

        <p>
            All unlisted values for {Register("ALUM")} will set the mode to "no op".
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            The value in {Register("ALUM")} can be copied to another location by using it as a {SOURCE}.
            When used as a {SOURCE}, {Register("ALUM")} works like a normal register.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("ALUM")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("GPB")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            The above example demonstrates moving the value of {Register("ALUM")} into a general purpose register.

            <br/><br/>
            When {Register("ALUM")} is used as a {SOURCE}, {Register("ALUR")}'s value will not change.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            {Register("ALUM")} can be used as a {DEST} in order to set the mode of the ALU.
            The mode will be set according to the {SOURCE} data; the available modes are listed in the table above.

            <br/><br/>
            When {Register("ALUM")} is used as a {DEST}, a numeric constant is often used as the {SOURCE}, but it does not have to be.
            The {SOURCE} can be any other location, like a general purpose register.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    <S.CSpan>2</S.CSpan>
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("ALUM")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            Here, the number two is being loaded into ALU's mode register, setting the mode to "multiply".
            This will also cause {Register("ALUR")} to update, calculating the new result of {Register("ALUA")} * {Register("ALUB")}.
            <br/><br/>

            The example snippet below demonstrates the effect of writing to {Register("ALUM")}, and the subsequent automatic updates to {Register("ALUR")}.
        </p>
        <S.ButtonContainer>
            <S.InstGrid>
                <S.ListItemHolder>12</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUA")}</S.ListItemHolder>

                <S.ListItemHolder>8</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUB")}</S.ListItemHolder>

                <S.ListItemHolder>1</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUM")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPA")}</S.ListItemHolder>

                <S.ListItemHolder>2</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUM")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPB")}</S.ListItemHolder>
            </S.InstGrid>
            {UpdateEditor(props, `12 ALUA\n8 ALUB\n1 ALUM\nALUR GPA\n2 ALUM\nALUR GPB`)}
        </S.ButtonContainer>
        <p>
            The first two instructions set the input operands, and third sets the ALU mode to "add".
            The result is calculated (12 + 8 = 20) and stored in {Register("ALUR")}, before being copied to {Register("GPA")} by the fourth instruction.
            <br/><br/>
            The last two instructions change the ALU mode to "multiply" before saving {Register("ALUR")} to {Register("GPB")}.
            By the time the last instruction is executed, {Register("ALUR")} has been updated to hold the new result (12 * 8 = 96), so 96 is stored in {Register("GPB")}.

            <br/><br/>
            Note that with the fifth instruction, the operator changed, but the operands remain the same.

            <br/><br/>
            After all six instructions are run, {Register("GPA")} will hold 20 and {Register("GPB")} will hold 96.
        </p>
    </S.Outer>
)

export const ALUR = (props) => (
    <S.Outer>
        <S.Header>{Register("ALUR")}</S.Header>
        <S.Subtitle>Arithmetic Logic Unit Result</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={"grey"}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. 32-bit register.
                <br/>Read-only.
                <br/>When used as a {SOURCE}, copies the register's data to the {DEST}. Always holds the result of {Register("ALUA")} and {Register("ALUB")} combined with the operator specified by {Register("ALUM")}.
            </p>
            <p>
                Not a valid {DEST}.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            {Register("ALUR")} is the result register for the arithmetic logic unit (ALU), and always holds the result of the current operation.
            The ALU's result is a function of its two input registers ({Register("ALUA")} and {Register("ALUB")}) and its mode register ({Register("ALUM")}).

            <br/><br/>
            The {Register("ALUR")} register is read-only; using it as a {DEST} will always have no effect.

            <br/><br/>
            {Register("ALUR")} is updated on any cycle after {Register("ALUA")}, {Register("ALUB")}, or {Register("ALUM")} is written to.
            The result of the operation is always available on the next cycle after any of those registers is modified.

            <br/><br/>
            The ALU has 12 modes, which are specified on the {Register("ALUM")} popup.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            {Register("ALUR")} may be used as a {SOURCE} to retrieve the result of the ALU's last operation. {Register("ALUR")} is always automatically updated after every cycle in which one of the ALU's input or mode registers were modified.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("ALUR")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("PC")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            In this example, the result of the ALU's last operation, retrieved from {Register("ALUR")}, is being stored in {Register("PC")}.
            The address of the next instruction to be executed therefore becomes the result of that operation - the normal effect of writing to {Register("PC")}.

            <br/><br/>
            One way to use {Register("ALUR")} as a {SOURCE} is to move it back into one of the operand registers, chaining the result of the calculation into the next.
            This is demonstrated in the snippet below.
        </p>
        <S.ButtonContainer>
            <S.InstGrid>
                <S.ListItemHolder>1</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUM")}</S.ListItemHolder>

                <S.ListItemHolder>8</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUA")}</S.ListItemHolder>

                <S.ListItemHolder>1</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUB")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUA")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUA")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPC")}</S.ListItemHolder>
            </S.InstGrid>
            {UpdateEditor(props, "1 ALUM\n8 ALUA\n1 ALUB\nALUR ALUA\nALUR ALUA\nALUR GPC")}
        </S.ButtonContainer>
        <p>
            In the code above, the ALU is first set to "add" mode, and its operands are set. The result (8 + 1 = 9) is then moved directly back into {Register("ALUA")}.

            <br/><br/>
            9 and 1 are now the operands, updating the result (9 + 1 = 10) in {Register("ALUR")}.

            <br/><br/>
            This instruction [{Register("ALUR")} {Register("ALUA")}] occurs again, performing one final addition (10 + 1 = 11). {Register("ALUR")} is finally copied into {Register("GPC")}.

            <br/><br/>
            After executing the above snippet, {Register("GPC")} will hold 11.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            {Register("ALUR")} is read-only, and therefore may not be used as a {DEST}.
            <br/><br/>
            Any instruction that uses {Register("ALUR")} as the {DEST} will have no effect.
        </p>
    </S.Outer>
)

export const FPU = (name) => (props) => (
    <S.Outer>
        <S.Header>{Register(name)}</S.Header>
        <S.Subtitle>Floating Point Unit Input "{name.at(name.length - 1)}"</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={DEST_COLOR}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. 32-bit register.
                <br/>When used as a {SOURCE}, copies the register's data to the {DEST}. As a source, {Register(name)} works like a normal register.
            </p>
            <p>
                Valid {DEST}. 32-bit register.
                <br/>When used as a {DEST}, sets the register's value to the data from {SOURCE}.
                In the same cycle, {Register("FPUR")} will be updated with the result of combining {Register("FPUA")} and {Register("FPUB")} in the
                mode specified by {Register("FPUM")}.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            {Register(name)} is one of two input registers for the floating point unit (FPU). {Register("FPUA")} and {Register("FPUB")} work exactly the same way.

            <br/><br/>
            The floating point unit is used to perform mathematical operations on floating-point numbers. It accepts two operands, the values in {Register("FPUA")} and {Register("FPUB")}, and an
            operator, specified by the value in {Register("FPUM")}. The values of the operands ({Register("FPUA")} and {Register("FPUB")}) are interpreted as floating-point numbers in IEEE-754 format.
            The result is available in {Register("FPUR")} starting in the next cycle.

            <br/><br/>
            In operations that are ordered, like subtraction, {Register("FPUA")} is always the first operand and {Register("FPUB")} is always the second.

            <br/><br/>
            The FPU works in mostly the same way as the ALU, except it operates on floating-point numbers instead of integers and has a different set of modes available.

            <br/><br/>
            The FPU is the only way to perform floating-point arithmetic on the processor without implementing it in software.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            When used as a {SOURCE}, the value in {Register(name)} will be stored in the {DEST}. So, as a {SOURCE}, {Register(name)} is much like a normal register.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register(name)}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("GPB")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            This example demonstrates retrieving a value from {Register(name)} and storing it into a general purpose register.
            Since the value in {Register(name)} remains the same, {Register("FPUR")} also remains unchanged.

            <br/><br/>
            A value that has been placed in {Register(name)} is not "converted" to a floating-point number - the actual bits of the value remain unchanged.
            The value is merely interpreted differently when operated on by the FPU.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            Using {Register(name)} as a {DEST} will copy the {SOURCE} data to {Register(name)} and cause an update to {Register("FPUR")} in the same cycle. This is normally done to set up the FPU for a math operation.
            Make sure you have saved the value of {Register("FPUR")} to another register first if you need it, though, since {Register("FPUR")} will be updated and its previous value will be lost.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("IADF")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register(name)}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            This example shows one of the most common ways to populate the FPU input registers.
            Loading a constant in a single instruction is limited to loading numbers less than 128, which is problematic for loading floating-point constants. Even the useful number 1.0 takes a full four bytes (0x3F800000) to represent it as a floating-point number.

            <br/><br/>
            Since all instructions are only two bytes, how can we use four-byte constants in our code?
            The solution is to use a special location, {Register("IADF")}, which is purpose-built to access four bytes of instructions as data.

            <br/><br/>
            {Register("IADF")}, also called "Far Instructions-As-Data", allows loading four bytes of data from instructions in close proximity to {Register("PC")}.
            You can learn more about this special location and the semantics of the snippet below by reading the help popup for {Register("IADF")}.

            <br/><br/>
            In this example, we will encode our floating-point constant in four bytes of instructions (the length of two full instructions), and use {Register("IADF")} to move the constant into {Register(name)}.
        </p>
        <h5>
            (Read more about this sequence in the {Register("IADF")} and {Register("SKIP")} popups.)
        </h5>
        <S.ButtonContainer>
            <S.InstGrid>
                <S.ListItemHolder>{Register("IADF")}</S.ListItemHolder>
                <S.ListItemHolder>{Register(name)}</S.ListItemHolder>

                <S.ListItemHolder>{Register("SKIP")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>

                <S.ListItemHolder>0x3F</S.ListItemHolder>
                <S.ListItemHolder>0x80</S.ListItemHolder>

                <S.ListItemHolder>0x00</S.ListItemHolder>
                <S.ListItemHolder>0x00</S.ListItemHolder>

                <S.ListItemHolder>1</S.ListItemHolder>
                <S.ListItemHolder>{Register("FPUM")}</S.ListItemHolder>
            </S.InstGrid>
            {UpdateEditor(props, "IADF FPUA\nSKIP PC\n0x3F 0x80\n0x00 0x00\n1 FPUM")}
        </S.ButtonContainer>
        <p>
            Though the snippet above contains five instructions, only three are actually executed. The third and fourth instructions, [<b>0x41 0x20</b>] and [<b>0x00 0x00</b>],
            are actually just the encoding of our four-byte constant 0x3F800000 (1.0).

            <br/><br/>
            Those two instructions contain only data and are not meant to be executed; we skip them with the instruction [{Register("SKIP")} {Register("PC")}].

            <br/><br/>
            After skipping our data instructions, the final instruction that gets executed is [<b>1</b> {Register("FPUM")}], which sets the FPU mode to "add".


            <br/><br/>
            A recap of the snippet:
            <li>First, we load 0x3F800000 (which is 1.0) into {Register(name)} [{Register("IADF")} {Register(name)}]</li>
            <li>Next, we skip the two upcoming data instructions [{Register("SKIP")} {Register("PC")}]</li>
            <li>Finally, we set the FPU to "add" mode [<b>1</b> {Register("FPUM")}]</li>
        </p>

        <hr/>
        <S.Image3 src="fpu_precision.gif"/>
        <b>Note</b>: For convenience, the simulator shows the values of FPU registers in floating-point form by default.
        The values are displayed with limited precision, but calculations are always carried out with full precision.
        The exact contents of the registers can be seen in hexadecimal form by hovering over the value.
    </S.Outer>
)

export const FPUM = (props) => (
    <S.Outer>
        <S.Header>{Register("FPUM")}</S.Header>
        <S.Subtitle>Floating Point Unit Mode</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={DEST_COLOR}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. 32-bit register.
                <br/>When used as a {SOURCE}, copies the register's data to the {DEST}. As a source, {Register("FPUM")} works like a normal register.
            </p>
            <p>
                Valid {DEST}. 32-bit register.
                <br/>When used as a {DEST}, sets the register's value to the data from {SOURCE}, which also changes the FPU's mode.
                In the same cycle, {Register("FPUR")} will be updated with the result of combining {Register("FPUR")} and {Register("FPUR")} in the new mode.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            {Register("FPUM")} is the operator input register for the floating-point unit (FPU).

            <br/><br/>
            The floating point unit is used to perform mathematical operations on floating-point numbers.
            It accepts two operands, the values in {Register("FPUA")} and {Register("FPUB")}, and an
            operator, specified by the value in {Register("FPUM")}.
            The result is available in {Register("FPUR")} starting in the next cycle.

            <br/><br/>
            <S.Image3 src="fpu_modes.gif"/>
            The FPU is used to perform floating-point arithmetic. The operation performed by the FPU is determined by its mode,
            which is the value held in {Register("FPUM")}. To change the mode of the FPU, store a new value in {Register("FPUM")}.

            <br/><br/>
            {Register("FPUM")} works exactly like {Register("ALUM")}, except it has different legal modes.

            <br/><br/>
            The FPU has 5 modes:

        </p>
        <S.Table>
            <tbody>
                <tr>
                    <S.TableHeader>{Register("FPUM")}</S.TableHeader>
                    <S.TableHeader>Mode</S.TableHeader>
                    <S.TableHeader>Description</S.TableHeader>
                </tr>
                <tr>
                    <td>0x00</td>
                    <td>No Op</td>
                    <td>No operation; {Register("FPUR")} will not be updated</td>
                </tr>
                <tr>
                    <td>0x01</td>
                    <td>Add</td>
                    <td>{Register("FPUA")} + {Register("FPUB")}</td>
                </tr>
                <tr>
                    <td>0x02</td>
                    <td>Multiply</td>
                    <td>{Register("FPUA")} * {Register("FPUB")}</td>
                </tr>
                <tr>
                    <td>0x03</td>
                    <td>Subtract</td>
                    <td>{Register("FPUA")} - {Register("FPUB")}</td>
                </tr>
                <tr>
                    <td>0x04</td>
                    <td>Divide</td>
                    <td>{Register("FPUA")} ÷ {Register("FPUB")}</td>
                </tr>
            </tbody>
        </S.Table>
        <p>
            All unlisted values for {Register("FPUM")} will set the mode to "no op".
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            The value in {Register("FPUM")} can be copied to another location by using it as a {SOURCE}.
            When used as a {SOURCE}, {Register("FPUM")} works like a normal register.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("FPUM")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("GPG")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            The above example demonstrates moving the value of {Register("FPUM")} into a general purpose register.

            <br/><br/>
            When {Register("FPUM")} is used as a {SOURCE}, {Register("FPUR")}'s value will not change.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            {Register("FPUM")} can be used as a {DEST} in order to set the mode of the FPU.
            The mode will be set according to the {SOURCE} data.

            <br/><br/>
            Unlike the other FPU registers ({Register("FPUA")}, {Register("FPUB")}, and {Register("FPUR")}),
            the value in {Register("FPUM")} is always interpreted as an integer, setting the mode of the FPU according to the table above.

            <br/><br/>
            When {Register("FPUM")} is used as a {DEST}, a numeric constant is often used as the {SOURCE}, but it does not have to be.
            The {SOURCE} can be any other location, like a general purpose register.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    <S.CSpan>4</S.CSpan>
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("FPUM")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            Here, the number four is being loaded into FPU's mode register, setting the mode to "divide".
            This will also cause {Register("FPUR")} to update, calculating the new result of {Register("FPUA")} ÷ {Register("FPUB")}.
            <br/><br/>

            The example snippet below demonstrates the effect of writing to {Register("FPUM")}, and the subsequent automatic updates to {Register("FPUR")}.
        </p>
        <h5>
            (Read more about this sequence in the {Register("FPUA")} and {Register("IADF")} popups.)
        </h5>
        <S.ButtonContainer>
            <S.InstGrid>
                <S.ListItemHolder>{Register("IADF")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("FPUA")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("SKIP")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>

                <S.ListItemHolder>0x40</S.ListItemHolder>
                <S.ListItemHolder>0x40</S.ListItemHolder>

                <S.ListItemHolder>0x00</S.ListItemHolder>
                <S.ListItemHolder>0x00</S.ListItemHolder>

                <S.ListItemHolder>{Register("IADF")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("FPUB")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("SKIP")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>

                <S.ListItemHolder>0x40</S.ListItemHolder>
                <S.ListItemHolder>0x86</S.ListItemHolder>

                <S.ListItemHolder>0x66</S.ListItemHolder>
                <S.ListItemHolder>0x66</S.ListItemHolder>

                <S.ListItemHolder>2</S.ListItemHolder>
                <S.ListItemHolder>{Register("FPUM")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("FPUR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPA")}</S.ListItemHolder>

                <S.ListItemHolder>4</S.ListItemHolder>
                <S.ListItemHolder>{Register("FPUM")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("FPUR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPB")}</S.ListItemHolder>
            </S.InstGrid>
            {UpdateEditor(props, "IADF FPUA\nSKIP PC\n0x40 0x40\n0x00 0x00\nIADF FPUB\nSKIP PC\n0x40 0x86\n0x66 0x66\n2 FPUM\nFPUR GPA\n4 FPUM\nFPUR GPB")}
        </S.ButtonContainer>
        <p>
            The first eight lines load constants into {Register("FPUA")} and {Register("FPUB")}.
            The operands are the floating point numbers 3.0 and 4.2.

            <br/><br/>
            Next, the instruction [<b>2</b> {Register("FPUM")}] sets the FPU to "multiply" mode.
            The result, 0x41499999 (≈12.6), is then stored into a general purpose register with [{Register("FPUR")} {Register("GPA")}].

            <br/><br/>
            The last two instructions again change the mode and store the new result into a different register.
            [<b>4</b> {Register("FPUM")}] sets the FPU to "divide" mode, and [{Register("FPUR")} {Register("GPB")}] stores the result of (3.0 ÷ 4.2 ≈ 0.7143) in {Register("GPB")}.

            <br/><br/>
            After all six instructions are run, {Register("GPA")} will hold 0x41499999 and {Register("GPB")} will hold 0x3F36D86E, which are the IEEE-754 representations of the FPU's results.
        </p>
    </S.Outer>
)

export const FPUR = () => (
    <S.Outer>
        <S.Header>{Register("FPUR")}</S.Header>
        <S.Subtitle>Floating Point Unit Result</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={"grey"}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. 32-bit register.
                <br/>Read-only.
                <br/>When used as a {SOURCE}, copies the register's data to the {DEST}. Always holds the result of {Register("FPUA")} and {Register("FPUB")} combined with the operator specified by {Register("FPUM")}.
            </p>
            <p>
                Not a valid {DEST}.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            {Register("FPUR")} is the result register for the floating point unit (FPU), and always holds the result of the current operation.
            The FPU's result is a function of its two input registers ({Register("FPUA")} and {Register("FPUB")}) and its mode register ({Register("FPUM")}).

            <br/><br/>
            The {Register("FPUR")} register is read-only; using it as a {DEST} will always have no effect.

            <br/><br/>
            {Register("FPUR")} is updated on any cycle after {Register("FPUA")}, {Register("FPUB")}, or {Register("FPUM")} is written to.
            The result of the operation is always available on the next cycle after any of those registers is modified.

            <br/><br/>
            The FPU has 5 modes, which are specified on the {Register("FPUM")} popup.

            <br/><br/>
            The value of {Register("FPUM")} represents a floating-point number in IEEE-754 format.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            {Register("FPUR")} may be used as a {SOURCE} to retrieve the result of the FPU's last operation. {Register("FPUR")} is always automatically updated after every cycle in which one of the FPU's input or mode registers were modified.

        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("FPUR")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("ALUA")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            In this instruction, the result of the FPU's last operation, retrieved from {Register("FPUR")}, is being copied to the ALU as an operand.
            Note that there is no "conversion" of the floating-point result to an integer;
            the ALU will interpret the same bytes as an integer, which will likely be a completely different number.

            <br/><br/>
            If the result of the FPU's operation was 4.7, for example, its hexadecimal representation would be 0x40966666.
            When used by the ALU, this will be interpreted as the integer 1083598438.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            {Register("FPUR")} is read-only, and therefore may not be used as a {DEST}.
            <br/><br/>
            Any instruction that uses {Register("FPUR")} as the {DEST} will have no effect.
        </p>
    </S.Outer>
)

export const BASE = (name) => (props) => (
    <S.Outer>
        <S.Header>{Register(name)}</S.Header>
        <S.Subtitle>Memory Accesor "{name[0]}" {name[1] === "B" ? "Base" : "Offset"}</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={DEST_COLOR}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. 32-bit register.
                <br/>When used as a {SOURCE}, copies the register's data to the {DEST}.
            </p>
            <p>
                Valid {DEST}. 32-bit register.
                <br/>When used as a {DEST}, sets the "{name[0]}" memory accessor's {name[1] === "B" ? "base address" : "offset"} equal to the value from the {SOURCE}.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            {Register(name)}, together with {Register(`${name[0]}${name[1] === "B" ? "OFST" : "BASE"}`)} and {Register(`${name[0]}MEM`)}, make up one of two "memory accessors" on the processor.
            These accessors are used to read from or write to memory at any address. This is the primary mechanism for moving data between registers on the processor and main memory.

            <br/><br/>
            The accessors are named the "R" accessor and the "W" accessor to distinguish between the two, but they function exactly the same way.
            There are two accessors so that two different places in memory can be accessed as the same time.

            <br/><br/>
            The names "R" and "W" allude to the idea that one could be used like a "read head" while the other is used as a "write head" (like in a copying operation),
            but <em><b>both</b> accessors are capable of <b>both</b> reading <b>and</b> writing</em>.

            <br/><br/>
            The memory accessor works by accepting two input values, a base address and an offset ({Register(`${name[0]}BASE`)} and {Register(`${name[0]}OFST`)}).
            The values of {Register(`${name[0]}BASE`)} and {Register(`${name[0]}OFST`)} are added together to compute the target address.

            <br/><br/>
            Four bytes in main memory, starting at the target address, can then be read by using {Register(`${name[0]}MEM`)} as a {SOURCE}, or written by using {Register(`${name[0]}MEM`)} as a {DEST}.

            <br/><br/>
            {Register(`${name[0]}BASE`)} and {Register(`${name[0]}OFST`)} are both registers on the processor that hold data.
            It is important to note, however, that {Register(`${name[0]}MEM`)} is <b>not a register</b>. It is a special location that accesses memory directly;
            any reads or writes to {Register(`${name[0]}MEM`)} read and write main memory directly.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            When used as a {SOURCE}, {Register(name)} is like any normal register. It's 32-bit value is used as the data which is written to the {DEST}.
        </p>
        <S.Gap/>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register(name)}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("GPB")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            In the example instruction above, the value held in {Register(name)} will be copied to {Register("GPB")}.
            This can be done to save a recently accessed address for later.
            <br/><br/>
            Since {Register(name)} will not change when used as a {SOURCE}, {Register(`${name[0]}MEM`)} will still point to the same target address after this instruction.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            When used as a {DEST}, {Register(name)} can be written to like most normal registers.
            Writing to {Register(name)} will use the data from the {SOURCE} as its new value.
            This will also change the target address of {Register(`${name[0]}MEM`)}, which is computed as ({Register(`${name[0]}BASE`)} + {Register(`${name[0]}OFST`)}).

            <br/><br/>
            Changing either {Register(`${name[0]}BASE`)} or {Register(`${name[0]}OFST`)} causes no memory accesses;
            it only changes the target address that will be used in future reads or writes to {Register(`${name[0]}MEM`)}.

            <br/><br/>
            (<b>Note</b>: in the simulator, {Register(`${name[0]}MEM`)} is shown as holding a value; this is a convenience to preview what value <em>would</em> be read from
            {Register(`${name[0]}MEM`)} if it were used as a {SOURCE} this cycle. The value shown will update whenever {Register(`${name[0]}BASE`)} or {Register(`${name[0]}OFST`)}
            changes, but no actual memory access occurs. This distinction is not relevant for this version of the simulator, but if <b>caching</b> or <b>virtual memory</b> were enabled, this
            would have significant implications.)
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("IADF")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register(name)}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            The example instruction demonstrates a common way to fill {Register(name)}.
            Since the instructions only allow loading numbers less than 128 as a constant using a single instruction, it is not possible to load a four-byte constant address that way.

            <br/><br/>
            To achieve this, we can instead use the special {Register("IADF")} location to use nearby instructions as data to fill {Register(name)} with an arbitrary address.

            <br/><br/>
            The snippet below demonstrates accessing an arbitrary, constant location in memory.
        </p>
        <h5>
            (Read more about using instructions as data in the help popups for {Register("IADF")} and {Register("FPUA")}.)
        </h5>
        <S.ButtonContainer>
            <S.InstGrid>
                <S.ListItemHolder>{Register("IADF")}</S.ListItemHolder>
                <S.ListItemHolder>{Register(`${name[0]}BASE`)}</S.ListItemHolder>

                <S.ListItemHolder>{Register("SKIP")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>

                <S.ListItemHolder>0x00</S.ListItemHolder>
                <S.ListItemHolder>0x00</S.ListItemHolder>

                <S.ListItemHolder>0x0F</S.ListItemHolder>
                <S.ListItemHolder>0x00</S.ListItemHolder>

                <S.ListItemHolder>0x08</S.ListItemHolder>
                <S.ListItemHolder>{Register(`${name[0]}OFST`)}</S.ListItemHolder>

                <S.ListItemHolder>{Register(`${name[0]}MEM`)}</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPA")}</S.ListItemHolder>
            </S.InstGrid>
            {UpdateEditor(props, `IADF ${name[0]}BASE\nSKIP PC\n0x00 0x00\n0x0F 0x00\n0x08 ${name[0]}OFST\n${name[0]}MEM GPA`)}
        </S.ButtonContainer>
        <p>
            This snippet loads the address 0x00000F00 (3840) into {Register(`${name[0]}BASE`)} and 0x08 into {Register(`${name[0]}OFST`)},
            setting the accessor's target address to (0x00000F00 + 0x08 = 0x00000F08).
            Finally, the four bytes starting at that address are copied into {Register("GPA")}.

            <br/><br/>
            Something like this might be done to access a member of a struct located at 0x00000F00 with an offset of 8.

            <br/><br/>
            Values for {Register(`${name[0]}BASE`)} and {Register(`${name[0]}OFST`)} do not have to be static, of course; dynamic values
            can be computed with the ALU, or loaded from any {SOURCE}, including the other memory accessor.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>Motivation</S.T>
        <p>
            Why does the processor include memory accessors? Why not access memory with devoted instructions?

            <br/><br/>
            One of the design goals of this assembly language was to have a consistent instruction format of [{SOURCE} {DEST}];
            Another goal was to create a RISC processor, so all instructions should be the same size.
            This meant that if an instruction were to use memory addresses directly as a {SOURCE} or {DEST}, the number of accessible addresses
            would have to be very small, or the size of the instruction would have be large enough to accommodate the largest address.

            <br/><br/>
            Instead of limiting memory, we break memory accesses into multiple instructions, and introduce some processor state (the memory accessor registers)
            to combine these instructions across cycles. This approach is CISC-like in that we require more / longer instructions to perform a memory access than a simple move,
            but we did not actually increase the size of any single executable instruction.

            <br/><br/>
            With the memory accessor approach, we meet the goal of keeping all instructions the same, short length, without coupling the size of memory to the size of the instruction.
        </p>
    </S.Outer>
)

export const MEM = (name) => (props) => (
    <S.Outer>
        <S.Header>{Register(name)}</S.Header>
        <S.Subtitle>Memory Accesor "{name[0]}"</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={DEST_COLOR}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. Reads from memory.
                <br/>When used as a {SOURCE}, copies four bytes from the target address to the {DEST}.
            </p>
            <p>
                Valid {DEST}. Writes to memory.
                <br/>When used as a {DEST}, writes the {SOURCE} data to the target address in memory.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            {Register(name)}, together with {Register(`${name[0]}BASE`)} and {Register(`${name[0]}OFST`)}, make up one of two "memory accessors" on the processor.
            These accessors are used to read from or write to memory at any address. This is the primary mechanism for moving data between registers on the processor and main memory.

            <br/><br/>
            The accessors are named the "R" accessor and the "W" accessor to distinguish between the two, but they function exactly the same way.
            There are two accessors so that two different places in memory can be accessed as the same time.

            <br/><br/>
            The names "R" and "W" allude to the idea that one could be used like a "read head" while the other is used as a "write head" (like in a copying operation),
            but <em><b>both</b> accessors are capable of <b>both</b> reading <b>and</b> writing</em>.

            <br/><br/>
            The memory accessor works by accepting two input values, a base address and an offset ({Register(`${name[0]}BASE`)} and {Register(`${name[0]}OFST`)}).
            The values of {Register(`${name[0]}BASE`)} and {Register(`${name[0]}OFST`)} are added together to compute the target address.

            <br/><br/>
            Four bytes in main memory, starting at the target address, can then be read by using {Register(`${name[0]}MEM`)} as a {SOURCE}, or written by using {Register(`${name[0]}MEM`)} as a {DEST}.

            <br/><br/>
            {Register(`${name[0]}BASE`)} and {Register(`${name[0]}OFST`)} are both registers on the processor that hold data.
            It is important to note, however, that {Register(`${name[0]}MEM`)} is <b>not a register</b>. It is a special location that accesses memory directly;
            any reads or writes to {Register(`${name[0]}MEM`)} read and write main memory directly.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            When used as a {SOURCE}, {Register(name)} reads four bytes from main memory.
            The address read is calculated by adding the contents of {Register(`${name[0]}BASE`)} and {Register(`${name[0]}OFST`)}.
        </p>
        <S.Gap/>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register(name)}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("GPB")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            In the example instruction above, four bytes starting at the address ({Register(`${name[0]}BASE`)} + {Register(`${name[0]}OFST`)}) are into a general purpose register.
            This is the primary way of reading from dynamic memory sources.

            <br/><br/>
            (<b>Note</b>: in the simulator, {Register(`${name[0]}MEM`)} is shown as holding a value; this is a convenience to preview what value <em>would</em> be read from
            {Register(`${name[0]}MEM`)} if it were used as a {SOURCE} this cycle. The value shown will update whenever {Register(`${name[0]}BASE`)} or {Register(`${name[0]}OFST`)}
            changes, but no actual memory access occurs. This distinction is not relevant for this version of the simulator, but if <b>caching</b> or <b>virtual memory</b> were enabled, this
            would have significant implications.)

            <br/><br/>
            The simulator shows a preview of the region of memory near the target address of each memory accessor.
            This is useful for seeing what memory you're accessing, and fixing memory access bugs.
            The highlighted region indicates the four bytes at the target address.
        </p>
        <S.Subtitle>
            <img src="preview.png"/>
        </S.Subtitle>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            When used as a {DEST}, {Register(name)} can be used to write to main memory.
            Writing to {Register(name)} will write the four bytes of {SOURCE} date to the target address.
            As discussed above, the target address is calculated by adding {Register(`${name[0]}BASE`)} and {Register(`${name[0]}OFST`)}.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("FPUR")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register(name)}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            The example instruction demonstrates storing the result of a recent FPU operation in memory.
            This can be useful if the results of a calculation are not immediately useful, so we do not want to take up one of the general purpose registers;
            instead, we can write it to memory, where there is ample storage space.

            <br/><br/>
            The memory accessors can become much more powerful when used in combination with the ALU.
            We can quickly modify more than just four bytes of memory by taking advantage of the {Register(`${name[0]}OFST`)} register.

            <br/><br/>
            The following snippet demonstrates pushing the general purpose registers onto a stack, as might occur during a function call routine.
        </p>
        <h5>
            (Read more about using instructions as data in the help popups for {Register("IADF")} and {Register("FPUA")}.)
        </h5>
        <S.ButtonContainer>
            <S.InstGrid>
                <S.ListItemHolder>0x11</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPA")}</S.ListItemHolder>

                <S.ListItemHolder>0x22</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPB")}</S.ListItemHolder>

                <S.ListItemHolder>0x33</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPC")}</S.ListItemHolder>

                <S.ListItemHolder>0x44</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPD")}</S.ListItemHolder>


                <S.ListItemHolder>{Register("IADF")}</S.ListItemHolder>
                <S.ListItemHolder>{Register(`${name[0]}BASE`)}</S.ListItemHolder>

                <S.ListItemHolder>{Register("SKIP")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>

                <S.ListItemHolder>0x00</S.ListItemHolder>
                <S.ListItemHolder>0x00</S.ListItemHolder>

                <S.ListItemHolder>0x10</S.ListItemHolder>
                <S.ListItemHolder>0x00</S.ListItemHolder>

                <S.ListItemHolder>0x00</S.ListItemHolder>
                <S.ListItemHolder>{Register(`${name[0]}OFST`)}</S.ListItemHolder>

                <S.ListItemHolder>0x01</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUM")}</S.ListItemHolder>

                <S.ListItemHolder>0x00</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUA")}</S.ListItemHolder>

                <S.ListItemHolder>0x04</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUB")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("GPA")}</S.ListItemHolder>
                <S.ListItemHolder>{Register(`${name[0]}MEM`)}</S.ListItemHolder>

                <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register(`${name[0]}OFST`)}</S.ListItemHolder>

                <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUA")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("GPB")}</S.ListItemHolder>
                <S.ListItemHolder>{Register(`${name[0]}MEM`)}</S.ListItemHolder>

                <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register(`${name[0]}OFST`)}</S.ListItemHolder>

                <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUA")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("GPC")}</S.ListItemHolder>
                <S.ListItemHolder>{Register(`${name[0]}MEM`)}</S.ListItemHolder>

                <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register(`${name[0]}OFST`)}</S.ListItemHolder>

                <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUA")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("GPD")}</S.ListItemHolder>
                <S.ListItemHolder>{Register(`${name[0]}MEM`)}</S.ListItemHolder>
            </S.InstGrid>
            {UpdateEditor(props, `0x11 GPA\n0x22 GPB\n0x33 GPC\n0x44 GPD
            IADF ${name[0]}BASE\nSKIP PC\n0x00 0x00\n0x10 0x00
            0x00 ${name[0]}OFST
            0x01 ALUM\n0x00 ALUA\n0x04 ALUB
            GPA ${name[0]}MEM\nALUR ${name[0]}OFST\nALUR ALUA
            GPB ${name[0]}MEM\nALUR ${name[0]}OFST\nALUR ALUA
            GPC ${name[0]}MEM\nALUR ${name[0]}OFST\nALUR ALUA
            GPD ${name[0]}MEM
            `)}
        </S.ButtonContainer>
        <p>
            It might be helpful to see this snippet in action; try running it in the simulator by copying & pasting it into the code editor.

            <br/><br/>
            As mentioned above, this example demonstrates pushing the contents of some general purpose registers onto a stack.
            In the example, the stack is presumed to start at address 0x00001000 and stack in the positive direction.

            <br/><br/>
            The first five instructions set the accessor's target address to the top of the stack, address 0x00001000.
            The next four instructions set the ALU up for stacking by setting it to "add" mode and setting the operands to 0 and 4.
            0 is the first operand, representing our starting offset. 4 is the second operand, since we will be incrementing the offset by 4 bytes with each register we push on the stack.

            <br/><br/>
            Finally, we begin pushing to the stack. We are able to store one register's contents in memory, increment the offset, and adjust the stack pointer (the memory accessor's target address)
            in only three instructions, which can then be repeated.

            <br/><br/>
            Take a closer look at this repeated block of instructions:
        </p>
        <S.InstGrid>
            <S.ListItemHolder>{Register("GPA")}</S.ListItemHolder>
            <S.ListItemHolder>{Register(`${name[0]}MEM`)}</S.ListItemHolder>

            <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
            <S.ListItemHolder>{Register(`${name[0]}OFST`)}</S.ListItemHolder>

            <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
            <S.ListItemHolder>{Register("ALUA")}</S.ListItemHolder>

            <S.ListItemHolder>{Register("GPB")}</S.ListItemHolder>
            <S.ListItemHolder>{Register(`${name[0]}MEM`)}</S.ListItemHolder>

            <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
            <S.ListItemHolder>{Register(`${name[0]}OFST`)}</S.ListItemHolder>

            <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
            <S.ListItemHolder>{Register("ALUA")}</S.ListItemHolder>

            <S.ListItemHolder>{Register("GPC")}</S.ListItemHolder>
            <S.ListItemHolder>{Register(`${name[0]}MEM`)}</S.ListItemHolder>

            <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
            <S.ListItemHolder>{Register(`${name[0]}OFST`)}</S.ListItemHolder>

            <S.ListItemHolder>{Register("ALUR")}</S.ListItemHolder>
            <S.ListItemHolder>{Register("ALUA")}</S.ListItemHolder>

            <S.ListItemHolder>{Register("GPD")}</S.ListItemHolder>
            <S.ListItemHolder>{Register(`${name[0]}MEM`)}</S.ListItemHolder>
        </S.InstGrid>
        <p>
            The trick is that both the ALU and accessor are set up so that we only need to change {Register("ALUA")} and {Register(`${name[0]}OFST`)} in
            order to advance the stack pointer. By chaining the output of the ALU into its input
            ([{Register("ALUR")} {Register("ALUA")}]), {Register("ALUR")} is incremented by 4.
            We also use the newly calculated result as the new offset for the accessor ([{Register("ALUR")} {Register(`${name[0]}OFST`)}]).
            The other instruction ([{Register("GPA")} {Register(`${name[0]}MEM`)}]) is the write operation, storing the value in memory.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>Motivation</S.T>
        <p>
            Why does the processor include memory accessors? Why not access memory with devoted instructions?

            <br/><br/>
            One of the design goals of this assembly language was to have a consistent instruction format of [{SOURCE} {DEST}];
            I also wanted the processor to be a RISC, so all instructions should be the same size.
            This meant that if an instruction were to use memory addresses directly as a {SOURCE} or {DEST}, the number of accessible addresses
            would have to be very small, or the size of the instruction would have be large enough to accommodate the largest address.

            <br/><br/>
            Instead of limiting memory, we break memory accesses into multiple instructions, and introduce some processor state (the memory accessor registers)
            to combine these instructions across cycles. This approach is CISC-like in that we require more / longer instructions to perform a memory access than a simple move,
            but we did not actually increase the size of any single executable instruction.

            <br/><br/>
            With the memory accessor approach, we meet the goal of keeping all instructions the same, short length, without coupling the size of memory to the size of the instruction.
        </p>
    </S.Outer>
)

export const SKIP = (props) => (
    <S.Outer>
        <S.Header>{Register("SKIP")}</S.Header>
        <S.Subtitle>Skip Immediate Address Register</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={"grey"}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. 32-bit register.
                <br/>Read-only.
                <br/>When used as a {SOURCE}, copies the register's data to the {DEST}. Always holds {Register("PC")} + 4.
            </p>
            <p>
                Not a valid {DEST}.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            The {Register("SKIP")} register is a special read-only location that is primarily used to skip the next two instructions.
            This is useful when the next two instructions are not meant to be executable, but rather hold a four-byte immediate value.

            <br/><br/>
            To accomplish this, {Register("SKIP")} always holds the value of {Register("PC")} + 4. Therefore, when executing [{Register("SKIP")} {Register("PC")}],
            execution always skips the next two instructions, and resumes on the third.

            <br/><br/>
            For this reason, {Register("SKIP")} is almost exclusively followed by {Register("PC")} as the {DEST}, and it often appears after an instruction that uses {Register("IADF")} as the {SOURCE}.
            It is, however, technically valid to use any location as the {DEST} when {Register("SKIP")} is the {SOURCE}.

            <br/><br/>
            Learn more about how {Register("SKIP")} is best used on the help popup for {Register("IADF")}.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            {Register("SKIP")} may only be used as a {SOURCE}. It always holds the value of {Register("PC")} + 4;
            in other words, it always holds the address of third instruction after the current instruction.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("SKIP")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("PC")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            This example demonstrates moving {Register("SKIP")} into {Register("PC")}, the primary use case for the {Register("SKIP")} location.

            A longer snippet demonstrating the effects of this instruction follows:
        </p>

        <S.ButtonContainer>
            <S.InstGrid>
                <S.ListItemHolder>{Register("SKIP")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>

                <S.ListItemHolder>0x01</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPA")}</S.ListItemHolder>

                <S.ListItemHolder>0x02</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPB")}</S.ListItemHolder>

                <S.ListItemHolder>0x03</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPC")}</S.ListItemHolder>
            </S.InstGrid>
            {UpdateEditor(props, "SKIP PC\n0x01 GPA\n0x02 GPB\n0x03 GPC")}
        </S.ButtonContainer>

        <p>
            In this snippet, only the first and last instructions execute. The middle instructions ([<b>0x01</b> {Register("GPA")}] and [<b>0x02</b> {Register("GPB")}])
            are skipped.

            <br/><br/>
            It is not especially common to skip normal instructions with [{Register("SKIP")} {Register("PC")}]. It is more typical to skip instructions that are meant to be interpreted as a numerical constant:
        </p>
        <S.ButtonContainer>
            <S.InstGrid>
                <S.ListItemHolder>{Register("SKIP")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>

                <S.ListItemHolder>0x11</S.ListItemHolder>
                <S.ListItemHolder>0x22</S.ListItemHolder>

                <S.ListItemHolder>0x33</S.ListItemHolder>
                <S.ListItemHolder>0x44</S.ListItemHolder>

                <S.ListItemHolder>0x03</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPC")}</S.ListItemHolder>
            </S.InstGrid>
            {UpdateEditor(props, "SKIP PC\n0x11 0x22\n0x33 0x44\n0x03 GPC")}
        </S.ButtonContainer>

        <p>
            In above code, we have encoded the immediate 0x11223344 in the program, and we avoid executing its data as instructions with [{Register("SKIP")} {Register("PC")}].

            <br/><br/>
            See the help popup for {Register("IADF")} or {Register("FPUA")} to learn more about how this can be used.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            {Register("SKIP")} is read-only, and therefore may not be used as a {DEST}.
            <br/><br/>
            Any instruction that uses {Register("SKIP")} as the {DEST} will have no effect.
        </p>
    </S.Outer>
)

export const RTRN = () => (
    <S.Outer>
        <S.Header>{Register("RTRN")}</S.Header>
        <S.Subtitle>Return Address Register</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={"grey"}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. 32-bit register.
                <br/>Read-only.
                <br/>When used as a {SOURCE}, copies the register's data to the {DEST}. Always holds {Register("PC")} + 6.
            </p>
            <p>
                Not a valid {DEST}.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            The {Register("RTRN")} register is a special read-only location; it enables jumping to a new, non-contiguous part of the program with the intent to return here.

            <br/><br/>
            {Register("RTRN")} always holds the value of {Register("PC")} + 6; this value was chosen because, in practice, it is a useful address to return to after jumping away.
            Continue reading for a more detailed explanation.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            {Register("RTRN")} may only be used as a {SOURCE}. It always holds the value of {Register("PC")} + 6;
            in other words, it always holds the address of the fourth instruction after this one.

            <br/><br/>
            The space between {Register("PC")} and {Register("PC")} + 6 is enough to hold one instruction and one, four-byte immediate value.
            This is useful for a highly specific, but common, construct that enables "jump-and-link" behavior.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("RTRN")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("LINK")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            The above instruction is the most common way to use {Register("RTRN")}. It almost always appears in a snippet like this:
        </p>

        <S.InstGrid>
            <S.ListItemHolder>{Register("RTRN")}</S.ListItemHolder>
            <S.ListItemHolder>{Register("LINK")}</S.ListItemHolder>

            <S.ListItemHolder>{Register("IADN")}</S.ListItemHolder>
            <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>

            <S.ListItemHolder>0x00</S.ListItemHolder>
            <S.ListItemHolder>0x00</S.ListItemHolder>

            <S.ListItemHolder>0x33</S.ListItemHolder>
            <S.ListItemHolder>0x44</S.ListItemHolder>

            <S.ListItemHolder>0x01</S.ListItemHolder>
            <S.ListItemHolder>{Register("GPC")}</S.ListItemHolder>
        </S.InstGrid>

        <p>
            The goal of the code above is to jump to another part of the code, execute it, and return here; in other words, it is a function call.

            <br/><br/>
            This code begins by saving {Register("RTRN")} ({Register("PC")} + 6) to a register ({Register("LINK")} is used by convention and in this case,
            but any register can be used). The value of {Register("RTRN")} is the address of the [<b>0x01</b> {Register("GPC")}] instruction, the last one in the snippet.
            This is the address to which we want to return when our target function exits.

            <br/><br/>
            The next instruction uses {Register("IADN")} to load an immediate address into {Register("PC")}.
            By using an immediate, we can specify an arbitrary address to jump to; in this case, it is the address of our target function;
            its address is 0x00003344.

            <br/><br/>
            The next two instructions ([<b>0x11</b> <b>0x22</b>] and [<b>0x33</b> <b>0x44</b>]) are actually just data; they encode the target function's address.
            Since these instructions are not meant to be executed, we do not want to execute them when we return from our function.
            This is why we saved the address of the [<b>0x01</b> {Register("GPC")}] instruction into {Register("LINK")}: it is the first instruction
            we want to execute after returning, skipping the jump instruction and the function address data.

            <br/><br/>
            Since our return address is stored in {Register("LINK")}, the target function only needs to execute [{Register("LINK")} {Register("PC")}]
            to return to our code.

            <br/><br/>
            Note that if, instead of [{Register("RTRN")} {Register("LINK")}], our first instruction had been [{Register("PC")} {Register("LINK")}],
            the first instruction executed after returning would be [{Register("IADN")} {Register("PC")}], causing us to jump back to the target function.
            This results in an infinite loop.

        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            {Register("RTRN")} is read-only, and therefore may not be used as a {DEST}.
            <br/><br/>
            Any instruction that uses {Register("RTRN")} as the {DEST} will have no effect.
        </p>
    </S.Outer>
)

export const LINK  = () => (
    <S.Outer>
        <S.Header>{Register("LINK")}</S.Header>
        <S.Subtitle>Link Register</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={DEST_COLOR}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. 32-bit register.
                <br/>When used as a {SOURCE}, copies the register's data to the {DEST}.
            </p>
            <p>
                Valid {DEST}. 32-bit register.
                <br/>When used as a {DEST}, sets the register's value to the data from {SOURCE}.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            {Register("LINK")} is technically a general purpose register, and therefore functions exactly like {Register("GPA")} or any other general purpose register.

            <br/><br/>
            By convention, {Register("LINK")} is used to store the return address of a function call; it is set by the caller and can be used by the callee to jump back to the caller.
            Its name denotes this special convention, but it has no special behavior.

            <br/><br/>
            The help popup for {Register("RTRN")} has more information about how {Register("LINK")} is normally used.

        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            When used as a {SOURCE}, the value in {Register("LINK")} will be stored in the {DEST}.
        </p>
        <S.Gap/>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("LINK")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("GPG")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            In this example, {Register("LINK")}'s contents are being moved to {Register("GPG")}.
            It is common to use {Register("LINK")} as a {SOURCE} to copy the caller's return address, particularly if the callee will make its own function calls, because {Register("LINK")} will be overwritten.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            Any value can be written to {Register("LINK")} when it is used as a {DEST}.
            It is most common to use {Register("LINK")} to hold the intended return address when making a function call.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("RTRN")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("LINK")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            This instruction shows the most common use of {Register("LINK")} as a {DEST}.
            As a {DEST}, it is almost always part of a snippet like this:
        </p>
        <S.InstGrid>
            <S.ListItemHolder>{Register("RTRN")}</S.ListItemHolder>
            <S.ListItemHolder>{Register("LINK")}</S.ListItemHolder>

            <S.ListItemHolder>{Register("IADN")}</S.ListItemHolder>
            <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>

            <S.ListItemHolder>0x00</S.ListItemHolder>
            <S.ListItemHolder>0x00</S.ListItemHolder>

            <S.ListItemHolder>0x33</S.ListItemHolder>
            <S.ListItemHolder>0x44</S.ListItemHolder>

            <S.ListItemHolder>0x01</S.ListItemHolder>
            <S.ListItemHolder>{Register("GPA")}</S.ListItemHolder>
        </S.InstGrid>

        <p>
            This snippet stores {Register("RTRN")} (equivalent to {Register("PC")} + 6) in {Register("LINK")} before loading an immediate
            address into {Register("PC")}. The target function, located at address 0x00003344, can read from {Register("LINK")} to
            retrieve the return address.

            <br/><br/>
            Learn more about using {Register("LINK")} with {Register("RTRN")} on the help popup for {Register("RTRN")}.
        </p>
    </S.Outer>
)

export const COMP = (name) => (props) => (
    <S.Outer>
        <S.Header>{Register(name)}</S.Header>
        <S.Subtitle>Branching Comparator Input "{name.at(name.length - 1)}"</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={DEST_COLOR}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. 32-bit register.
                <br/>When used as a {SOURCE}, copies the register's data to the {DEST}. As a source, {Register(name)} works like a normal register.
            </p>
            <p>
                Valid {DEST}. 32-bit register.
                <br/>When used as a {DEST}, sets the register's value to the data from {SOURCE}.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            {Register(name)} is one of two input registers for the branching comparator. {Register("COMPA")} and {Register("COMPB")} work exactly the same way.

            <br/><br/>
            The branching comparator is used for conditional control flow (if-statements) in a program. It enables changing the order of execution based on the comparison of two values.

            <br/><br/>
            The branching comparator works by comparing the values of the input registers, {Register("COMPA")} and {Register("COMPB")}, to determine the target address of a conditional memory read.
            If the registers hold equal values, the target address will be the value held in {Register("PC")}; if {Register("COMPA")} and {Register("COMPB")} are different, the target address will be {Register("PC")} + 4.

            <br/><br/>
            The target address can be read from memory by using the special location {Register("COMPR")} as a {SOURCE}. {Register("COMPR")} is not a register; it accesses memory directly.
            In this way, it is similar to {Register("RMEM")} and {Register("WMEM")}. Unlike those locations, however, {Register("COMPR")} cannot be used as a {DEST} to write to memory.

            <br/><br/>
            With the instruction [{Register("COMPR")} {Register("PC")}], we can make the next statement executed dependent on the comparator's result.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            When used as a {SOURCE}, the value in {Register(name)} will be stored in the {DEST}. So, as a {SOURCE}, {Register(name)} is much like a normal register.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register(name)}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("GPB")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            This example demonstrates retrieving a value from {Register(name)} and storing it into a general purpose register.
            Since the value in {Register(name)} remains the same, the result of the comparison will remain the same. The target address of {Register("COMPR")}
            will still change, though, since the target address is based on {Register("PC")}.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            Using {Register(name)} as a {DEST} will copy the {SOURCE} data to {Register(name)};
            in the same cycle, {Register("COMPA")} and {Register("COMPB")} will be compared for equality, and the target address of {Register("COMPR")} will be updated based on that comparison.

            <br/><br/>
            If the values are the same, {Register("COMPR")}'s target will be the value of {Register("PC")}; if the values are different, the target address will be {Register("PC")} + 4.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    <S.CSpan>13</S.CSpan>
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register(name)}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            Here, the value 13 is being loaded into one of the comparator's input registers.
            It is common to see a variety of different {SOURCE}s used with {Register(name)} as the {DEST}; we may want to make a comparison against a constant, an ALU result, a register, or any other {SOURCE}.

            <br/><br/>
            It is easier to see the effects of the comparator across multiple instructions, so this example shows a program making a simple comparison.
        </p>
        <S.ButtonContainer>
            <S.InstGrid>
                <S.ListItemHolder>1</S.ListItemHolder>
                <S.ListItemHolder>{Register("COMPA")}</S.ListItemHolder>

                <S.ListItemHolder>2</S.ListItemHolder>
                <S.ListItemHolder>{Register("COMPB")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("COMPR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>

                <S.ListItemHolder>0x00</S.ListItemHolder>
                <S.ListItemHolder>0x00</S.ListItemHolder>

                <S.ListItemHolder>0x00</S.ListItemHolder>
                <S.ListItemHolder>0x0E</S.ListItemHolder>

                <S.ListItemHolder>0x00</S.ListItemHolder>
                <S.ListItemHolder>0x00</S.ListItemHolder>

                <S.ListItemHolder>0x00</S.ListItemHolder>
                <S.ListItemHolder>0x14</S.ListItemHolder>

                <S.ListItemHolder>11</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPA")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("SKIP")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>

                <S.ListItemHolder>0</S.ListItemHolder>
                <S.ListItemHolder>0</S.ListItemHolder>

                <S.ListItemHolder>99</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPA")}</S.ListItemHolder>

                <S.ListItemHolder>55</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPB")}</S.ListItemHolder>
            </S.InstGrid>
            {UpdateEditor(props, `1 COMPA\n2 COMPB
            COMPR PC\n0x00 0x00\n0x00 0x0E
            0x00 0x00\n0x00 0x14
            11 GPA\nSKIP PC\n0 0
            99 GPA
            55 GPB
            `)}
        </S.ButtonContainer>

        <p>
            Copying & pasting this code into the editor to run it in the simulator is the best way to understand how it works.

            <br/><br/>
            The first two instructions set the inputs to the comparator as 1 and 2. Since those two values are not equal,
            [{Register("COMPR")} {Register("PC")}] loads 0x00000014 into {Register("PC")}.
            The next instruction run is then [<b>99</b> {Register("GPA")}], followed by [<b>55</b> {Register("GPB")}].

            <br/><br/>
            If instead of 1 and 2, the comparator's inputs are changed to 1 and 1, the code takes a different path;
            [{Register("COMPR")} {Register("PC")}] loads 0x0000000E into {Register("PC")}, making the next instruction executed [<b>11</b> {Register("GPA")}].

            <br/><br/>
            The final two instructions executed are [{Register("SKIP")} {Register("PC")}] and then [<b>55</b> {Register("GPB")}].

            <br/><br/>
            In the first case, when the comparator inputs were not equal, {Register("GPA")} ends holding the value 11 (0x0B).

            <br/><br/>
            In the second case, when the comparator inputs were equal, {Register("GPA")} ends holding the value 99 (0x63).

            <br/><br/>
            Note that, in both cases, {Register("GPB")} ends with the value 55 (0x37).

            <br/><br/>
            The snippet above contains four instructions' worth of instructions-as-data, and one no-op [<b>0 0</b>] (which is never executed).
            To understand more about these patterns, look at the help popups for {Register("IADF")}, {Register("SKIP")}, and {Register("FPUA")}.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>Motivation</S.T>
        <p>
            Why include a branching comparator? Why not include a branch instruction?

            <br/><br/>
            In order to include a branching instruction, we would have be able to specify, with a single instruction, either multiple {SOURCE}s or multiple {DEST}s.
            Otherwise, we could include a way to use an opcode other than the implicit "move" of all other instructions.

            <br/><br/>
            All three of these approaches violate the design goal to reach a homogeneous set of instructions with the form [{SOURCE} {DEST}].

            <br/><br/>
            Instead of a branching instruction, then, new functionality was added to the processor in the form of the branching comparator.
            There were many possible alternatives to enable conditional behavior, but this particular approach was chosen because it matched up nicely with some of
            the other instructions-as-data patterns that had already been introduced.

            <br/><br/>
            {Register("SKIP")}, {Register("RTRN")}, {Register("IADN")}, {Register("IADF")}, and {Register("COMPR")} are a group of closely related
            locations which were co-designed to work together to achieve concise branching and jumping logic.
            This is why you will find they have many similarities and often appear near each other in instructions.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>Labels</S.T>
        <p>
            In this version of the simulator, the branching comparator can be tricky to use because of the need to encode fixed target locations
            after the [{Register("COMPR")} {Register("PC")}] instruction. If any changes are made to the code, the target locations need to be updated, too.

            <br/><br/>
            This problem is solved with an assembler that supports adding labels in the code.
            The labels can be used as stand-ins for four-byte values or addresses, and the assembler can automatically resolve the labels to the correct
            addresses once all the code is present.

            <br/><br/>
            This online version of the simulator does not include support for labels, though they are a functional necessity for writing any significant program,
            or any program that spans multiple files or translation units.
        </p>
    </S.Outer>
)

export const COMPR = (props) => (
    <S.Outer>
        <S.Header>{Register("COMPR")}</S.Header>
        <S.Subtitle>Branching Comparator Read Result</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={"grey"}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. Reads from memory.
                <br/>When used as a {SOURCE}, copies four bytes from the target address to the {DEST}.
            </p>
            <p>
                Not a valid {DEST}.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            {Register("COMPR")}, along with {Register("COMPA")} and {Register("COMPB")}, make up the branching comparator.

            <br/><br/>
            The branching comparator is used for conditional control flow (if-statements) in a program. It enables changing the order of execution based on the comparison of two values.

            <br/><br/>
            The branching comparator works by comparing the values of the input registers, {Register("COMPA")} and {Register("COMPB")}, to determine the target address of a conditional memory read.
            If the registers hold equal values, the target address will be the value held in {Register("PC")}; if {Register("COMPA")} and {Register("COMPB")} are different, the target address will be {Register("PC")} + 4.

            <br/><br/>
            The target address can be read from memory by using the special location {Register("COMPR")} as a {SOURCE}. {Register("COMPR")} is not a register; it accesses memory directly.
            In this way, it is similar to {Register("RMEM")} and {Register("WMEM")}. Unlike those locations, however, {Register("COMPR")} cannot be used as a {DEST} to write to memory.

            <br/><br/>
            With the instruction [{Register("COMPR")} {Register("PC")}], we can make the next statement executed dependent on the comparator's result.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            When used as a {SOURCE}, {Register("COMPR")} reads four bytes from main memory.
            The address read is determined by the result of comparing the contents of {Register("COMPA")} and {Register("COMPB")}.
            If those two registers hold equal values, the target address is the value of {Register("PC")}; if the values are not equal,
            the target address is {Register("PC")} + 4.

            <br/><br/>
            If the values are the same, {Register("COMPR")}'s target will be the value of {Register("PC")}; if the values are different, the target address will be {Register("PC")} + 4.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("COMPR")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("PC")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            In the instruction above, {Register("COMPR")} is being used to read memory into {Register("PC")}. This is the most common use case for {Register("COMPR")}.

            <br/><br/>
            {Register("COMPR")} is almost always used to read one of two possible target addresses into {Register("PC")},
            and is therefore usually accompanied by {Register("PC")} as the {DEST}.

            <br/><br/>
            This way, {Register("COMPR")} can be used to choose between two possible locations to continue execution: basically, the "if" clause, or the "else" clause.

            <br/><br/>
            The example below demonstrates how two different target addresses can follow the [{Register("COMPR")} {Register("PC")}] instruction to change
            the order of execution based on the result of the comparison of {Register("COMPA")} and {Register("COMPB")}.
        </p>

        <S.ButtonContainer>
            <S.InstGrid>
                <S.ListItemHolder>1</S.ListItemHolder>
                <S.ListItemHolder>{Register("COMPA")}</S.ListItemHolder>

                <S.ListItemHolder>2</S.ListItemHolder>
                <S.ListItemHolder>{Register("COMPB")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("COMPR")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>

                <S.ListItemHolder>0x00</S.ListItemHolder>
                <S.ListItemHolder>0x00</S.ListItemHolder>

                <S.ListItemHolder>0x00</S.ListItemHolder>
                <S.ListItemHolder>0x0E</S.ListItemHolder>

                <S.ListItemHolder>0x00</S.ListItemHolder>
                <S.ListItemHolder>0x00</S.ListItemHolder>

                <S.ListItemHolder>0x00</S.ListItemHolder>
                <S.ListItemHolder>0x14</S.ListItemHolder>

                <S.ListItemHolder>11</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPA")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("SKIP")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>

                <S.ListItemHolder>0</S.ListItemHolder>
                <S.ListItemHolder>0</S.ListItemHolder>

                <S.ListItemHolder>99</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPA")}</S.ListItemHolder>

                <S.ListItemHolder>55</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPB")}</S.ListItemHolder>
            </S.InstGrid>
            {UpdateEditor(props, `1 COMPA\n2 COMPB
            COMPR PC\n0x00 0x00\n0x00 0x0E
            0x00 0x00\n0x00 0x14
            11 GPA\nSKIP PC\n0 0
            99 GPA
            55 GPB
            `)}
        </S.ButtonContainer>

        <p>
            Copying & pasting this code into the editor to run it in the simulator is the best way to understand how it works.

            <br/><br/>
            The first two instructions set the inputs to the comparator as 1 and 2. Since those two values are not equal,
            [{Register("COMPR")} {Register("PC")}] loads 0x00000014 into {Register("PC")}.
            The next instruction run is then [<b>99</b> {Register("GPA")}], followed by [<b>55</b> {Register("GPB")}].

            <br/><br/>
            If instead of 1 and 2, the comparator's inputs are changed to 1 and 1, the code takes a different path;
            [{Register("COMPR")} {Register("PC")}] loads 0x0000000E into {Register("PC")}, making the next instruction executed [<b>11</b> {Register("GPA")}].

            <br/><br/>
            The final two instructions executed are [{Register("SKIP")} {Register("PC")}] and then [<b>55</b> {Register("GPB")}].

            <br/><br/>
            In the first case, when the comparator inputs were not equal, {Register("GPA")} ends holding the value 11 (0x0B).

            <br/><br/>
            In the second case, when the comparator inputs were equal, {Register("GPA")} ends holding the value 99 (0x63).

            <br/><br/>
            Note that, in both cases, {Register("GPB")} ends with the value 55 (0x37).

            <br/><br/>
            The snippet above contains four instructions' worth of instructions-as-data, and one no-op [<b>0 0</b>] (which is never executed).
            To understand more about these patterns, look at the help popups for {Register("IADF")}, {Register("SKIP")}, and {Register("FPUA")}.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            {Register("COMPR")} is read-only, and therefore may not be used as a {DEST}.
            <br/><br/>
            Any instruction that uses {Register("COMPR")} as the {DEST} will have no effect.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>Motivation</S.T>
        <p>
            Why include a branching comparator? Why not include a branch instruction?

            <br/><br/>
            In order to include a branching instruction, we would have be able to specify, with a single instruction, either multiple {SOURCE}s or multiple {DEST}s.
            Otherwise, we could include a way to use an opcode other than the implicit "move" of all other instructions.

            <br/><br/>
            All three of these approaches violate the design goal to reach a homogeneous set of instructions with the form [{SOURCE} {DEST}].

            <br/><br/>
            Instead of a branching instruction, then, new functionality was added to the processor in the form of the branching comparator.
            There were many possible alternatives to enable conditional behavior, but this particular approach was chosen because it matched up nicely with some of
            the other instructions-as-data patterns that had already been introduced.

            <br/><br/>
            {Register("SKIP")}, {Register("RTRN")}, {Register("IADN")}, {Register("IADF")}, and {Register("COMPR")} are a group of closely related
            locations which were co-designed to work together to achieve concise branching and jumping logic.
            This is why you will find they have many similarities and often appear near each other in instructions.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>Labels</S.T>
        <p>
            In this version of the simulator, the branching comparator can be tricky to use because of the need to encode fixed target locations
            after the [{Register("COMPR")} {Register("PC")}] instruction. If any changes are made to the code, the target locations need to be updated, too.

            <br/><br/>
            This problem is solved with an assembler that supports adding labels in the code.
            The labels can be used as stand-ins for four-byte values or addresses, and the assembler can automatically resolve the labels to the correct
            addresses once all the code is present.

            <br/><br/>
            This online version of the simulator does not include support for labels, though they are a functional necessity for writing any significant program,
            or any program that spans multiple files or translation units.
        </p>
    </S.Outer>
)

export const IADN = (props) => (
    <S.Outer>
        <S.Header>{Register("IADN")}</S.Header>
        <S.Subtitle>Near Instructions-As-Data Accessor</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={"grey"}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. Reads from memory.
                <br/>When used as a {SOURCE}, copies four bytes from the target address to the {DEST}.
            </p>
            <p>
                Not a valid {DEST}.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            {Register("IADN")} and {Register("IADF")} are both used to read instructions in the program as {SOURCE} data.
            This allows using immediate values that are four bytes long, which would otherwise not be possible.

            <br/><br/>
            This allows writing useful constants, like four-byte numbers, floating-point numbers, and addresses directly in the code,
            as well as accessing those constants easily - often with a single instruction.

            <br/><br/>
            {Register("IADN")} allows reading main memory at the address held in {Register("PC")}.
            In other words, {Register("IADN")} reads the next two instructions.
            This way, we can interpret the next four bytes of instructions literally, and therefore use any arbitrary four-byte number as {SOURCE} data.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            When used as a {SOURCE}, {Register("IADN")} reads four bytes from main memory.
            The address read is always the address held in {Register("PC")}.
            This means the data when {Register("IADN")} is the {SOURCE} is always the next two instructions.

            <br/><br/>
            Since the instructions following an {Register("IADN")} instruction are read as plain data, we generally do not want to execute those bytes as instructions.
            To avoid running them, we must use {Register("PC")} as the {DEST} in order to jump away.
            This makes {Register("IADN")} ideally suited for unconditional jumps to arbitrary addresses.

        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("IADN")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("PC")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            As seen in the example instruction, {Register("IADN")} is almost always followed by {Register("PC")} as the {DEST}.
            If we do not use {Register("PC")} as the {DEST}, the next four bytes will be executed as instructions, when they were meant to represent an address.

            <br/><br/>
            The snippet below demonstrates a simplified normal use case for {Register("IADN")}.
        </p>

        <S.ButtonContainer>
            <S.InstGrid>
                <S.ListItemHolder>{Register("IADN")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>

                <S.ListItemHolder>0x00</S.ListItemHolder>
                <S.ListItemHolder>0x00</S.ListItemHolder>

                <S.ListItemHolder>0x00</S.ListItemHolder>
                <S.ListItemHolder>0x0A</S.ListItemHolder>

                <S.ListItemHolder>11</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPA")}</S.ListItemHolder>

                <S.ListItemHolder>22</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPB")}</S.ListItemHolder>

                <S.ListItemHolder>33</S.ListItemHolder>
                <S.ListItemHolder>{Register("GPC")}</S.ListItemHolder>
            </S.InstGrid>
            {UpdateEditor(props, "IADN PC\n0x00 0x00\n0x00 0x0A\n11 GPA\n22 GPB\n33 GPC")}
        </S.ButtonContainer>

        <p>
            In this example, {Register("IADN")} is used as the {SOURCE} to copy the next four bytes into {Register("PC")}.
            These bytes are 0x0000000A, which is the address of the last instruction in the snippet.

            <br/><br/>
            The next instruction executed is then [<b>33</b> {Register("GPC")}]; note that the intervening two instructions
            (the ones that set {Register("GPA")} and {Register("GPB")}) are skipped.


            <br/><br/>
            This construct is particularly useful for something like a function call, where we want to jump to a known address
            to continue execution. In a function call, we need to be able to return to the caller, though.
            Read about {Register("RTRN")} in its help popup for an example of how this can be accomplished.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            {Register("IADN")} is read-only, and therefore may not be used as a {DEST}.
            <br/><br/>
            Any instruction that uses {Register("IADN")} as the {DEST} will have no effect.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>Labels</S.T>
        <p>
            In this version of the simulator, {Register("IADN")} is not convenient to use because of the need to write fixed target locations.
            If the code changes, these target locations need to be updated.

            <br/><br/>
            It would be easier to write position-independent code with {Register("IADN")} by using an assembler with support for labels.

            <br/><br/>
            The labels can be used as stand-ins for four-byte values or addresses, and the assembler can automatically resolve the labels to the correct
            addresses once all the code is present.

            <br/><br/>
            This online version of the simulator does not include support for labels, though they are a functional necessity for writing any significant program,
            or any program that spans multiple files or translation units.
        </p>
    </S.Outer>
)

export const IADF = (props) => (
    <S.Outer>
        <S.Header>{Register("IADF")}</S.Header>
        <S.Subtitle>Far Instructions-As-Data Accessor</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={"grey"}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. Reads from memory.
                <br/>When used as a {SOURCE}, copies four bytes from the target address to the {DEST}.
            </p>
            <p>
                Not a valid {DEST}.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            {Register("IADF")} and {Register("IADN")} are both used to read instructions in the program as {SOURCE} data.
            This allows using immediate values that are four bytes long, which would otherwise not be possible.

            <br/><br/>
            This allows writing useful constants, like four-byte numbers, floating-point numbers, and addresses directly in the code,
            as well as accessing those constants easily - often with a single instruction.

            <br/><br/>
            {Register("IADF")} allows reading main memory at the address held in {Register("PC")} + 2.
            In other words, {Register("IADN")} reads the two instructions following the next instruction (the second and third after the current instruction).
            This way, we can interpret those four bytes of instructions literally, and therefore use any arbitrary four-byte number as {SOURCE} data.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            When used as a {SOURCE}, {Register("IADF")} reads four bytes from main memory.
            The address read is always the address held in {Register("PC")} + 2.


        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("IADF")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("ALUA")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            In this example, a four-byte immediate is being loaded as an operand into the ALU.
            This allows us to use arbitrary operands, instead of being limited to the 128 values we can load using a literal in an instruction, like [<b>0x15</b> {Register("ALUA")}].


            <br/><br/>
            Unlike when using {Register("IADN")}, there is room for an additional instruction between an {Register("IADF")} instruction and its target data.
            This makes {Register("IADF")} ideal for loading a four-byte constant to any {DEST} without the intent to jump to a different part of the code.
            The {Register("SKIP")} location is an especially helpful partner in such a case.
        </p>

        <S.ButtonContainer>
            <S.InstGrid>
                <S.ListItemHolder>{Register("IADF")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUA")}</S.ListItemHolder>

                <S.ListItemHolder>{Register("SKIP")}</S.ListItemHolder>
                <S.ListItemHolder>{Register("PC")}</S.ListItemHolder>

                <S.ListItemHolder>0x11</S.ListItemHolder>
                <S.ListItemHolder>0x22</S.ListItemHolder>

                <S.ListItemHolder>0x33</S.ListItemHolder>
                <S.ListItemHolder>0x44</S.ListItemHolder>

                <S.ListItemHolder>1</S.ListItemHolder>
                <S.ListItemHolder>{Register("ALUM")}</S.ListItemHolder>
            </S.InstGrid>
            {UpdateEditor(props, "IADF ALUA\nSKIP PC\n0x11 0x22\n0x33 0x44\n1 ALUM")}
        </S.ButtonContainer>

        <p>
            In snippet above, {Register("IADF")} is used as the {SOURCE} to copy the value 0x11223344 into the ALU.

            <br/><br/>
            The next instruction, [{Register("SKIP")} {Register("PC")}], is necessary to move the program counter ahead of our immediate data.
            Without that instruction, we would execute those bytes as instructions, which is not what we intended. This is why an instruction
            using {Register("IADF")} as the {SOURCE} will almost always be followed by [{Register("SKIP")} {Register("PC")}].

            <br/><br/>
            The next instruction executed is then [<b>1</b> {Register("ALUM")}]; note that the intervening two instructions
            (the ones representing our constant) are skipped.

            <br/><br/>
            This is useful for all kinds of things, like loading addresses into {Register("RBASE")}, loading floating-point numbers into the FPU,
            or reading a constant into {Register("COMPA")} for comparison. See the help popup for {Register("FPUA")} for another example.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            {Register("IADF")} is read-only, and therefore may not be used as a {DEST}.
            <br/><br/>
            Any instruction that uses {Register("IADF")} as the {DEST} will have no effect.
        </p>
    </S.Outer>
)

export const FLAG  = () => (
    <S.Outer>
        <S.Header>{Register("FLAG")}</S.Header>
        <S.Subtitle>Status Register</S.Subtitle>
        <hr/>
        <S.InfoSection>
            <S.Subtitle><S.CSpan color={SOURCE_COLOR}>Source</S.CSpan></S.Subtitle>
            <S.Subtitle><S.CSpan color={DEST_COLOR}>Destination</S.CSpan></S.Subtitle>
            <p>
                Valid {SOURCE}. 32-bit register.
                <br/>When used as a {SOURCE}, copies the register's data to the {DEST}.
            </p>
            <p>
                Valid {DEST}. 32-bit register.
                <br/>When used as a {DEST}, sets the register's value to the data from {SOURCE}.
            </p>
        </S.InfoSection>
        <hr/>
        <h2>About</h2>
        <p>
            In this online version of the simulator, {Register("FLAG")} is technically a general purpose register, and therefore functions exactly like {Register("GPA")} or any other general purpose register.

            <br/><br/>
            {Register("FLAG")} has special behavior that is necessary for enabling interrupts and virtual memory.
            Since neither interrupts nor virtual memory are implemented in the online demo version of the simulator, {Register("FLAG")}'s special behavior has been disabled.

            <br/><br/>
            Read the "motivation" section below to learn about {Register("FLAG")}'s special behavior.

        </p>
        <S.Gap/>
        <hr/>
        <S.T>{SOURCE}</S.T>
        <p>
            When used as a {SOURCE}, the value in {Register("FLAG")} will be stored in the {DEST}.
        </p>
        <S.Gap/>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("FLAG")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("GPG")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <p>
            In this example, {Register("FLAG")}'s contents are being moved to {Register("GPG")}.
            In other versions of the simulator, an instruction like this is necessary to check the status of the processor
            when handling an interrupt. Read more about this in the "motivation" section.
        </p>
        <S.Gap/>
        <hr/>
        <S.T>{DEST}</S.T>
        <p>
            Any value can be written to {Register("FLAG")} when it is used as a {DEST}.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={SOURCE_COLOR}>
                    {Register("ALUR")}
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={DEST_COLOR}>
                    {Register("FLAG")}
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>
        <S.Gap/>
        <hr/>
        <S.T>Motivation</S.T>
        <p>
            What is the status register for?

            <br/><br/>
            This online demo is not the full version of the simulator. This processor was designed to support interrupts, virtual memory, and time-sharing.
            In order to simplify the demo, a small subset of the processor's functionality for those features was disabled.

            <br/><br/>
            The {Register("FLAG")} register, critical to the handling of interrupts, has special behavior that is disabled in this demo.
            Normally, {Register("FLAG")} is used to hold information about signals sent to the processor from other parts of the system,
            like input / output devices or the memory management unit (MMU).

            <br/><br/>
            Interrupt signals are sent from the environment and stored in {Register("FLAG")}.
            Between cycles, {Register("FLAG")} is updated to hold the most recent signal,
            and the processor checks {Register("FLAG")}'s contents before executing the next instruction.

            <br/><br/>
            If the contents of {Register("FLAG")} are non-zero, the processor will enter the interrupt trap instead of executing the next instruction normally.
        </p>
        <S.InstructionWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={"#EF233C"}>
                    U
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={"#FF9B42"}>
                    D
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={"#0BD55C"}>
                    L
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={"#00A7E1"}>
                    R
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={"#7A28CB"}>
                    K
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={"#3D68D6"}>
                    UD
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={"#F36878"}>
                    UL
                </S.InstructionElement>
            </S.LabelWrapper>
            <S.LabelWrapper>
                <S.InstructionElement color={"white"}>
                    7 ... 31
                </S.InstructionElement>
            </S.LabelWrapper>
        </S.InstructionWrapper>

        <br/>
        The 32 bits of a signal stored in {Register("FLAG")} are named according to the diagram above.
        Each of the first seven bits has a particular name and function, while the lower 25 bits are used to identify the source of the signal.

        <br/><br/>
        <S.Table>
            <tbody>
                <tr>
                    <S.TableHeader>Bit</S.TableHeader>
                    <S.TableHeader>Name</S.TableHeader>
                    <S.TableHeader>Description</S.TableHeader>
                </tr>
                <tr>
                    <td>U</td>
                    <td>Unlocked</td>
                    <td>Bits 0-5 can only be changed by the trap</td>
                </tr>
                <tr>
                    <td>D</td>
                    <td>Disabled</td>
                    <td>Trap is disabled; continue execution</td>
                </tr>
                <tr>
                    <td>L</td>
                    <td>Delay</td>
                    <td>Trap will set this bit to 0, then exit</td>
                </tr>
                <tr>
                    <td>R</td>
                    <td>Retry</td>
                    <td>After interrupt, retry previous instruction</td>
                </tr>
                <tr>
                    <td>K</td>
                    <td>Kernel</td>
                    <td>Kernel handles interrupt</td>
                </tr>
                <tr>
                    <td>UD</td>
                    <td>User Disabled</td>
                    <td>Trap is disabled for user mode interrupts</td>
                </tr>
                <tr>
                    <td>UL</td>
                    <td>User Delay</td>
                    <td>If user mode, trap will set this bit to 0, then exit</td>
                </tr>
            </tbody>
        </S.Table>

        <br/>
        This table gives a brief summary of the function of each bit. The "trap" refers to a sequence of steps that the processor
        automatically takes before the next cycle; the trap does not execute instructions - it simply follows a set routine
        according to the signal, without changing the processor's state (with a few exceptions).

        <br/><br/>
        According to the state of {Register("FLAG")}, the trap may overwrite {Register("FLAG")}, store some data in memory,
        or modify {Register("PC")}.

        <br/><br/>
        {Register("FLAG")} and the trap were designed with virtual memory and time-sharing in mind.
        In service of those features, interrupts were introduced to allow an operating system to
        take control of the processor when needed.

        <br/><br/>
        Interrupts can be useful to user programs, too, and so it is desirable to allow user programs
        to define their own interrupt handlers for some types of interrupts, while reserving the priority
        of the operating system for critical interrupts.

        <br/><br/>
        The <b>D</b>, <b>L</b>, <b>R</b>, and <b>K</b> bits of the {Register("FLAG")} signal are necessary for handling virtual memory related interrupts,
        while the <b>U</b>, <b>UD</b>, and <b>UL</b> bits were introduced to extend interrupt handling functionality to user programs.

        <br/><br/>
        Managing the signal flags is somewhat arcane; to understand the details of their roles, a full description of the
        MMU and trap would be necessary. Instead, we can briefly go over the general purpose of each flag:

        <br/><br/>
        <li>
            The <b>U</b> bit is used to stop user processes from disabling interrupts entirely
        </li>
        <li>
            The <b>D</b> bit is used to disable interrupts during interrupt handling
        </li>
        <li>
            The <b>UD</b> bit allows users to disable user interrupts during their handlers
        </li>
        <li>
            The <b>L</b> and <b>UL</b> bits are used when returning from interrupt handlers
        </li>
        <li>
            The <b>K</b> bit specifies if this is a user interrupt or kernel interrupt
        </li>
        <li>
            The <b>R</b> bit is used to retry instructions that caused MMU faults
        </li>

        <br/>
        As discussed above, none of this complexity is necessary in this online demo, since there is no operating system or MMU.
        With the addition of the trap and {Register("FLAG")} register, though, the processor is fully capable of supporting
        virtual memory, time-sharing, and memory-mapped IO devices.

    </S.Outer>
)

export const Popups = {
    Help,
    PC,
    GP,
    ALU,
    ALUM,
    ALUR,
    FPU,
    FPUM,
    FPUR,
    BASE,
    MEM,
    SKIP,
    RTRN,
    LINK,
    COMP,
    COMPR,
    IADN,
    IADF,
    FLAG,
}

export default Popups
