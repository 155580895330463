import styled, { css } from "styled-components"
//https://coolors.co/272727-fcf7f8-7a28cb-00a7e1-0bd55c-ff9b42-ef233c

//#10 on this page for press and hold animation
//https://dev.to/webdeasy/top-20-css-buttons-animations-f41

import {
    BGColor,
    FGColor,
    Floating,
    RoundSides,
    Transitions
} from "utility/StyleUtility"

// const Transitions = (time) => {
//     return css`
//         transition: ${time}s;
//         &:hover {
//             transition: ${time}s;
//         }
//         &:active {
//             transition: ${time}s;
//         }
//     `
// }

// const Floating = () => {
//     return css`
//         box-shadow: 4px 4px 4px #CCCCCC;
//         &:hover {
//             transform: translate(0px, -2px);
//         }

//         &:active {
//             box-shadow: 2px 2px 2px #CCCCCC;
//             z-index: 9;
//             transform: translate(0px, 0px);
//         }
//     `
// }

export const Popup = styled.div`
    position: fixed;
    width: 400px;
    height: 600px;
    top: calc(50% - 300px);
    left: calc(50% - 200px);

    // width: 101%;
    // height: 100%;
    // border: thick solid red;

    display: flex;
    backdrop-filter: blur(5px);
    // filter: drop-shadow(4px 4px 4px black);
    box-shadow: 8px 8px 30px rgba(0, 0, 0, 0.5);
    z-index: 11;
`

export const Inner = styled.div`
    flex: 1;
    background-color: purple;
    margin: 100px;
    z-index: 1;
    max-width: 400px;
    max-height: 400px;
`

export const Outer = styled.div`
    background: red;
`

export const TestArea = styled.div`
    box-shadow: 8px 8px 30px rgba(0, 0, 0, 0.2);
    background-color: #EEEEEE;

    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // justify-content: center;
    padding: 24px;

    max-width: 1600px;
    min-width: 588px;

    z-index: 1;
`


// const RoundSides = ({sides=0b0000, r=15}) => {
//     const tl = (sides >>> 3 & 0b1) && r
//     const tr = (sides >>> 2 & 0b1) && r
//     const br = (sides >>> 1 & 0b1) && r
//     const bl = (sides >>> 0 & 0b1) && r

//     return `border-radius: ${tl}px ${tr}px ${br}px ${bl}px;`
// }

// const BGColor = (({color}) => color ? `background-color: ${color};` : "")
// const FGColor = (({color}) => color ? `color: ${color};` : "")

export const Op = styled.div`
    border: medium solid currentColor;
    font-weight: 700;
    padding-left: 8px;
    padding-right: 8px;
    min-width: 3em;

    & + & {
        margin-top: 12px;
    }

    ${RoundSides}
`

export const Mem = styled.div`
    user-select: none;
    flex-grow: 1;
    width: 100%;
    background-color: #272727;
    color: white;
    min-height: 8em;
    ${RoundSides}
    ${Floating}
    ${Transitions(0.25)}

    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(8, 1fr);
    padding: 2px;

    &:hover {
        padding: 18px;
    }
`

export const SmallMem = styled.div`
    user-select: none;
    flex-grow: 1;
    width: 100%;
    background-color: #272727;
    color: white;
    min-height: 8em;
    ${RoundSides}
    ${Floating}
    ${Transitions(0.25)}

    max-width: 360px;

    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(8, 1fr);
    padding: 2px;

`

export const MemByte = styled.div`
    text-align: center;
    font-weight: ${({bold}) => bold ? "600" : "400"};
    font-family: monospace;
    font-size: 16px;
    text-decoration: ${({strike}) => strike ? "line-through" : "initial"};
    ${FGColor}
    ${({bold}) => bold && css`
        color: gold;
        background-color: #FFFFFF10;
    `}
    &:hover {
        background-color: #30BB3030;
        color: lime;
    }
    &:not(:hover) {
        transition: 0.35s;
    }

`

export const BigRegCol = styled.div`
    @media (max-width: 1781px) {
        display: none;
    }
`

export const SmallRegCol = styled.div`
    @media (min-width: 1782px) {
        display: none;
    }
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: space-between;
    gap: 32px;
`

export const BigRow = styled.div`
    @media (max-width: 1280px) {
        display: none;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: space-between;
    gap: 32px;
`

export const SmallRow = styled.div`
    @media (min-width: 1281px) {
        display: none;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: space-between;
    gap: 32px;
    max-width: 588px;
`

export const Col = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    place-items: center;
`

export const FreeZone = styled.div`
    background-color: salmon;
`

export const RegStack = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
`

export const Stack = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    // align-items: center;
`

export const Math = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 1670px) {
        flex-direction:column;
        justify-content: space-between;
    }
    flex-wrap: wrap;
    gap: 16px;
`

export const Spread = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    gap: ${({gap}) => gap || 8}px;

    & > ${() => Card} {
        // prevent from growing in cross access (vertically)
        align-self: center;
    }

`

export const Card = styled.div`
    --primary: ${({primary}) => primary};
    --secondary: ${({secondary}) => secondary};

    background-color: white;
    box-shadow: 4px 4px 4px #CCCCCC;
    border-radius: 6px;
    padding: 8px;

    display: flex;
    flex-direction: ${({long}) => long ? "row" : "column"};
    gap: 2px;

    user-select: none;

    ${RoundSides}
    z-index: 10;

    &:hover {
        transform: translate(0px, -2px);
        filter: saturate(180%);
    }

    &:active {
        box-shadow: 2px 2px 2px #CCCCCC;
        z-index: 9;
        transform: translate(0px, 0px);
    }

    ${Transitions(0.25)}
`

export const Name = styled.span`
    color: #00A7E1;
    text-align: center;
    flex: 1;
    align-self: center;
    padding: 2px;
    font-weight: 600;
    border-radius: 4px;

    svg {
        font-size: 40px;
    }

    color: var(--primary);
    ${FGColor}
`

export const Counter = styled.div`
    --transition-time: 0.15s;

    color: var(--primary);
    ${FGColor}
    ${({colorAlt}) => colorAlt && `--secondary: ${colorAlt};`}
    font-weight: 600;

    font-size: ${({big}) => big ? "72px" : "initial"};
    // letter-spacing: 1px;
    font-variant-numeric: tabular-nums;

    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
        "center";

    span {
        //place a span with text in at as a child of this Counter to
        //make the minimum width of the counter the width of that text
        grid-area: center;
        opacity: 0;
    }

    &:hover {
        &::after {
            opacity: 1;
            transform: translateY(0) rotateX(0);
        }

        &::before {
            opacity: 0;
            transform: translateY(-50%) rotateX(-90deg);
        }
    }

    &::after {
        content: attr(data-back);

        grid-area: center;
        transform: translateY(50%) rotateX(-90deg);
        opacity: 0;
        display: flex;
        align-items: center;
        place-content: center;

        background-color: black;
        text-align: center;
        padding: 3px;

        transition: var(--transition-time);
        ${RoundSides}
    }

    &::before {
        content: attr(data-front);

        grid-area: center;
        transform: translateY(0) rotateX(0);
        opacity: 1;

        background-color: var(--secondary);
        text-align: center;
        padding: 3px;

        display: flex;
        align-items: center;
        place-content: center;

        transition: var(--transition-time);
        ${RoundSides}
    }
`
