import { configureStore } from "@reduxjs/toolkit"
import counterReducer from "features/counter/counterSlice"
import mainReducer from "slices/MainSlice"
import articleReducer from "slices/ArticleSlice"
import cpuReducer from "slices/CpuSlice"

export default configureStore({
    reducer: {
        counter: counterReducer,
        main: mainReducer,
        article: articleReducer,
        cpu: cpuReducer,
    },
})
