
import React, { useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import {
    selectColor,
    selectPopup,
    setPopup,
    selectBackgroundColor,
    selectExtraNavItems,
} from "slices/MainSlice"

import { IoHardwareChipOutline } from "react-icons/io5"
import { BsPersonLinesFill } from "react-icons/bs"
import { CgFileDocument } from "react-icons/cg"
import { FaTwitch } from "react-icons/fa"
import { BiEqualizer } from "react-icons/bi"
import { TiWaves } from "react-icons/ti"
import { GrArticle } from "react-icons/gr"

import { useClickOutside, useEscape } from "utility/Hooks"

import {
    updateRawInstructions,
} from "slices/CpuSlice"

import Popups from "components/Cpu/Popups"

import * as S from "components/MainContainer2/Style"
import { useEffect } from "react"

const getPopupComponent = (name) => {
    const adjustedName = name && name.toUpperCase()
    switch (adjustedName) {
        case "HELP":
            return Popups.Help
        case "PC":
            return Popups.PC
        case "GPA":
        case "GPB":
        case "GPC":
        case "GPD":
        case "GPE":
        case "GPF":
        case "GPG":
        case "GPH":
            return Popups.GP(adjustedName)
        case "ALUA":
        case "ALUB":
            return Popups.ALU(adjustedName)
        case "ALUM":
            return Popups.ALUM
        case "ALUR":
            return Popups.ALUR
        case "FPUA":
        case "FPUB":
            return Popups.FPU(adjustedName)
        case "FPUM":
            return Popups.FPUM
        case "FPUR":
            return Popups.FPUR
        case "RBASE":
        case "WBASE":
        case "ROFST":
        case "WOFST":
            return Popups.BASE(adjustedName)
        case "RMEM":
        case "WMEM":
            return Popups.MEM(adjustedName)
        case "SKIP":
            return Popups.SKIP
        case "RTRN":
            return Popups.RTRN
        case "LINK":
            return Popups.LINK
        case "COMPA":
        case "COMPB":
            return Popups.COMP(adjustedName)
        case "COMPR":
            return Popups.COMPR
        case "IADN":
            return Popups.IADN
        case "IADF":
            return Popups.IADF
        case "FLAG":
            return Popups.FLAG
        default:
            return Popups.Help
    }
}

export const MainContainer = (props) => {
    const dispatch = useDispatch()
    const color = useSelector(selectColor)
    const backgroundColor = useSelector(selectBackgroundColor)
    const hasPopup = useSelector(selectPopup)

    const extraNavItems = useSelector(selectExtraNavItems(props))

    const history = useHistory()

    const PopupComponent = getPopupComponent(hasPopup)

    const popupRef = useRef(null)
    useClickOutside(popupRef, () => dispatch(setPopup("")))
    useEscape(popupRef, () => dispatch(setPopup("")))


    useEffect(() => {
        if (props.location.pathname === "/articles") {
            history.push("/articles/lens-perspective")
        }
    })

    return (
        <S.Outer color={color || "white"}>
            <div
                style={{
                    height: "80px",
                    width: "100%",
                    // backgroundColor: color,
                    backgroundColor: "black",
                }}
            >
                <S.TextContainer>
                    <S.Title onClick={() => history.push("/about")}>nobeario</S.Title>
                </S.TextContainer>
            </div>
            <S.Area backgroundColor={backgroundColor || "white"}>
                <S.leftContainer>
                    <S.left>
                        <S.ColorTab color={"#00A7E1"} onClick={() => history.push("/cpu")}>
                            <S.TabSpacer/>
                            <IoHardwareChipOutline />
                            CPU
                        </S.ColorTab>
                        <S.ColorTab color={"#0BD55C"} onClick={() => history.push("/docs")}>
                            <S.TabSpacer/>
                            <CgFileDocument/>
                            Docs
                        </S.ColorTab>
                        <S.ColorTab color={"#EF233C"} onClick={() => history.push("/about")}>
                            <S.TabSpacer/>
                            <BsPersonLinesFill/>
                            About
                        </S.ColorTab>
                        <S.ColorTab color={"#3399FF"} onClick={() => history.push("/articles")}>
                            <S.TabSpacer/>
                            <GrArticle/>
                            Articles
                        </S.ColorTab>
                        <S.ColorTab color={"#FFBE45"} onClick={() => history.push("/chords")}>
                            <S.TabSpacer/>
                            <BiEqualizer/>
                            Chords
                        </S.ColorTab>
                        <S.ColorTab color={"#CB5BCF"} onClick={() => history.push("/vectors")}>
                            <S.TabSpacer/>
                            <TiWaves/>
                            Vectors
                        </S.ColorTab>
                        {/* <S.ColorTab color={"#AC75FF"} onClick={() => history.push("/twitch")}> */}
                        <S.ColorTab color={"#AC75FF"} onClick={() => window.open("https://www.twitchtours.tv/", "_blank")}>
                            <S.TabSpacer/>
                            <FaTwitch/>
                            <S.SmallerTabText>TwitchTours</S.SmallerTabText>
                        </S.ColorTab>
                    </S.left>
                    <S.leftTrigger/>
                    <S.leftExtraNavContainer>
                        <S.leftExtraNav>
                                {
                                    extraNavItems.map((item, idx) => (
                                        <S.leftExtraNavItem
                                            color={`hsl(${(60 * ((idx))) % 360}deg 90% 65%)`}
                                            onClick={() => history.push(item.path)}
                                        >
                                            <div>{item.text}</div>
                                        </S.leftExtraNavItem>
                                    ))
                                }
                        </S.leftExtraNav>
                    </S.leftExtraNavContainer>
                </S.leftContainer>
                <S.main blur={hasPopup}>
                    {/* <S.ColorBlock color="yellow">main area</S.ColorBlock> */}
                    {props.children}
                    {/* <S.foot>
                        <S.ColorBlock color="purple">
                            {"footer\nfooter\nfooter"}
                        </S.ColorBlock>
                    </S.foot> */}
                </S.main>
                {hasPopup &&
                    <S.Popup ref={popupRef}>
                        <PopupComponent updateEditor={(code) => dispatch(updateRawInstructions(code))}/>
                    </S.Popup>
                }
                <S.head>
                    <S.ColorBlock color={"black"}>
                        .
                    </S.ColorBlock>
                </S.head>
            </S.Area>
        </S.Outer>
    )
}

export default MainContainer
