import styled from "styled-components"

export const Area = styled.div`
  height: 400px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: thin solid gray;
`
export const FancyContainer = styled.div`
    display: flex;
    background-color: red;
`

export const FancyRegister = styled.div`
    --transition-time: 0.15s;
    display: flex;
    background-color: #2A2B2E;
    color: #FB4B4E;
    min-width: 180px;
    padding: 6px;
    border-radius: 8px;

    user-select: none;

    flex-direction: row;

    transition: var(--transition-time);
    box-shadow: 0px 0px 0px 0px red;

    :hover {
        transition: var(--transition-time);
        box-shadow: 2px 3px 0px 0px red;
        transform: scale(1.05, 1.05);
        filter: saturate(150%) hue-rotate(-45deg);
    }

    :active {
        transition: var(--transition-time);
        transform: scale(1, 1);
    }
`

export const FlexHud = styled.div`
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    border: thin solid gray;
    min-height: 250px;

    background-color: black;

    ${FancyRegister} {
        margin: 6px;
    }
`

export const FRValueContainer = styled.div`
  background-color: #5A5A66;
  border-radius: 4px;
  padding: 4px;
  align-self: center;
  min-width: 60px;
`

export const FRLabel = styled.div`
  color: #ACEB98;
  align-self: center;
  font-family: monospace;
  font-size: 18px;
  flex-basis: 100%;
`

export const MemPreview = styled.div`
    background: salmon;
`

//https://coolors.co/2a2b2e-5a5a66-a4c2a8-aceb98-87ff65-f87666-fb4b4e
export const OuterContainer = styled.div`
    display: flex;
    border-radius: 10px;
    margin: 20px;
    background-color: #2A2B2E;
`

export const RegisterGrid = styled.div`
    display: grid;
    background-color: #5A5A66;
    min-height: 600px;
    width: 100%;
    margin: 10px;
    border-radius: 6px;

    grid-template-columns: repeat(12, auto);
    grid-template-rows: 10% repeat(14, auto) 40%;
`

export const HeaderArea = styled.div`
    background-color: #F87666;
`

export const RegisterArea = styled.div`
    background-color: #2A2B2E;
`

export const RamArea = styled.div`
    background-color: #FB4B4E;
`

export const Register = styled.div`
    width: 100px;
    background-color: #A4C2A8;
    border: thin solid #ACEB98;
`



export const GridContainer = styled.div`
    width: 100%;
    padding: 10px;

    //generated
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 0.2fr 1.5fr 1.3fr;
    gap: 0px 0px;
    grid-template-areas:
      "flag inst inst inst"
      "regs regs regs regs"
      "r r w w";
`

export const regs = styled.div`
    display: grid;
    grid-template-columns: 0.7fr 0.7fr 1.1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "exe gpa alum"
      "pc gpb alua"
      ". gpc alub"
      "skip gpd alur"
      "rtrn . ."
      "link gpe fpum"
      ". gpf fpua"
      ". gpg fpub"
      "compa gph fpur"
      "compb . ."
      "compr . ."
      ". . ."
      "iadn . ."
      "iadf . .";
    grid-area: regs;
`
export const GridReg = styled.div`
    grid-area: ${({gridArea}) => gridArea};
    background-color: ${({color}) => color};
    // min-width: 120px;
    width: 100%;
`

export const r = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "rregs rram rram"
      ". rram rram";
    grid-area: r;
`

export const rram = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
      ".";
    grid-area: rram;
`

export const rregs = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "rbase"
      "rofst"
      "rmem";
    grid-area: rregs;
`

export const w = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "wregs wram wram"
      ". wram wram";
    grid-area: w;
`

export const wram = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
      ".";
    grid-area: wram;
`

export const wregs = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "wbase"
      "wofst"
      "wmem";
    grid-area: wregs;
`

// new grid styles
export const GridContainer2 = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 5fr 1fr;
    gap: 0px 0px;
    background-color: black;
    padding: 20px;
    grid-template-areas:
      "status"
      "registers"
      "memory";
`

export const status = styled.div`
    grid-area: status;
    display: flex;
    justify-content: space-around;
    ${FancyRegister} {
        flex-basis: 50%;
        max-height: 36px;
        flex-basis: 40%;
    }
`

export const registers = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 200px;
    grid-template-areas:
      "prog gp math";
    grid-area: registers;
    // background-color: gray;
`

export const prog = styled.div`
    grid-area: prog;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${FancyRegister} {
        margin-top: 4px;
        margin-bottom: 4px;
    }
`

export const gp = styled.div`
    grid-area: gp;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 80%;
`

export const math = styled.div`
    grid-area: math;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 40%;
`

export const memory = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "rregs rmem wregs wmem";
    grid-area: memory;
`

export const rregs2 = styled.div`
    grid-area: rregs;
`
export const rmem2 = styled.div`
    grid-area: rmem;
`

export const wregs2 = styled.div`
    grid-area: wregs;
`

export const wmem2 = styled.div`
    grid-area: wmem;
`
