import React, { useEffect, useRef, useState } from "react"

import { useSelector, useDispatch } from "react-redux"

import * as S from "components/ChordsPage/Style"

import { setBackgroundColor } from "slices/MainSlice"

const getRandomItem = (arr) => arr[Math.floor(Math.random() * arr.length)]

const makeSineTone = (audioContext, frequency) => {
    const osc = audioContext.createOscillator()
    osc.type = "sine"
    osc.frequency.value = frequency
    // osc.connect(audioContext.destination)
    return osc
}

const makeBowedGain = (audioContext, duration, volume) => {
    const gain = audioContext.createGain()
    gain.gain.value = 0
    gain.gain.setValueAtTime(0.0001, 0)
    gain.gain.setTargetAtTime(volume, audioContext.currentTime, 0.008)
    gain.gain.setTargetAtTime(0, audioContext.currentTime + (0.001 * duration * 0.5), duration * 0.001 * 0.25)
    return gain
}

// Play a the given frequency (in hz) for approximately the given duration (in ms)
// The sound is rounded (exponential decay) on the front and end to prevent clicking
// The note is played as a pure sine wave with the first and third harmonic
const playFrequency = (audioContext, fundamentalFrequency, duration) => {
    const volume = 0.15
    const partials = [
        makeSineTone(audioContext, fundamentalFrequency, volume),
        makeSineTone(audioContext, fundamentalFrequency * 2),
        makeSineTone(audioContext, fundamentalFrequency * 4),
    ]

    partials.forEach((h, idx) => {
        // each harmonic has a different gain attached to it to individually control the volume
        const gain = makeBowedGain(audioContext, duration, volume * (0.5 ** idx))

        // the gain product should play to the speakers
        gain.connect(audioContext.destination)

        // the harmonic should output into the gain processor
        h.connect(gain)

        h.start()
        setTimeout(() => {
            h.stop()
        }, duration * 2)
    })
}

const NOTES = [
    "C",
    "C♯",
    "D",
    "E♭",
    "E",
    "F",
    "F♯",
    "G",
    "G♯",
    "A",
    "B♭",
    "B",
]

const CHORD_TYPES = {
    "Major": [4, 7, 12],
    "Major 7": [4, 7, 11],
    "Dominant 7": [4, 7, 10],
    "Minor": [3, 7, 12],
    "Minor 7": [3, 7, 10],
    "Diminished": [3, 6, 12],
    "Half diminished": [3, 6, 10],
    "Diminished 7": [3, 6, 9],
    "Minor major seventh": [3, 7, 11],
}

// return Note object
const makeNote = (noteName, octave=4) => {
    const index = NOTES.findIndex(x => x.toUpperCase() === noteName.toUpperCase())
    const baseFrequency = 16.35159783128741 * 2.0 ** (index / 12.0)
    const frequency = baseFrequency * (2.0 ** octave)
    return {
        frequency,
        index,
        octave,
        name: noteName.toUpperCase(),
    }
}

// note is a Note object
const transposeNote = (note, halfSteps) => {
    const adjustedIndex = (note.octave * 12) + note.index + halfSteps
    const newIndex = adjustedIndex % 12
    const newOctave = Math.floor(adjustedIndex / 12)
    const newName = NOTES[newIndex]
    return makeNote(newName, newOctave)
}

// note is a string or a Note object
const stringifyNote = (note) => {
    const symbols = typeof note === "string"
        ? note.split("")
        : note.name.split("")
    const letter = symbols[0].toUpperCase() === "A" ? "Ayy" : symbols[0]
    if (symbols[1])
    {
        const accidental = symbols[1] === "#" || symbols[1] === "♯" ? "sharp" : "flat"
        return `${letter} ${accidental}`
    }
    return letter
}

const textToSpeech = (text, onEnd) => {
    const speech = new SpeechSynthesisUtterance()
    speech.text = text
    const voices = window.speechSynthesis.getVoices()
    const voice = voices.findIndex(x => x.name.includes("Google UK English"))
    speech.voice = voices[voice]
    if (onEnd)
    {
        speech.onend = onEnd
    }
    window.speechSynthesis.speak(speech)
}

const BPM = 80 * 1.2
const MSPB = 60000 / BPM

export const ChordsPage = (props) => {
    const dispatch = useDispatch()

    const audio = useRef(null)

    const [octave, setOctave] = useState(1)

    const [tone, setTone] = useState(0)
    const [chord, setChord] = useState("Major")


    const [selectedRoots, setSelectedRoots] = useState([])
    const [selectedChordTypes, setSelectedChordTypes] = useState([])
    const [selectedNarrator, setSelectedNarrator] = useState(0)
    const [playingAutoMode, setPlayingAutoMode] = useState(false)
    const autoModeIntervalRef = useRef(null)

    if (!audio.current)
    {
        audio.current = new(window.AudioContext)()
    }

    useEffect(() => {
        dispatch(setBackgroundColor("#272727"))

        return () => dispatch(setBackgroundColor("white"))
    })

    const playNote = (note) => {
        playFrequency(audio.current, note.frequency, MSPB)
    }

    const playNotesTogether = (notes) => {
        notes.forEach(note => {
            playNote(note)
        })
    }

    const playNotesSeries = (notes) => {
        notes.forEach((note, idx) => {
            setTimeout(() => playNote(note), MSPB * idx)
        })
    }

    const playArpeggiatedAndChord = (notes, name=undefined, order=undefined) => {
        const playTones = () => {
            playNotesSeries(notes)
            setTimeout(() => playNotesTogether(notes), MSPB * (notes.length + 1))
        }

        if (!order)
        {
            playTones()
        }

        if (order === "before")
        {
            textToSpeech(name, () => setTimeout(playTones, 300))
        }

        if (order === "after")
        {
            playTones()
            setTimeout(() => textToSpeech(name), MSPB * (notes.length + 2))
        }
    }

    const getChordNotes = (rootName, chordType, octave=4) => {
        const root = makeNote(rootName, octave)
        const notes = [root]
        const intervals = CHORD_TYPES[chordType]

        intervals.forEach(halfSteps => notes.push(transposeNote(root, halfSteps)))
        return notes
    }

    const startAutoMode = () => {
        if (autoModeIntervalRef.current)
        {
            clearInterval(autoModeIntervalRef.current)
        }

        const getRandomFromSelections = () => {
            const rootName = getRandomItem(selectedRoots.length ? selectedRoots.map(x => NOTES[x]) : NOTES)
            const chordType = getRandomItem(selectedChordTypes.length
                ? selectedChordTypes
                : Object.keys(CHORD_TYPES)
            )
            return [getChordNotes(rootName, chordType), rootName, chordType]
        }

        let callback
        if (selectedNarrator === 0)
        {
            callback = () => {
                const [notes, name, chord] = getRandomFromSelections()
                playArpeggiatedAndChord(notes)
            }
        }
        else if (selectedNarrator === 1)
        {
            callback = () => {
                const [notes, name, chord] = getRandomFromSelections()
                playArpeggiatedAndChord(notes, `${stringifyNote(name)} ${chord}`, "before")
            }
        }
        else if (selectedNarrator === 2)
        {
            callback = () => {
                const [notes, name, chord] = getRandomFromSelections()
                playArpeggiatedAndChord(notes, `${stringifyNote(name)} ${chord}`, "after")
            }
        }

        callback()
        autoModeIntervalRef.current = setInterval(callback, 6500)
    }

    const stopAutoMode = () => {
        if (autoModeIntervalRef.current)
        {
            clearInterval(autoModeIntervalRef.current)
        }
    }


    return (
        <S.Outer>
            <S.TopGrid>
                <S.GridRow>
                    <h1>Play Chords</h1>
                    <S.BankSet>
                        <S.ButtonBank>
                            {
                                NOTES.map((name, idx) => (
                                    <S.Button
                                        onClick={() => setTone(idx)}
                                        deselected={tone !== idx}
                                    >{name}</S.Button>
                                ))
                            }
                        </S.ButtonBank>

                        <S.ButtonBank>
                            {
                                Object.keys(CHORD_TYPES).map(name => (
                                    <S.Button
                                        onClick={() => setChord(name)}
                                        deselected={chord !== name}
                                    >{name}</S.Button>
                                ))
                            }
                        </S.ButtonBank>

                        <S.FloatingButtons>
                            <S.WideButton
                                onClick={() => playArpeggiatedAndChord(getChordNotes(NOTES[tone], chord), `${stringifyNote(NOTES[tone])} ${chord}`, "after")}
                            >{`Play ${NOTES[tone]} ${chord}`}</S.WideButton>
                        </S.FloatingButtons>
                    </S.BankSet>
                </S.GridRow>


                <S.GridRow>
                    <h1>Interval Practice</h1>
                    <S.BankSet>
                        <S.ButtonBank>
                            {
                                NOTES.map((name, idx) => {
                                    const enabled = selectedRoots.includes(idx)

                                    return (
                                        <S.Button
                                            deselected={!enabled}
                                            onClick={() => {
                                                if (enabled)
                                                {
                                                    setSelectedRoots(selectedRoots.filter(x => x !== idx))
                                                }
                                                else
                                                {
                                                    setSelectedRoots([...selectedRoots, idx])
                                                }
                                            }}
                                        >{name}</S.Button>
                                    )
                                })
                            }
                            <S.Button
                                deselected={selectedRoots.length}
                                onClick={() => setSelectedRoots([])}
                            >Random</S.Button>
                        </S.ButtonBank>

                        <S.ButtonBank>
                            <S.Button
                                deselected={selectedChordTypes.length}
                                onClick={() => setSelectedChordTypes([])}
                            >Random</S.Button>
                            {
                                Object.keys(CHORD_TYPES).map(name => {
                                    const enabled = selectedChordTypes.includes(name)

                                    return (
                                        <S.Button
                                            deselected={!enabled}
                                            onClick={() => {
                                                if (enabled)
                                                {
                                                    setSelectedChordTypes(selectedChordTypes.filter(x => x !== name))
                                                }
                                                else
                                                {
                                                    setSelectedChordTypes([...selectedChordTypes, name])
                                                }
                                            }}
                                        >{name}</S.Button>
                                    )
                                })
                            }
                        </S.ButtonBank>

                        <S.ButtonBank>
                        <h3>Narration</h3>
                        <S.BankSet>
                            <S.Button
                                deselected={selectedNarrator !== 0}
                                onClick={() => setSelectedNarrator(0)}
                            >None</S.Button>
                            <S.Button
                                deselected={selectedNarrator !== 1}
                                onClick={() => setSelectedNarrator(1)}
                            >Before</S.Button>
                            <S.Button
                                deselected={selectedNarrator !== 2}
                                onClick={() => setSelectedNarrator(2)}
                            >After</S.Button>
                        </S.BankSet>
                        </S.ButtonBank>

                        <S.FloatingButtons>
                            <S.WideButton
                                onClick={() => {
                                    playingAutoMode ? stopAutoMode() : startAutoMode()
                                    setPlayingAutoMode(!playingAutoMode)
                                }}
                            >{playingAutoMode ? "Stop" : "Start"}</S.WideButton>
                        </S.FloatingButtons>
                    </S.BankSet>
                </S.GridRow>
            </S.TopGrid>

        </S.Outer>
    )
}

export default ChordsPage
