import styled, { css } from "styled-components"

import {
    BGColor,
    Transitions,
} from "utility/StyleUtility"

export const Outer = styled.div`
    ${({blur}) => blur && css`
        filter: blur(5px);
        pointer-events: none;
        scroll: none;
    `}
`

export const Popup = styled.div`
    width: 600px;
    // height: 400px;
    max-height: 80%;
    overflow-y: auto;
    position: absolute;
    left: calc(50% - 300px);
    top: 150px;
    // top: calc(50% - 200px);
    color: white;
    background-color: #272727;
    z-index: 1;
`

export const Title = styled.div`
    //gradient text color
    background: -webkit-linear-gradient(0deg, #42CBF5, #FE0230, gold);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200%;
    background-position: 0%;

    animation-duration: 2.2s;
    animation-name: fadein;

    @keyframes fadein {
        0% {
            opacity: 0%;
        }

        10% {
            opacity: 0%;
        }

        100% {
            opactiy: 100%;
        }
    }

    @media (max-width: 768px) {
        animation-name: colorize;
        animation-duration: 0.8s;
        animation-fill-mode: forwards;
        @keyframes colorize {
            0% {
                background-position-x: 0%;
            }

            100% {
                background-position-x: 320%;
            }
        }
    }
`

export const TextContainer = styled.div`
    z-index: 2;
    position: absolute;

    cursor: default;

    font-size: 72px;

    :hover {
        ${Title} {
            background-position-x: 320%;
            transition-duration: 0.8s;
        }
    }

    & > * {
        transition-duration: 0.5s;
    }

    transition-duration: 0.5s;
`

export const Area = styled.div`
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "header header"
        "main main"
        "leftbar footer";

    grid-auto-rows: minmax(10px, auto);
    height: calc(100vh - 80px);
    background-color: ${({backgroundColor}) => backgroundColor};
`

export const leftContainer = styled.div`
    grid-area: main;

    display: flex;
    flex-direction: row;

    z-index: 2;

    //hide the left bar by moving it off the left side of the screen
    //100% + border width so the border (and leftTrigger) hang out
    --bar-trigger-area-width: 100px;
    transform: translateX(calc(-100% + var(--bar-trigger-area-width)));
    border-right: solid var(--bar-trigger-area-width) transparent;

    //width of the left bar when on screen
    // width: 10%;
    width: 75px;


    --bar-popout-time: 0.2s;
    transition: var(--bar-popout-time);

    :hover {
        transition: var(--bar-popout-time);

        //move the left bar back on screen on hover
        transform: translateX(0%);
    }
`

export const left = styled.div`
    background-color: black;
    min-width: 100%;
`

export const leftTrigger = styled.div`
    @keyframes slidebar {
        0% {
            background-position-y: 0%;
        }
        100% {
            background-position-y: -400%;
        }
    }
    animation: slidebar 18s infinite;
    animation-timing-function: cubic-bezier(1,.04,.41,1.03);

    background: linear-gradient(0deg, #21cbf5, #fe0230, gold, #fe0230, #21cbf5);
    background-position-y: 0%;
    background-size: 50% 200%;

    min-width: 10px;
    width: 10px;
    z-index: -1;
`

export const leftExtraNavContainer = styled.div`
    z-index: -1;
`

export const leftExtraNav = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    background-color: black;
    width: 200px;
`

export const leftExtraNavItem = styled.div`
    ${Transitions(0.15)}

    border-top: medium solid ${({color}) => color};
    border-bottom: 0px solid ${({color}) => color};
    color: white;
    font-size: 20px;
    padding: 10px;

    user-select: none;

    &:hover {
        border-top: 8px solid ${({color}) => color};
        border-bottom: 8px solid ${({color}) => color};
    }

`

export const foot = styled.div`
    grid-area: footer;
    white-space: pre;
`

export const head = styled.div`
    grid-area: header;
`

export const main = styled.div`
    grid-area: main;
    padding: 85px;
    padding-top: 40px;
    min-width: 500px;
    overflow: auto;
    overflow-wrap: break-word;

    ${({blur}) => blur && css`
        filter: blur(8px);
        pointer-events: none;
    `}
`

export const ColorBlock = styled.div`
    background-color: ${({color}) => color};
    height: 100%;
    width: 100%;
`

export const TabSpacer = styled.div`
    //named div for making the tab appear to slide out
`

export const ColorTab = styled.div`
    display: flex;
    flex-direction: row;

    --sidebar-transition-time: 0.2s cubic-bezier(.86,.68,.35,.95);
    transition: var(--sidebar-transition-time);

    overflow: hidden;
    white-space: nowrap;
    background-color: ${({color}) => color};
    height: 100%;
    max-height: 65px;
    z-index: 1;

    opacity: 75%;
    width: 100%;

    font-size: 48px;
    min-width: 100%;

    user-select: none;
    cursor: pointer;

    svg {
        align-self: center;
        min-width: 55px;
        margin-left: 11px;
        margin-right: 11px;
    }

    * > path {
        stroke: currentcolor;
    }

    > ${TabSpacer} {
        width: 0%;
        background-color: black;
        transition: var(--sidebar-transition-time);
    }

    :hover {
        transition: var(--sidebar-transition-time);
        
        opacity: 100%;
        width: 300px;

        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        color: white;

        > ${TabSpacer} {
            width: 10%;
            background-color: black;
            transition: var(--sidebar-transition-time);
        }
    }
`

export const SmallerTabText = styled.span`
    font-size: 30px;
    align-self: center;
    margin-bottom: 5px;
`
