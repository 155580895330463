import { dmemRegex } from "components/Cpu/constants"

const MEMORY_SIZE = 0x0000FFFF
const MEMORY = new Array(MEMORY_SIZE)

const ERROR = console.log

const readMemory = (address, length) => {
    if (address + length > MEMORY_SIZE) {
        ERROR("Error: memory read out of bounds")
        const result = []
        for (let i = 0; i < length; i++) { result.push(0) }
        return result
    }
    return MEMORY.slice(address, address + length)
}

const writeMemory = (address, bytes) => {
    for (let i = 0; i < bytes.length; i++) {
        MEMORY[address + i] = bytes[i]
    }
}

export const getHighlightIndex = (address) => {
    const lineNumber = Math.floor(address / 8)
    let baseLineNumber = lineNumber < 3 ? 0 : (lineNumber - 3)
    const lastLine = Math.floor(0xFFFFFFFF / 8) - 1

    if (baseLineNumber > lastLine - 8) {
        baseLineNumber = lastLine - 7
    }

    const startingAddress = baseLineNumber * 8
    return address - startingAddress
}

const getNeighboringMemoryLines = (address) => {
    const lineNumber = Math.floor(address / 8)
    let baseLineNumber = lineNumber < 3 ? 0 : (lineNumber - 3)
    const lastLine = Math.floor(0xFFFFFFFF / 8) - 1

    if (baseLineNumber > lastLine - 8) {
        baseLineNumber = lastLine - 7
    }

    const startingAddress = baseLineNumber * 8
    return readMemory(startingAddress, 64)
}

export const getRandomMemoryPreview = () => {
    const result = []
    for (let i = 0; i < 64; i++) {
        result.push(
            Math.floor(Math.random() * (0xFF - 0 + 1) + 0)
            .toString(16)
            .toUpperCase()
            .padStart(2, "00")
        )
    }
    return result.join(" ")
}

const getMemoryPreview = (address) => {
    const bytes = getNeighboringMemoryLines(address)
    return bytes.map(b =>
        b.toString(16).toUpperCase().padStart(2, "00")
    ).join(" ")
}


const getComprAddress = () => {
    if (bytesToInt(cpu.COMPA) === bytesToInt(cpu.COMPB)) {
        return bytesToInt(cpu.PC)
    }
    else {
        return bytesToInt(cpu.PC) + 4
    }
}

const getIadfAddress = () => (bytesToInt(cpu.PC) + 2) >>> 0
const getIadnAddress = () => bytesToInt(cpu.PC)

const getRmemAddress = () => (bytesToInt(cpu.RBASE) + bytesToInt(cpu.ROFST)) >>> 0
const getWmemAddress = () => (bytesToInt(cpu.WBASE) + bytesToInt(cpu.WOFST)) >>> 0

const getRmemPreview = () => getMemoryPreview(getRmemAddress())
const getWmemPreview = () => getMemoryPreview(getWmemAddress())

export const intToHex = (int, pad=10) => {
    return int.toString(16).toUpperCase().padStart(pad, "0x00000000")
}

export const bytesToInt = (bytes) => {
    if (!bytes) { return 0 }
    if (!(bytes.length === 4)) {
        ERROR("Error: expected 4 bytes")
        return
    }

    bytes.forEach(x => {
        if (x < 0x00 || x > 0xFF) {
            ERROR("Error: byte out of range")
            return
        }
    })

    return ((bytes[0] << 24) | (bytes[1] << 16) | (bytes[2] << 8) | bytes[3]) >>> 0
}

export const bytesToFloat = (bytes) => {
    if (!bytes) { return 0 }
    if (!(bytes.length === 4)) {
        ERROR("Error: expected 4 bytes")
        return
    }

    bytes.forEach(x => {
        if (x < 0x00 || x > 0xFF) {
            ERROR("Error: byte out of range")
            return
        }
    })

    const view = new DataView(Uint8Array.from(bytes).buffer)
    return view.getFloat32(0, false)
}

const intToBytes = (int) => {
    if (int > 0xFFFFFFFF || int < 0x00) {
        ERROR("Error: int out of range")
        return
    }
    const mask = 0x000000FF
    const bytes = [0x00, 0x00, 0x00, 0x00]
    bytes[3] = int & mask
    bytes[2] = (int >> 8) & mask
    bytes[1] = (int >> 16) & mask
    bytes[0] = (int >> 24) & mask
    return bytes
}

const floatToBytes = (float) => {
    return Array.from(new Uint8Array(Float32Array.from([float]).buffer).reverse())
}

const loc = {
    IMM : 0x00,
    FLAG : 0x80,
    EXE : 0x81,
    PC : 0x82,
    ALUM : 0x83,
    ALUA : 0x84,
    ALUB : 0x85,
    ALUR : 0x86,
    FPUM : 0x87,
    FPUA : 0x88,
    FPUB : 0x89,
    FPUR : 0x8A,
    RBASE : 0x8B,
    ROFST : 0x8C,
    RMEM : 0x8D,
    WBASE : 0x8E,
    WOFST : 0x8F,
    WMEM : 0x90,
    GPA : 0x91,
    GPB : 0x92,
    GPC : 0x93,
    GPD : 0x94,
    GPE : 0x95,
    GPF : 0x96,
    GPG : 0x97,
    GPH : 0x98,
    COMPA : 0x99,
    COMPB : 0x9A,
    COMPR : 0x9B,
    IADN : 0x9C,
    IADF : 0x9D,
    LINK : 0x9E,
    SKIP : 0x9F,
    RTRN : 0xA0,
    DMEM : 0xC0,
}

export const aluMode = {
    NoOp: 0,
    Add: 1,
    Multiply: 2,
    Subtract: 3,
    Divide: 4,
    ShiftLeft: 5,
    ShiftRight: 6,
    OR: 7,
    AND: 8,
    XOR: 9,
    NAND: 10,
    NOR: 11,
}

export const fpuMode = {
    NoOp: 0,
    Add: 1,
    Multiply: 2,
    Subtract: 3,
    Divide: 4,
}

const byteToLoc = Object.keys(loc).reduce((acc, key) => {
    acc[loc[key]] = key
    return acc
}, {})

const byteToLocName = (byte) => {
    if (byte < loc.FLAG) {
        return byte
    }
    else if (byte >= loc.DMEM) {
        return byteToLoc[loc.DMEM]
    }
    else {
        return byteToLoc[byte]
    }
}

const cpu = Object.keys(loc).filter(key =>
    key !== byteToLoc[loc.DMEM]
    && key !== byteToLoc[loc.RMEM]
    && key !== byteToLoc[loc.WMEM]
    && key !== byteToLoc[loc.IMM]
).reduce((acc, key) => {
    acc[key] = [0x00, 0x00, 0x00, 0x00]
    return acc
}, {})
let currentInstruction = [0, 0]

const updateAlu = () => {
    const arga = bytesToInt(cpu.ALUA)
    const argb = bytesToInt(cpu.ALUB)
    const mode = bytesToInt(cpu.ALUM)

    let result = 0

    if (mode === aluMode.NoOp) { return }
    else if (mode === aluMode.Add) { result = arga + argb }
    else if (mode === aluMode.Multiply) { result = Math.imul(arga, argb) }
    else if (mode === aluMode.Subtract) { result = arga - argb }
    else if (mode === aluMode.Divide) { result = arga / argb }
    else if (mode === aluMode.ShiftLeft) { result = arga << argb }
    else if (mode === aluMode.ShiftRight) { result = arga >>> argb }
    else if (mode === aluMode.OR) { result = (arga | argb) }
    else if (mode === aluMode.AND) { result = (arga & argb) }
    else if (mode === aluMode.NOR) { result = ~(arga | argb) }
    else if (mode === aluMode.NAND) { result = ~(arga & argb) }
    else if (mode === aluMode.XOR) { result = (arga ^ argb) }
    else { return }

    cpu.ALUR = intToBytes(result >>> 0)
}

const updateFpu = () => {
    const arga = bytesToFloat(cpu.FPUA)
    const argb = bytesToFloat(cpu.FPUB)
    const mode = bytesToInt(cpu.FPUM)

    let result = 0

    if (mode === fpuMode.NoOp) { return }
    else if (mode === fpuMode.Add) { result = arga + argb }
    else if (mode === fpuMode.Multiply) { result = arga * argb }
    else if (mode === fpuMode.Subtract) { result = arga - argb }
    else if (mode === fpuMode.Divide) { result = arga / argb }
    else { return }

    cpu.FPUR = floatToBytes(result)
}

const updateReadOnlyRegisters = () => {
    const pc = bytesToInt(cpu.PC)
    cpu.SKIP = intToBytes(pc + 4)
    cpu.RTRN = intToBytes(pc + 6)
    updateAlu()
    updateFpu()
}

const execute = (instruction) => {
    const pc = bytesToInt(cpu.PC)
    const source = instruction[0]
    const destination = instruction[1]

    //source is an immediate value
    let sourceData
    if (source < loc.FLAG) {
        sourceData = source
    }
    // source is a location in memory
    else if (
        source === loc.COMPR
        || source === loc.IADF
        || source === loc.IADN
        || source === loc.RMEM
        || source === loc.WMEM
        || source >= loc.DMEM
    ) {
        let sourceAddress = 0
        if (source === loc.COMPR) {
            sourceAddress = getComprAddress()
        }
        else if (source === loc.IADF) { sourceAddress = getIadfAddress() }
        else if (source === loc.IADN) { sourceAddress = getIadnAddress() }
        else if (source === loc.RMEM) { sourceAddress = getRmemAddress() }
        else if (source === loc.WMEM) { sourceAddress = getWmemAddress() }
        else if (source >= loc.DMEM) { sourceAddress = source - loc.DMEM }
        else {
            ERROR("Error: bad source register")
            return
        }

        sourceData = bytesToInt(readMemory(sourceAddress, 4))
    }
    // source is a location on the processor
    else {
        sourceData = bytesToInt(cpu[byteToLoc[source]])
    }

    let destinationAddress = 0
    if (destination < loc.FLAG) { /*destination is immediate value; do nothing*/ }
    else if (
        destination === loc.ALUR
        || destination === loc.FPUR
        || destination === loc.RTRN
        || destination === loc.SKIP
    ) { /*destination is read-only; do nothing*/ }
    //destination is a location in memory
    else if (
        destination === loc.RMEM
        || destination === loc.WMEM
        || destination >= loc.DMEM
    ) {
        if (destination === loc.RMEM) { destinationAddress = getRmemAddress() }
        else if (destination === loc.WMEM) { destinationAddress = getWmemAddress() }
        else if (destination >= loc.DMEM) { destinationAddress = destination - loc.DMEM }
        else {
            ERROR("Error: bad destination register")
            return
        }

        writeMemory(destinationAddress, intToBytes(sourceData))
    }
    //destination is on the processor
    else {
        cpu[byteToLoc[destination]] = intToBytes(sourceData)
    }
}

const cycle = () => {
    const pc = bytesToInt(cpu.PC)

    //fetch instruction
    const instruction = readMemory(pc, 2)

    currentInstruction  = instruction

    //increment PC
    cpu.PC = intToBytes(pc + 2)

    //update read-only registers
    updateReadOnlyRegisters()

    //execute instruction
    execute(instruction)

    return true
}

export const getCpuForSlice = () => {
    //copy the CPU object, otherwise it seems like the store
    //makes it readonly
    return {
        cpu: {
            ...cpu,
            RMEM: readMemory(getRmemAddress(), 4),
            WMEM: readMemory(getWmemAddress(), 4),
            IADN: readMemory(getIadnAddress(), 4),
            IADF: readMemory(getIadfAddress(), 4),
            COMPR: readMemory(getComprAddress(), 4),
        },
        extra: {
            rmemPreview: getRmemPreview(),
            wmemPreview: getWmemPreview(),
            rmemAddress: getRmemAddress(),
            wmemAddress: getWmemAddress(),
            currentInstruction: {
                fromLoc: currentInstruction[0],
                fromLocHex: intToHex(currentInstruction[0], 4),
                fromLocName: byteToLocName(currentInstruction[0]),

                toLoc: currentInstruction[1],
                toLocHex: intToHex(currentInstruction[1], 4),
                toLocName: byteToLocName(currentInstruction[1]),
            }
        }
    }
}

export const startSim = (instructions) => {
    //initialize the simulation, including in the store

    // instructions = [
    //     0x0B, loc.ALUM,
    //     0x0A, loc.ALUM,
    //     0x09, loc.ALUM,
    //     0x08, loc.ALUM,
    //     0x07, loc.ALUM,
    //     0x06, loc.ALUM,
    //     0x05, loc.ALUM,
    //     0x04, loc.ALUM,
    //     0x03, loc.ALUM,
    //     0x02, loc.ALUM,

    //     0x04, loc.FPUM,
    //     0x03, loc.FPUM,
    //     0x02, loc.FPUM,
    //     0x01, loc.FPUM,
    //     0x00, loc.FPUM,

    //     loc.IADF, loc.FPUA,
    //     loc.SKIP, loc.PC,
    //     0x42, 0x2b, 0x33, 0x33,

    //     loc.IADF, loc.FPUB,
    //     loc.SKIP, loc.PC,
    //     0xC0, 0x06, 0x66, 0x66,

    //     0x01, loc.ALUM,
    //     0x02, loc.ALUA,
    //     0x03, loc.ALUB,
    //     loc.ALUR, loc.GPA,
    //     loc.ALUR, loc.DMEM + 0x10,
    //     0x05, loc.WBASE,
    //     0x01, loc.WOFST,
    //     loc.GPA, loc.FLAG,

    //     loc.IADF, loc.RBASE,
    //     loc.SKIP, loc.PC,
    //     0xFF, 0xFF, 0xFF, 0xFF,
    //     loc.RMEM, loc.GPH,
    // ]


    for (let i = 0; i < MEMORY.length; i++) {
        if (i < instructions.length) {
            MEMORY[i] = instructions[i]
        }
        else { MEMORY[i] = 0 }
    }
    //set registers to 0s
    Object.keys(cpu).forEach(k => cpu[k] = [0x00, 0x00, 0x00, 0x00])
    //set the current instruction to 0s (for the preview)
    currentInstruction = [0x00, 0x00]

    return getCpuForSlice()
}

export const stepSim = () => {
    cycle()
    return getCpuForSlice()
}

export const runSim = (instructions) => {
    instructions = [
        0x01, loc.ALUM,
        0x02, loc.ALUA,
        0x03, loc.ALUB,
        loc.ALUR, loc.GPA,
        loc.ALUR, loc.DMEM + 0x10,
        loc.GPA, loc.FLAG,
    ]

    let i
    for (let i = 0; i < MEMORY.length; i++) {
        if (i < instructions.length) {
            MEMORY[i] = instructions[i]
        }
        else { MEMORY[i] = 0 }
    }

    while (cycle());
}

export const parseInput = (instructionString) => {
    if (!instructionString || !instructionString.length) {
        return {
            instructions: [],
            errors: ["Instructions empty"],
            success: false,
        }
    }

    const errors = []
    const words = instructionString.split(/\s+/).filter(w => !!w).map(w => w.toUpperCase())

    const bytes = words.map(word => {
        if (word.search("DMEM") !== -1) {
            if (word.search(dmemRegex) !== -1) {
                //remove dmem part, add 0xC0
                const num = parseInt(word.replace("DMEM", ""), 16) + 0xC0
                return num
            }
            else {
                errors.push("DMEM must be between DMEM00 and DMEM3F")
                return 0
            }
        }
        else if (loc[word]) {
            return loc[word]
        }
        else if (!isNaN(parseInt(word))) {
            return parseInt(word)
        }
        errors.push(`Unknown symbol ${word}; used instead`)
        return 0
    })

    return {
        instructions: bytes,
        errors,
        success: !errors.length,
    }
}
