import styled, { css } from "styled-components"

export const Outer = styled.div`
    background-color: #272727;
    margin-top: -40px;
    margin-left: -75px;
    margin-right: -85px;
    margin-bottom: -85px;

    color: white;
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;

    width: 600px;
    min-width: 600px;
`
