export const DISPLAYS = {
    flag: {
        name: "FLAG",
        area: "status",
    },
    inst: {
        name: "INST",
        area: "status",
    },
    exe: {
        name: "EXE",
        area: "prog",
    },
    pc: {
        name: "PC",
        area: "prog",
    },
    skip: {
        name: "SKIP",
        area: "prog",
    },
    rtrn: {
        name: "RTRN",
        area: "prog",
    },
    link: {
        name: "LINK",
        area: "prog",
    },
    compa: {
        name: "COMPA",
        area: "prog",
    },
    compb: {
        name: "COMPB",
        area: "prog",
    },
    compr: {
        name: "COMPR",
        area: "prog",
    },
    iadn: {
        name: "IADN",
        area: "prog",
    },
    iadf: {
        name: "IADF",
        area: "prog",
    },

    gpa: {
        name: "GPA",
        area: "gp",
    },
    gpb: {
        name: "GPB",
        area: "gp",
    },
    gpc: {
        name: "GPC",
        area: "gp",
    },
    gpd: {
        name: "GPD",
        area: "gp",
    },
    gpe: {
        name: "GPE",
        area: "gp",
    },
    gpf: {
        name: "GPF",
        area: "gp",
    },
    gpg: {
        name: "GPG",
        area: "gp",
    },
    gph: {
        name: "GPH",
        area: "gp",
    },

    alum: {
        name: "ALUM",
        area: "math",
    },
    alua: {
        name: "ALUA",
        area: "math",
    },
    alub: {
        name: "ALUB",
        area: "math",
    },
    alur: {
        name: "ALUR",
        area: "math",
    },

    fpum: {
        name: "FPUM",
        area: "math",
    },
    fpua: {
        name: "FPUA",
        area: "math",
    },
    fpub: {
        name: "FPUB",
        area: "math",
    },
    fpur: {
        name: "FPUR",
        area: "math",
    },
    rbase: {
        name: "RBASE",
        area: "rregs",
    },
    rofst: {
        name: "ROFST",
        area: "rregs",
    },
    rmem: {
        name: "RMEM",
        area: "rregs",
    },
    wbase: {
        name: "WBASE",
        area: "wregs",
    },
    wofst: {
        name: "WOFST",
        area: "wregs",
    },
    wmem: {
        name: "WMEM",
        area: "wregs",
    },
}
