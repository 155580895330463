import styled, { css } from "styled-components"

import {
    FGColor,
    Floating,
    Transitions,
    RoundSides,
} from "utility/StyleUtility"

export const Outer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

export const TextArea = styled.textarea`
    border: thick solid red;
    position: relative;
    opacity: 0;
    z-index: 20;

    & + code {
        position: absolute;
        z-index: 19;
    }
`

export const Container = styled.div`
    width: 130px;
    background-color: white;
    ${Floating}
    ${RoundSides}
    ${Transitions(0.15)}
    padding: 10px;

    max-height: 400px;
    overflow-y: auto;

    & .code-area {
        outline: none;
        font-weight: 600 !important;
        white-space: pre-wrap !important;
    }

    & .pre-area {
        white-space: pre-wrap !important;
    }
`

export const Word = styled.div`
    font-size: 18px;
    font-weight: 600;
    ${FGColor}
    ${({backgroundColor}) => backgroundColor && `background-color: ${backgroundColor};`}
    white-space: pre-wrap;
    display: inline;
`
