export const vec = (x=0, y=0, z=0) => ({
    x, y, z,
    magnitude: Math.sqrt((x * x) + (y * y) + (z * z)),
    sub: (other) => vec(x - other.x, y - other.y, z - other.z),
    add: (other) => vec(x + other.x, y + other.y, z + other.z),
    scale: (length) => vec(x * length, y * length, z * length),
    normalize: () => {
        const len = Math.sqrt((x * x) + (y * y) + (z * z))
        return vec(x / len, y / len, z / len)
    },
})

export const lerp = (vec1, vec2, t) => {
    const difference = vec2.sub(vec1)
    const addition = difference.scale(t)
    return vec1.add(addition)
}

// Linearly interpolate by between a and b, by t
export const lerp_scalar = (a, b, t) => {
    const delta = b - a
    return (a + (delta * t))
}

export const drawVector = ({ ctx, color, origin, vector, capped }) => {
    ctx.strokeStyle = color
    ctx.beginPath()
    ctx.moveTo(origin.x, origin.y)
    ctx.lineTo(origin.x + vector.x, origin.y + vector.y)

    // draw arrow head
    if (capped) {
        const head = vec(origin.x + vector.x, origin.y + vector.y)
        const perp = vec(vector.y, -1 * vector.x).scale(0.25)
        const tip = head.add(vector.scale(0.5))

        ctx.lineTo(origin.x + vector.x + perp.x, origin.y + vector.y + perp.y)
        ctx.lineTo(tip.x, tip.y)
        ctx.lineTo(origin.x + vector.x - perp.x, origin.y + vector.y - perp.y)
        ctx.lineTo(head.x, head.y)
    }
    ctx.stroke()
}

export const rotatePoint = (point, angle, pivot) => {
    const p = point.sub(pivot)
    return vec(
        (p.x * Math.cos(angle)) - (p.y * Math.sin(angle)),
        (p.y * Math.cos(angle)) + (p.x * Math.sin(angle)),
    ).add(pivot)
}

export const randomVector = () => vec(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5).normalize()

export const randomInt = (max=2) => Math.floor(Math.random() * max)
