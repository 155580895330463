import styled, { css } from "styled-components"

import {
    BGColor,
    FGColor,
    Floating,
    RoundSides,
    Transitions
} from "utility/StyleUtility"

export const GameContainer = styled.div`
    margin: 200px;
    position: relative;
    border: thin solid black;
    border-radius: 18px;
    display: flex;
    padding: 10px;
`

export const HUD = styled.div`
    position: absolute;
    background-color: cornflowerblue;
    border-radius: 8px;
    padding: 10px;
    font-size: 22px;
    top: ${({y}) => y}px;
    left: ${({x}) => x}px;

    opacity: 60%;
    transition: 0.3s;

    user-select: none;

    min-width: 25px;

    text-align: center;
    font-weight: 600;

    :hover {
        opacity: 100%;
        transition: 0.3s;
    }
`

